import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ErrorMessage } from 'formik';
import { setActiveTab } from '../../redux/slices/header-slice.js';
import useOffices from '../../hooks/useOffices.js';
import {
    selectComputers,
    selectOfficeObj,
    setComputers,
    setOfficeObj
} from '../../redux/slices/office-slice.js';
import OfficeInfoContainer from '../../components/office-notes/OfficeInfoContainer.jsx';
import ComputersInfoContainer from '../../components/office-notes/ComputersInfoContainer.jsx';
import LoadingSpinner from '../../components/common/LoadingSpinner.jsx';

/**
 * React component for the page displaying a single office note and all it's info.
 *
 * @component
 * @returns {JSX.Element} The JSX representation of the ViewOfficeNotePage component.
 */
const ViewOfficeNotePage = () => {
    const dispatch = useDispatch();

    const { officeId } = useParams();
    const officeObj = useSelector(selectOfficeObj);
    const computers = useSelector(selectComputers);
    const { error, loading, getOffice } = useOffices();

    useEffect(() => {
        dispatch(setActiveTab('Notes'));

        return () => {
            dispatch(setActiveTab(null));
        };
    });

    useEffect(() => {
        const getData = async () => {
            const returnedOffice = await getOffice(officeId, true);
            const modifiedOffice = { ...returnedOffice };
            delete modifiedOffice.computers;
            dispatch(setOfficeObj(modifiedOffice));
            dispatch(setComputers(returnedOffice.computers));
        };

        getData();

        return () => {
            dispatch(setComputers([]));
        };
    }, [officeId, dispatch]);

    if (loading)
        return (
            <div style={{ marginTop: '300px' }}>
                <LoadingSpinner />
            </div>
        );
    if (error)
        return (
            <div style={{ marginTop: '300px' }}>
                <ErrorMessage message={error.message} />
            </div>
        );

    return (
        <div className="offices-container">
            <OfficeInfoContainer />
            <ComputersInfoContainer
                officeId={officeId}
                officeSysName={officeObj.officeSysName}
                computers={computers}
            />
        </div>
    );
};

export default ViewOfficeNotePage;
