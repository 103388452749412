/**
 * Redux slice responsible for managing the active tab in the header.
 * @module headerSlice
 */

import { createSlice } from '@reduxjs/toolkit';

// Initial state of the header slice
const initialState = {
    activeTab: null
};

/**
 * Slice containing reducers for managing the active tab state.
 */
export const headerSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        /**
         * Action creator to set the active tab.
         * @param {Object} state - Current state of the header slice.
         * @param {Object} action - Payload containing the active tab value.
         */
        setActiveTab: (state, action) => {
            // eslint-disable-next-line no-param-reassign
            state.activeTab = action.payload;
        }
    }
});

/**
 * Selector function to retrieve the active tab from the state.
 * @param {Object} state - Redux state containing the header slice.
 * @returns {string|null} The active tab value.
 */
export const selectActiveTab = (state) => state.header.activeTab;

export const { setActiveTab } = headerSlice.actions;

const headerSliceReducer = headerSlice.reducer;

export default headerSliceReducer;
