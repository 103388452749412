import UserFriendlyError from './UserFriendlyError.js';

/**
 * Converts an error to a UserFriendlyError, ensuring consistent error handling.
 * If the error passed is not already a user friendly error, meaning an error that can be
 * directly displayed to the end user, it will convert it to one using the default generic
 * system error message set in the UserFriendlyError class.
 *
 * @param {Error} err - The error to be converted.
 * @returns {UserFriendlyError} Returns the original UserFriendlyError if the error is already of that type,
 *                             otherwise returns a new instance of UserFriendlyError.
 *
 * @example
 * // Example usage:
 * import convertError from './convertError.js';
 *
 * try {
 *   // Some code that may throw an error
 * } catch (error) {
 *   const convertedUserFriendlyError = convertError(error);
 *   // Handle the converted error
 * }
 */
const convertError = (err) => {
    return err instanceof UserFriendlyError ? err : new UserFriendlyError();
};

export default convertError;
