/**
 * Converts a camelCase string to a regular sentence.
 *
 * @param {string} camelCaseString - The input camelCase string to be converted.
 * @returns {string} The converted sentence string.
 */
const camelCaseToSentence = (camelCaseString) => {
    // Split the camelCaseString into words
    const words = camelCaseString.split(/(?=[A-Z])/);

    // Capitalize the first letter of each word and join them with a space
    const sentence = words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

    return sentence;
};

export default camelCaseToSentence;
