import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setActiveTab } from '../../redux/slices/header-slice.js';
import SubLinks from '../../components/header/SubLinks.jsx';
import { userManagementLinks } from '../../config/standard-links.js';

/**
 * Component for displaying user management links and options.
 * @returns {JSX.Element} UsersHomePage component.
 */
const UsersHomePage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveTab('Users'));

        return () => {
            dispatch(setActiveTab(null));
        };
    });

    return (
        <div>
            <SubLinks links={userManagementLinks} />
            <div className="user-container">
                <h2 className="user-title" style={{ textAlign: 'left' }}>
                    User Management
                </h2>
                <ul className="user-links-container">
                    <Link to="/createuser" className="automation-link">
                        Create a user
                    </Link>
                    <Link to="/updateuser" className="automation-link">
                        Update a user
                    </Link>
                    <Link to="/deleteuser" className="automation-link">
                        Delete a user
                    </Link>
                </ul>
            </div>
        </div>
    );
};

export default UsersHomePage;
