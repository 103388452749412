import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import httpStatus from 'http-status';
import SubmitButton from '../common/SubmitButton.jsx';
import { addUserToSoftwarePlatformsFormFields } from '../../config/form-fields.js';
import ErrorMessage from '../common/ErrorMessage.jsx';
import SuccessMessage from '../common/SuccessMessage.jsx';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import camelCaseToSentence from '../../utils/camel-case-to-sentence.js';
import useNewScs from '../../hooks/useNewScs.js';
import addUserToSoftwarePlatformsValidation from '../../validation/add-user-to-software-platforms-validation.js';

/**
 * ManuallyAddUserForm component for rendering the form to trigger the Add user to software platforms automation process.
 *
 * @component
 * @returns {JSX.Element} - The rendered ManuallyAddUserForm component.
 */
const ManuallyAddUserForm = () => {
    const [processResultAllOk, setProcessResultAllOk] = useState(true);
    const [specificErrorsMessage, setSpecificErrorsMessage] = useState('');

    const { error, loading, addToPlatformsResult, addUserToPlatforms } =
        useNewScs();

    const formik = useFormik({
        initialValues: addUserToSoftwarePlatformsFormFields,
        validationSchema: addUserToSoftwarePlatformsValidation,
        onSubmit: async (values) => {
            await addUserToPlatforms(values);
        }
    });

    const formatPhoneNumber = (value) => {
        if (!value) return value;

        // Remove all non-numeric characters
        const phoneNumber = value.replace(/[^\d]/g, '');

        const phoneNumberLength = phoneNumber.length;

        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }

        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
            3,
            6
        )}-${phoneNumber.slice(6, 10)}`;
    };

    useEffect(() => {
        /**
         * Checks the status codes of the result object and handles proper messaging
         */
        const handleStatusCodes = () => {
            let errorMessage = 'The following failed:\n\n';

            if (addToPlatformsResult) {
                // Grab status codes
                const statusCodes = Object.values(addToPlatformsResult);
                if (statusCodes.every((code) => code === 200)) {
                    setProcessResultAllOk(true);
                } else {
                    setProcessResultAllOk(false);

                    const keys = Object.keys(addToPlatformsResult);
                    keys.forEach((key) => {
                        if (addToPlatformsResult[key] !== 200) {
                            // Converts result codes to user friendly text and sets to message
                            errorMessage += `${camelCaseToSentence(key)}: ${
                                httpStatus[addToPlatformsResult[key].toString()]
                            }\n`;
                        }
                    });
                }
            }

            setSpecificErrorsMessage(errorMessage);
        };

        handleStatusCodes();
    }, [addToPlatformsResult]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="newsc-form-container">
                    <label htmlFor="firstName" className="form-label">
                        First Name
                    </label>
                    <input
                        id="firstName"
                        type="text"
                        className="newsc-input"
                        placeholder="e.g. John"
                        {...formik.getFieldProps('firstName')}
                    />
                    {formik.touched.firstName && formik.errors.firstName ? (
                        <ErrorMessage message={formik.errors.firstName} />
                    ) : null}

                    <label htmlFor="lastName" className="form-label">
                        Last Name
                    </label>
                    <input
                        id="lastName"
                        type="text"
                        className="newsc-input"
                        placeholder="e.g. Doe"
                        {...formik.getFieldProps('lastName')}
                    />
                    {formik.touched.lastName && formik.errors.lastName ? (
                        <ErrorMessage message={formik.errors.lastName} />
                    ) : null}

                    <label htmlFor="eassistEmail" className="form-label">
                        eAssist Email
                    </label>
                    <input
                        id="eassistEmail"
                        type="text"
                        className="newsc-input"
                        placeholder="e.g. john.doe@eassist.me"
                        {...formik.getFieldProps('eassistEmail')}
                    />
                    {formik.touched.eassistEmail &&
                    formik.errors.eassistEmail ? (
                        <ErrorMessage
                            autofocus
                            message={formik.errors.eassistEmail}
                        />
                    ) : null}

                    <label htmlFor="personalEmail" className="form-label">
                        Personal Email
                    </label>
                    <input
                        id="personalEmail"
                        type="text"
                        className="newsc-input"
                        placeholder="e.g. john.doe@gmail.com"
                        {...formik.getFieldProps('personalEmail')}
                    />
                    {formik.touched.personalEmail &&
                    formik.errors.personalEmail ? (
                        <ErrorMessage
                            autofocus
                            message={formik.errors.personalEmail}
                        />
                    ) : null}

                    <label htmlFor="phoneNumber" className="form-label">
                        Phone Number
                    </label>
                    <input
                        id="phoneNumber"
                        type="text"
                        className="newsc-input"
                        placeholder="e.g. (801) 123-1234"
                        {...formik.getFieldProps('phoneNumber')}
                        onChange={(e) => {
                            formik.setFieldValue(
                                'phoneNumber',
                                formatPhoneNumber(e.target.value)
                            );
                        }}
                    />
                    {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                        <ErrorMessage
                            autofocus
                            message={formik.errors.phoneNumber}
                        />
                    ) : null}

                    <SubmitButton label="Add User" />
                </div>
            </form>
            <br />
            <br />
            {loading && <LoadingSpinner />}
            {Object.keys(formik.errors).length !== 0 && (
                <ErrorMessage message="There are form errors above, please fix." />
            )}
            {error && <ErrorMessage message={error.message} />}
            {addToPlatformsResult && processResultAllOk ? (
                <SuccessMessage message="User added to platforms successfully" />
            ) : null}
            {addToPlatformsResult && !processResultAllOk ? (
                <ErrorMessage message={specificErrorsMessage} />
            ) : null}
        </>
    );
};

export default ManuallyAddUserForm;
