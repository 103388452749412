import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/extensions
import { BiArrowBack } from 'react-icons/bi';
import { black } from '../../styles/standard-colors.js';

/**
 * BackArrow component for displaying a back button with optional navigation or onClick functionality.
 *
 * @component
 * @example
 * // Example usage with navigation:
 * <BackArrow navigateTo="/add-item" color="blue" size={40} />
 *
 * @example
 * // Example usage with custom onClick:
 * <BackArrow onClick={() => handleAddButtonClick()} size={30} />
 *
 * @param {Object} props - The component props.
 * @param {string} [props.color='#FF355E'] - The color of the button. Defaults to black.
 * @param {number} [props.size=34] - The size of the button icon.
 * @param {string} [props.navigateTo] - The URL to navigate to when the button is clicked.
 * @param {Function} [props.onClick] - The function to be executed when the button is clicked.
 *
 * @returns {JSX.Element} - The rendered BackArrow component.
 */
const BackArrow = ({ color, size, navigateTo, onClick }) => {
    const navigate = useNavigate();

    if (navigateTo) {
        return (
            <BiArrowBack
                size={size}
                color={color}
                style={{ cursor: 'pointer', marginRight: '2em' }}
                onClick={() => navigate(navigateTo)}
            />
        );
    }

    if (onClick) {
        return (
            <BiArrowBack
                size={size}
                color={color}
                style={{ cursor: 'pointer', marginRight: '2em' }}
                onClick={onClick}
            />
        );
    }

    return (
        <BiArrowBack size={size} color={color} style={{ marginRight: '2em' }} />
    );
};

BackArrow.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
    navigateTo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func
};

BackArrow.defaultProps = {
    color: black,
    size: 32
};

export default BackArrow;
