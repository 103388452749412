import PropTypes from 'prop-types';
// eslint-disable-next-line import/extensions
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import TableTitleHeader from '../common/TableTitleHeader.jsx';
import { yellow } from '../../styles/standard-colors.js';
import CreateUpdateComputerForm from './CreateUpdateComputerForm.jsx';
import ComputerInfoTable from './ComputerInfoTable.jsx';

/**
 * Component for displaying information about office computers.
 *
 * @param {object} props - The props object.
 * @param {string} props.officeId - The ID of the office.
 * @param {string} props.officeSysName - The system name of the office.
 * @param {Array<object>} props.computers - An array of computer objects.
 * @returns {JSX.Element} JSX element representing the Computers Info Container.
 */
const ComputersInfoContainer = ({ officeId, officeSysName, computers }) => {
    const [showCreate, setShowCreate] = useState(false);

    return (
        <div className="office-computers-container">
            <TableTitleHeader
                title="Computers"
                includeAddButton={true}
                underlineColor={yellow}
                handleAddButton={() => setShowCreate(true)}
                customContainerStyles={{ marginLeft: '30px' }}
            />
            {showCreate && (
                <CreateUpdateComputerForm
                    setShowCreate={setShowCreate}
                    officeId={officeId}
                    officeSysName={officeSysName}
                />
            )}
            <div className="office-computer-container">
                {computers.map((computer) => {
                    return (
                        <ComputerInfoTable
                            key={uuidv4()}
                            computer={computer}
                            officeId={officeId}
                            officeSysName={officeSysName}
                        />
                    );
                })}
            </div>
        </div>
    );
};

ComputersInfoContainer.propTypes = {
    officeId: PropTypes.string.isRequired,
    officeSysName: PropTypes.string.isRequired,
    computers: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ComputersInfoContainer;
