import { useState } from 'react';
import convertError from '../utils/error-converter.js';
import UserFriendlyError from '../utils/UserFriendlyError.js';
import callApi from '../utils/call-api.js';
import analyzeApiResponse from '../utils/analyze-api-response.js';

/**
 * Custom React hook for managing credential-related data.
 *
 * @returns {Object} An object containing state variables and functions related to credential data.
 * @property {Object|null} credential - The returned credential (null if none returned).
 * @property {boolean} loading - A boolean indicating whether data is currently being loaded.
 * @property {Object|null} error - An error object containing details about any encountered error.
 * @property {Function} createCredential - A function to create a new credential.
 */
const useCredentials = () => {
    const [credential, setCredential] = useState(null);
    const [isDeleteSuccess, setIsDeleteSuccess] = useState(false);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    /**
     * Creates a new credential.
     *
     * @async
     * @function
     * @param {Object} newCredentialFields - The fields for creating the new credential, passed from formik or elsewhere. Reference config/form-fields.js or validation/create-credential-validations.js for details on properties.
     * @returns {Promise<Credential>} A promise that resolves when the credential creation is complete with the new credential object.
     */
    const createCredential = async (newCredentialFields) => {
        try {
            setLoading(true);
            setError(null);

            const path = `/credentials`;
            const response = await callApi(path, 'post', newCredentialFields);

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const newCredential = await response.json();
            setCredential(newCredential);
            return newCredential;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Updates a credential.
     *
     * @async
     * @function
     * @param {string} credentialId
     * @param {Object} updateCredentialFields - The fields to update, passed from formik or elsewhere. Reference config/form-fields.js or validation/create-credential-validations.js for details on properties.
     * @returns {Promise<Credential>} A promise that resolves when the credential update is complete with the updated credential object.
     */
    const updateCredential = async (credentialId, updateCredentialFields) => {
        try {
            setLoading(true);
            setError(null);

            const updateCredentialFieldsModified = {
                ...updateCredentialFields
            };

            // Remove any fields that are not allowed
            delete updateCredentialFieldsModified.id;
            delete updateCredentialFieldsModified.hipaaCompliant;

            const path = `/credentials/${credentialId}`;
            const response = await callApi(
                path,
                'patch',
                updateCredentialFieldsModified
            );

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const updatedCredential = await response.json();
            setCredential(updatedCredential);
            return updatedCredential;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Deletes a credential.
     *
     * @async
     * @function
     * @param {string} credentialId
     * @returns {Promise<Boolean>} A promise that resolves when the credential is deleted.
     */
    const deleteCredential = async (credentialId) => {
        try {
            setLoading(true);
            setError(null);

            const path = `/credentials/${credentialId}`;
            const response = await callApi(path, 'delete');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            setIsDeleteSuccess(true);
            return true;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return false;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Duplicates a single credential and it's info by ID.
     *
     * @async
     * @function
     * @param {string} credId
     * @returns {Promise<Credential>} A promise that resolves with the returned new credential.
     */
    const duplicateCredential = async (credId) => {
        try {
            setLoading(true);
            setError(null);

            const path = `/credentials/duplicate/${credId}`;
            const response = await callApi(path, 'post');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const returnedCredential = await response.json();
            setCredential(returnedCredential);
            return returnedCredential;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    return {
        credential,
        loading,
        error,
        createCredential,
        updateCredential,
        deleteCredential,
        isDeleteSuccess,
        duplicateCredential
    };
};

export default useCredentials;
