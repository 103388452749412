import { useFormik } from 'formik';
import SubmitButton from '../common/SubmitButton.jsx';
import { runAoarFormFields } from '../../config/form-fields.js';
import ErrorMessage from '../common/ErrorMessage.jsx';
import SuccessMessage from '../common/SuccessMessage.jsx';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import useAutomations from '../../hooks/useAutomations.js';
import { runAoarValidation } from '../../validation/admin-automation-validations.js';

/**
 * AoarForm component for rendering the form to trigger the AOAR (All Office Assignments Removed) automation process.
 *
 * @component
 * @returns {JSX.Element} - The rendered AoarForm component.
 */
const AoarForm = () => {
    const { error, loading, isAoarSuccess, runAoar } = useAutomations();

    const formik = useFormik({
        initialValues: runAoarFormFields,
        validationSchema: runAoarValidation,
        onSubmit: async (values) => {
            await runAoar(values);
        }
    });

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="newsc-form-container">
                    <label htmlFor="scFullName" className="form-label">
                        SC Full Name
                    </label>
                    <input
                        id="scFullName"
                        type="text"
                        className="newsc-input"
                        placeholder="e.g. John Doe"
                        {...formik.getFieldProps('scFullName')}
                    />
                    {formik.touched.scFullName && formik.errors.scFullName ? (
                        <ErrorMessage message={formik.errors.scFullName} />
                    ) : null}

                    <label htmlFor="scEassistEmail" className="form-label">
                        SC eAssist Email
                    </label>
                    <input
                        id="scEassistEmail"
                        type="text"
                        className="newsc-input"
                        placeholder="e.g. john.doe@eassist.me"
                        {...formik.getFieldProps('scEassistEmail')}
                    />
                    {formik.touched.scEassistEmail &&
                    formik.errors.scEassistEmail ? (
                        <ErrorMessage
                            autofocus
                            message={formik.errors.scEassistEmail}
                        />
                    ) : null}
                    <label htmlFor="scPersonalEmail" className="form-label">
                        SC Personal Email
                    </label>
                    <input
                        id="scPersonalEmail"
                        type="text"
                        className="newsc-input"
                        placeholder="e.g. john.doe@gmail.com"
                        {...formik.getFieldProps('scPersonalEmail')}
                    />
                    {formik.touched.scPersonalEmail &&
                    formik.errors.scPersonalEmail ? (
                        <ErrorMessage
                            autofocus
                            message={formik.errors.scPersonalEmail}
                        />
                    ) : null}
                    <SubmitButton label="Run" />
                </div>
            </form>
            <br />
            <br />
            {loading && <LoadingSpinner />}
            {Object.keys(formik.errors).length !== 0 &&
            Object.keys(formik.touched).length !== 0 ? (
                <ErrorMessage message="There are form errors above, please fix." />
            ) : null}
            {error && <ErrorMessage message={error.message} />}
            {isAoarSuccess && (
                <SuccessMessage message="AOAR process ran successfully" />
            )}
        </>
    );
};

export default AoarForm;
