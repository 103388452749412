/**
 * Redux slice responsible for managing the new SC object state.
 * @module newScSlice
 */

/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

// Initial state of the new SC slice
const initialState = {
    newScObj: {}
};

/**
 * Slice containing reducers for managing the new SC object state.
 */
export const newScSlice = createSlice({
    name: 'newSc',
    initialState,
    reducers: {
        /**
         * Action creator to set the new SC object.
         * @param {Object} state - Current state of the new SC slice.
         * @param {Object} action - Payload containing the new SC object.
         */
        setNewScObj: (state, action) => {
            state.newScObj = action.payload;
        },
        /**
         * Action creator to update the active state of the new SC object.
         * @param {Object} state - Current state of the new SC slice.
         * @param {Object} action - Payload containing the new active state.
         */
        updateScActiveState: (state, action) => {
            state.newScObj.isActive = action.payload;
        }
    }
});

/**
 * Selector function to retrieve the new SC object from the state.
 * @param {Object} state - Redux state containing the new SC slice.
 * @returns {Object} The new SC object.
 */
export const selectNewScObj = (state) => state.newSc.newScObj;

export const { setNewScObj, updateScActiveState } = newScSlice.actions;

const newScSliceReducer = newScSlice.reducer;

export default newScSliceReducer;
