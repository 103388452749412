import PropTypes from 'prop-types';

/**
 * React component for displaying success messages.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {string} props.message - The success message to be displayed.
 * @returns {JSX.Element} The rendered SuccessMessage component.
 * @example
 * // Example usage of the SuccessMessage component
 * import SuccessMessage from './path/to/SuccessMessage';
 * // ...
 * <SuccessMessage message="Operation was successful!" />
 */
const SuccessMessage = ({ message }) => {
    return (
        <div autoFocus className="success-message-container">
            <p className="success-message-text">{message}</p>
        </div>
    );
};

SuccessMessage.propTypes = {
    message: PropTypes.string.isRequired
};

export default SuccessMessage;
