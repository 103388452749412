import React, { useEffect } from 'react';
// eslint-disable-next-line import/extensions
import { GiWolverineClaws } from 'react-icons/gi';
// eslint-disable-next-line import/extensions
import { FaHippo, FaUsers } from 'react-icons/fa';
// eslint-disable-next-line import/extensions
import { ImStatsDots } from 'react-icons/im';
// eslint-disable-next-line import/extensions
import { MdOutlinePersonAdd, MdLogout } from 'react-icons/md';
// eslint-disable-next-line import/extensions
import { TbMoodCry } from 'react-icons/tb';
// eslint-disable-next-line import/extensions
import { CgNotes } from 'react-icons/cg';
import { useDispatch } from 'react-redux';
import { setActiveTab } from '../redux/slices/header-slice.js';
import useAuthentication from '../hooks/useAuthentication.js';
import LoadingSpinner from '../components/common/LoadingSpinner.jsx';
import NavTile from '../components/homepage/NavTile.jsx';
import {
    blue,
    darkRed,
    green,
    lightBlue,
    orange,
    pink,
    purple,
    red
} from '../styles/standard-colors.js';
import ErrorMessage from '../components/common/ErrorMessage.jsx';

/**
 * React component representing the homepage of the Oracle application.
 *
 * @component
 */
const Homepage = () => {
    const { loading, error, logout } = useAuthentication();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveTab(null));
    });

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <div className="homepage-container">
            <h2>Welcome to Oracle!</h2>
            <div className="homepage-icons-container">
                <div className="homepage-icons-column">
                    <NavTile
                        title="Office Notes"
                        color={blue}
                        to="/notes"
                        id="homepage-notes"
                        icon={CgNotes}
                    />
                    <NavTile
                        title="New SC"
                        color={red}
                        to="/newsc"
                        id="homepage-newsc"
                        icon={MdOutlinePersonAdd}
                    />
                    <NavTile
                        title="Admin"
                        color={green}
                        to="/admin"
                        id="homepage-admin"
                        icon={GiWolverineClaws}
                    />
                    <NavTile
                        title="HIPAA"
                        color={orange}
                        to="/hipaa"
                        id="homepage-hipaa"
                        icon={FaHippo}
                    />
                </div>
                <div className="homepage-icons-column">
                    <NavTile
                        title="Install Fails"
                        color={pink}
                        to="/installfails"
                        id="homepage-install"
                        icon={TbMoodCry}
                    />
                    <NavTile
                        title="User Management"
                        color={purple}
                        to="/users"
                        id="homepage-user"
                        icon={FaUsers}
                    />
                    <NavTile
                        title="Reporting"
                        color={lightBlue}
                        to="/reporting"
                        id="homepage-reporting"
                        icon={ImStatsDots}
                    />
                    <div onClick={logout}>
                        <NavTile
                            title="Logout"
                            color={darkRed}
                            id="homepage-logout"
                            icon={MdLogout}
                            onClick={logout}
                        />
                    </div>
                </div>
            </div>
            <br />
            <br />
            {error && <ErrorMessage message={error.message} />}
        </div>
    );
};

export default Homepage;
