import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LinksCollapse from './LinksCollapse.jsx';
import LinksFull from './LinksFull.jsx';
import oracleLogo from '../../assets/cropped-logo.png';

/**
 * React component representing the header of the Oracle application.
 *
 * @component
 * @returns {JSX.Element} The rendered Header component.
 */
const Header = () => {
    const [width, setWidth] = useState(window.innerWidth);

    const breakpoint = 1150;

    const handleResize = () => {
        setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    const narrowView = width <= breakpoint;

    return (
        <div className="header-container">
            <Link to="/" className="header-logo-container">
                <img
                    src={oracleLogo}
                    alt="oracle logo"
                    className="oracle-header-logo"
                />
            </Link>
            {narrowView ? <LinksCollapse /> : <LinksFull />}
        </div>
    );
};

export default Header;
