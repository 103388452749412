import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { setActiveTab } from '../../redux/slices/header-slice.js';
import SubLinks from '../../components/header/SubLinks.jsx';
import { hipaaLinks } from '../../config/standard-links.js';
import ErrorMessage from '../../components/common/ErrorMessage.jsx';
import LoadingSpinner from '../../components/common/LoadingSpinner.jsx';
import BackArrow from '../../components/common/BackArrow.jsx';
import useHipaa from '../../hooks/useHipaa.js';
import OfficeNotesList from '../../components/office-notes/OfficeNotesList.jsx';
import { hipaaIncompliantReasonOptions } from '../../config/dropdown-options.js';

/**
 * Component for rendering the HIPAA Concerns Page.
 * @returns {JSX.Element} HIPAA Concerns Page component.
 */
const HipaaConcernsPage = () => {
    const dispatch = useDispatch();

    const [incompliantReason, setIncompliantReason] = useState('credentials');
    const [currentPage, setCurrentPage] = useState(1);

    const { error, loading, getHipaaIncompliantOffices, offices, totalPages } =
        useHipaa();

    useEffect(() => {
        dispatch(setActiveTab('HIPAA'));

        return () => {
            dispatch(setActiveTab(null));
        };
    });

    useEffect(() => {
        const getOffices = async () => {
            await getHipaaIncompliantOffices(incompliantReason, currentPage);
        };

        getOffices();
    }, [incompliantReason, currentPage]);

    return (
        <div>
            <SubLinks links={hipaaLinks} />
            <div className="newsc-container">
                <br />
                <br />
                <br />
                <div className="create-office-heading">
                    <BackArrow navigateTo={-1} />
                    <h2>Select HIPAA concern</h2>
                </div>
                <br />
                <div className="notes-select">
                    <Select
                        options={hipaaIncompliantReasonOptions}
                        placeholder={hipaaIncompliantReasonOptions[0].label}
                        onChange={(e) => setIncompliantReason(e.value)}
                    />
                </div>
                <div>
                    {loading && <LoadingSpinner />}
                    {error && <ErrorMessage message={error.message} />}
                    {!loading && !error && offices.length < 1 ? (
                        <p>No concerns</p>
                    ) : null}
                    {offices.length > 0 && (
                        <OfficeNotesList
                            offices={offices}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                            customContainerStyles={{ marginTop: '10px' }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default HipaaConcernsPage;
