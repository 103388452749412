import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectActiveTab } from '../../redux/slices/header-slice.js';
import useAuthentication from '../../hooks/useAuthentication.js';

/**
 * React component representing a header link with optional navigation.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {string} props.title - The title or label for the header link.
 * @param {string} props.to - The target URL for navigation.
 * @returns {JSX.Element} The rendered HeaderLink component.
 * @example
 * // Example usage of the HeaderLink component
 * import HeaderLink from './path/to/HeaderLink';
 * // ...
 * <HeaderLink title="Home" to="/" />
 */
const HeaderLink = ({ title, to }) => {
    const activeTab = useSelector(selectActiveTab);
    const isActive = title === activeTab;
    const { logout } = useAuthentication();

    if (title === 'Logout') {
        return (
            <div
                className="header-link-inactive"
                onClick={async () => logout()}>
                <p
                    style={{
                        color: isActive ? 'white' : 'black'
                    }}
                    className="header-link-text">
                    {title}
                </p>
            </div>
        );
    }

    return (
        <Link
            to={to}
            className={
                isActive ? 'header-link-active' : 'header-link-inactive'
            }>
            <p
                style={{
                    color: isActive ? 'white' : 'black'
                }}
                className="header-link-text">
                {title}
            </p>
        </Link>
    );
};

// Prop validation
HeaderLink.propTypes = {
    title: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired
};

export default HeaderLink;
