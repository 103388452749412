import React from 'react';
import HeaderLink from './HeaderLink.jsx';
import { headerLinks } from '../../config/standard-links.js';

/**
 * React component representing full navigation links in the header.
 *
 * @component
 * @returns {JSX.Element} The rendered LinksFull component.
 */
const LinksFull = () => {
    return (
        <div className="header-links-container">
            {headerLinks.map((link) => (
                <HeaderLink key={link.title} title={link.title} to={link.to} />
            ))}
        </div>
    );
};

export default LinksFull;
