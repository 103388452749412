import PropTypes from 'prop-types';

/**
 * Component for rendering a row in the statistical table.
 * @param {object} props - The props object.
 * @param {Array<object>} props.data - An array of objects representing the data for each cell in the row.
 * @returns {JSX.Element} The JSX element representing the StatTableRow component.
 */
const StatTableRow = ({ data }) => {
    return (
        <tr>
            {data.map((piece) => (
                <td
                    key={piece.id}
                    style={{
                        borderRight: '1px #DCDCDC solid',
                        borderBottom: '1px #DCDCDC solid',
                        padding: '.5em'
                    }}>
                    {piece.data}
                </td>
            ))}
        </tr>
    );
};

StatTableRow.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default StatTableRow;
