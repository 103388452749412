import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setActiveTab } from '../../redux/slices/header-slice.js';
import SubLinks from '../../components/header/SubLinks.jsx';
import { hipaaLinks } from '../../config/standard-links.js';
import BackArrow from '../../components/common/BackArrow.jsx';
import SendOsFailEmailForm from '../../components/hipaa/SendOsFailEmailForm.jsx';

/**
 * Component for rendering the page to send OS HIPAA fail email to an office.
 * @returns {JSX.Element} SendOsFailEmailToOfficePage component.
 */
const SendOsFailEmailToOfficePage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveTab('HIPAA'));

        return () => {
            dispatch(setActiveTab(null));
        };
    });

    return (
        <div>
            <SubLinks links={hipaaLinks} />
            <div className="newsc-container">
                <br />
                <br />
                <br />
                <div className="create-office-heading">
                    <BackArrow navigateTo={-1} />
                    <h2>Send OS HIPAA Fail Email to Office</h2>
                </div>
                <br />
                <SendOsFailEmailForm />
            </div>
        </div>
    );
};

export default SendOsFailEmailToOfficePage;
