import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select';
import { jwtDecode } from 'jwt-decode';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import ErrorMessage from '../common/ErrorMessage.jsx';
import useReporting from '../../hooks/useReporting.js';
import {
    itDeptOptions,
    timePeriodOptions
} from '../../config/dropdown-options.js';
import { green, lightBlue, red, yellow } from '../../styles/standard-colors.js';
import chartColors from '../../styles/chart-colors.js';
import BackandForthArrows from '../common/BackandForthArrows.jsx';
import useUsers from '../../hooks/useUsers.js';

/**
 * Component for displaying quick graphs on the dashboard.
 * @returns {JSX.Element} JSX element representing the QuickGraphs component.
 */
const QuickGraphs = () => {
    const { loading, error, charts, queryType, tables, runQuery } =
        useReporting();

    const { getUser, user } = useUsers();

    const [selectedTimePeriod, setSelectedTimePeriod] = useState(
        timePeriodOptions[0]
    );
    const [selectedItSegment, setSelectedItSegment] = useState(
        itDeptOptions[0]
    );
    const [current, setCurrent] = useState(1);
    const [maxScroll, setMaxScroll] = useState(1);

    /**
     * Fetches data from the server.
     * @returns {void}
     */
    useEffect(() => {
        const fetchData = async () => {
            const token = sessionStorage.getItem('accessToken');
            const decoded = jwtDecode(token);

            const returnedUser = await getUser(decoded.sub);
            if (
                returnedUser &&
                (returnedUser.role.name === 'manager' ||
                    returnedUser.role.name === 'dev')
            ) {
                await runQuery({
                    timePeriod: selectedTimePeriod.value,
                    itSegment: selectedItSegment.value
                });
            } else {
                await runQuery({
                    timePeriod: selectedTimePeriod.value,
                    userId: decoded.sub
                });
            }
        };

        fetchData();
    }, [selectedTimePeriod, selectedItSegment]);

    useEffect(() => {
        const adjustMaxScroll = async () => {
            switch (queryType) {
                case 'hd': {
                    setMaxScroll(1);
                    break;
                }
                case 'newsc/admin': {
                    setMaxScroll(2);
                    break;
                }
                case 'nclt': {
                    setMaxScroll(4);
                    break;
                }
                default:
            }
        };

        adjustMaxScroll();
    }, [queryType]);

    const handleClickBackArrow = () => {
        if (current !== 1) {
            setCurrent(current - 1);
        } else {
            setCurrent(1);
        }
    };
    const handleClickForwardArrow = () => {
        if (current !== maxScroll) {
            setCurrent(current + 1);
        } else {
            setCurrent(maxScroll);
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'normal',
                height: '100%',
                width: '100%'
            }}>
            <h2>Quick Graphs</h2>
            <br />
            <div style={{ width: '50%' }}>
                <Select
                    options={timePeriodOptions}
                    onChange={(selectedOption) =>
                        setSelectedTimePeriod(selectedOption)
                    }
                    value={selectedTimePeriod}
                />
                {user &&
                    (user.role.name === 'manager' ||
                        user.role.name === 'dev') && (
                        <>
                            <br />
                            <Select
                                options={itDeptOptions}
                                onChange={(selectedOption) =>
                                    setSelectedItSegment(selectedOption)
                                }
                                value={selectedItSegment}
                            />
                        </>
                    )}
            </div>
            <br />
            <>
                {loading && <LoadingSpinner />}
                {error && <ErrorMessage message={error.message} />}
                {queryType === 'hd' && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            height: '100%',
                            alignItems: 'center'
                        }}>
                        {' '}
                        {charts && charts.length > 0 && (
                            <>
                                <h3 style={{ fontStyle: 'italic' }}>
                                    Tickets Resolved
                                </h3>
                                <ResponsiveContainer width="95%" height={400}>
                                    <BarChart
                                        data={charts}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5
                                        }}>
                                        <CartesianGrid />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar
                                            dataKey="# of Tickets Resolved"
                                            fill={lightBlue}
                                        />
                                        <Bar
                                            dataKey="# of Tickets Not Resolved Within Brand Promise"
                                            fill={green}
                                        />
                                    </BarChart>
                                </ResponsiveContainer>
                            </>
                        )}
                    </div>
                )}

                {queryType === 'newsc/admin' && (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                height: '100%',
                                alignItems: 'center'
                            }}>
                            {charts && charts.length > 0 && (
                                <>
                                    {current === 1 && (
                                        <>
                                            <h3 style={{ fontStyle: 'italic' }}>
                                                Tickets/Tasks Resolved
                                            </h3>
                                            <ResponsiveContainer
                                                width="95%"
                                                height={400}>
                                                <BarChart
                                                    data={charts}
                                                    margin={{
                                                        top: 5,
                                                        right: 30,
                                                        left: 20,
                                                        bottom: 5
                                                    }}>
                                                    <CartesianGrid />
                                                    <XAxis dataKey="name" />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Legend />
                                                    <Bar
                                                        dataKey="# of Tickets Resolved"
                                                        fill={lightBlue}
                                                    />
                                                    <Bar
                                                        dataKey="# of Admin Tasks Completed"
                                                        fill={red}
                                                    />
                                                    <Bar
                                                        dataKey="# of Admin Tasks Completed Outside SLA"
                                                        fill={yellow}
                                                    />
                                                    <Bar
                                                        dataKey="# of Installs"
                                                        fill={green}
                                                    />
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </>
                                    )}
                                    {current === 2 && (
                                        <>
                                            <h3 style={{ fontStyle: 'italic' }}>
                                                Average Completion Time
                                            </h3>
                                            <ResponsiveContainer
                                                width="95%"
                                                height={400}>
                                                <LineChart
                                                    width={500}
                                                    height={300}
                                                    data={charts}
                                                    margin={{
                                                        top: 5,
                                                        right: 30,
                                                        left: 20,
                                                        bottom: 5
                                                    }}>
                                                    <CartesianGrid />
                                                    <XAxis dataKey="name" />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Legend />
                                                    <Line
                                                        type="linear"
                                                        dataKey="Completion Time Avg (Days)"
                                                        fill={lightBlue}
                                                        stroke={lightBlue}
                                                    />
                                                </LineChart>
                                            </ResponsiveContainer>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'right'
                            }}>
                            <BackandForthArrows
                                size={22}
                                onClickBackward={handleClickBackArrow}
                                onClickForward={handleClickForwardArrow}
                            />
                        </div>
                    </>
                )}

                {queryType === 'nclt' && (
                    <>
                        {' '}
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                height: '100%',
                                alignItems: 'center'
                            }}>
                            {' '}
                            {charts && charts.length > 0 && (
                                <>
                                    {current === 1 && (
                                        <>
                                            <h3
                                                key={`launches-chart`}
                                                style={{ fontStyle: 'italic' }}>
                                                Launches
                                            </h3>
                                            <ResponsiveContainer
                                                width="95%"
                                                height={400}>
                                                <BarChart
                                                    data={charts}
                                                    margin={{
                                                        top: 5,
                                                        right: 30,
                                                        left: 20,
                                                        bottom: 5
                                                    }}>
                                                    <CartesianGrid />
                                                    <XAxis dataKey="name" />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Legend />
                                                    <Bar
                                                        dataKey="Total Launches"
                                                        fill={lightBlue}
                                                    />
                                                    {tables[1].rows.map(
                                                        (service, index) => (
                                                            <Bar
                                                                key={uuidv4()}
                                                                dataKey={`${service[0].data} Launches`}
                                                                fill={
                                                                    chartColors[
                                                                        index
                                                                    ]
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </>
                                    )}
                                    {current === 2 && (
                                        <>
                                            <h3
                                                key={`days-in-it-chart`}
                                                style={{ fontStyle: 'italic' }}>
                                                Avg Days in IT
                                            </h3>
                                            <ResponsiveContainer
                                                width="95%"
                                                height={400}>
                                                <LineChart
                                                    width={500}
                                                    height={300}
                                                    data={charts}
                                                    margin={{
                                                        top: 5,
                                                        right: 30,
                                                        left: 20,
                                                        bottom: 5
                                                    }}>
                                                    <CartesianGrid />
                                                    <XAxis dataKey="name" />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Legend />
                                                    <Line
                                                        dataKey="Total Avg Days in IT"
                                                        fill="#155180"
                                                        stroke="#155180"
                                                    />
                                                    {tables[1].rows.map(
                                                        (service, index) => (
                                                            <Line
                                                                key={uuidv4()}
                                                                dataKey={`${service[0].data} Avg Days in IT`}
                                                                fill={
                                                                    chartColors[
                                                                        index
                                                                    ]
                                                                }
                                                                stroke={
                                                                    chartColors[
                                                                        index
                                                                    ]
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </LineChart>
                                            </ResponsiveContainer>
                                        </>
                                    )}
                                    {current === 3 && (
                                        <>
                                            <h3
                                                key={`5days-chart`}
                                                style={{ fontStyle: 'italic' }}>
                                                % Completed Outside 5 Days
                                            </h3>
                                            <ResponsiveContainer
                                                width="95%"
                                                height={400}>
                                                <LineChart
                                                    width={500}
                                                    height={300}
                                                    data={charts}
                                                    margin={{
                                                        top: 5,
                                                        right: 30,
                                                        left: 20,
                                                        bottom: 5
                                                    }}>
                                                    <CartesianGrid />
                                                    <XAxis dataKey="name" />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Legend />
                                                    <Line
                                                        dataKey="Total % Completed Outside 5 Days"
                                                        fill="#155180"
                                                        stroke="#155180"
                                                    />
                                                    {tables[1].rows.map(
                                                        (service, index) => (
                                                            <Line
                                                                key={uuidv4()}
                                                                dataKey={`${service[0].data} % Completed Outside 5 Days`}
                                                                fill={
                                                                    chartColors[
                                                                        index
                                                                    ]
                                                                }
                                                                stroke={
                                                                    chartColors[
                                                                        index
                                                                    ]
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </LineChart>
                                            </ResponsiveContainer>
                                        </>
                                    )}
                                    {current === 4 && (
                                        <>
                                            <h3
                                                key={`avg-launch-time-chart`}
                                                style={{ fontStyle: 'italic' }}>
                                                Avg Launch Time (Days)
                                            </h3>
                                            <ResponsiveContainer
                                                width="95%"
                                                height={400}>
                                                <LineChart
                                                    width={500}
                                                    height={300}
                                                    data={charts}
                                                    margin={{
                                                        top: 5,
                                                        right: 30,
                                                        left: 20,
                                                        bottom: 5
                                                    }}>
                                                    <CartesianGrid />
                                                    <XAxis dataKey="name" />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Legend />
                                                    <Line
                                                        dataKey="Total Avg Launch Time (Days)"
                                                        fill="#155180"
                                                        stroke="#155180"
                                                    />
                                                    {tables[1].rows.map(
                                                        (service, index) => (
                                                            <Line
                                                                key={uuidv4()}
                                                                dataKey={`${service[0].data} Avg Launch Time (Days)`}
                                                                fill={
                                                                    chartColors[
                                                                        index
                                                                    ]
                                                                }
                                                                stroke={
                                                                    chartColors[
                                                                        index
                                                                    ]
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </LineChart>
                                            </ResponsiveContainer>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'right'
                            }}>
                            <BackandForthArrows
                                size={22}
                                onClickBackward={handleClickBackArrow}
                                onClickForward={handleClickForwardArrow}
                            />
                        </div>
                    </>
                )}
            </>
        </div>
    );
};

export default QuickGraphs;
