import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import SubmitButton from '../common/SubmitButton.jsx';
import { sendOsFailEmailFormFields } from '../../config/form-fields.js';
import ErrorMessage from '../common/ErrorMessage.jsx';
import SuccessMessage from '../common/SuccessMessage.jsx';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import useHipaa from '../../hooks/useHipaa.js';
import sendOsFailEmailValidation from '../../validation/send-os-fail-email-validation.js';
import useOffices from '../../hooks/useOffices.js';

/**
 * SendOsFailEmailForm component for rendering the form to send the HIPAA OS fail email.
 *
 * @component
 * @returns {JSX.Element} - The rendered SendOsFailEmailForm component.
 */
const SendOsFailEmailForm = () => {
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [placeholderMessage, setPlaceholderMessage] = useState('');

    const {
        error: emailError,
        loading,
        isSendEmailSuccess,
        sendHipaaOsFailEmail
    } = useHipaa();
    const { error: officesError, getAllOffices } = useOffices();

    useEffect(() => {
        const getOffices = async () => {
            let returnedOffices = await getAllOffices();
            returnedOffices = returnedOffices.map((o) => {
                // eslint-disable-next-line no-param-reassign
                o = {
                    value: o.id,
                    label: o.officeSysName
                };
                return o;
            });
            setDropdownOptions(returnedOffices);
        };

        setPlaceholderMessage('Loading offices');
        setPlaceholderMessage('Search and select an office');
        getOffices();
    }, []);

    const formik = useFormik({
        initialValues: sendOsFailEmailFormFields,
        validationSchema: sendOsFailEmailValidation,
        onSubmit: async (values) => {
            await sendHipaaOsFailEmail(values.officeId);
        }
    });

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="newsc-form-container">
                    <label htmlFor="officeId" className="form-label">
                        Office
                    </label>
                    <Select
                        id="officeId"
                        name="officeId"
                        placeholder={
                            officesError
                                ? 'Error loading offices'
                                : placeholderMessage
                        }
                        options={dropdownOptions}
                        value={dropdownOptions.find(
                            (option) => option.value === formik.values.officeId
                        )}
                        onChange={(selectedOption) =>
                            formik.setFieldValue(
                                'officeId',
                                selectedOption ? selectedOption.value : null
                            )
                        }
                    />
                    {formik.touched.officeId && formik.errors.officeId ? (
                        <ErrorMessage message={formik.errors.officeId} />
                    ) : null}

                    <SubmitButton label="Send email" />
                </div>
            </form>
            <br />
            <br />
            {loading && <LoadingSpinner />}
            {Object.keys(formik.errors).length !== 0 && (
                <ErrorMessage message="There are form errors above, please fix." />
            )}
            {emailError && <ErrorMessage message={emailError.message} />}
            {isSendEmailSuccess && (
                <SuccessMessage message="Email sent successfully" />
            )}
        </>
    );
};

export default SendOsFailEmailForm;
