import { useState } from 'react';
import convertError from '../utils/error-converter.js';
import UserFriendlyError from '../utils/UserFriendlyError.js';
import callApi from '../utils/call-api.js';
import analyzeApiResponse from '../utils/analyze-api-response.js';

/**
 * Custom React hook for managing New SC(Success Consultant)-related data.
 *
 * @returns {Object} An object containing state variables and functions related to user data.
 * @property {Array<Object>} newScs - The array of returned new SCs (empty if none returned).
 * @property {number} totalPages - The total number of pages for pagination.
 * @property {Object|null} newSc - The returned new SC (null if none returned).
 * @property {Object|null} addToPlatformsResult - The result object of the process with http status codes.
 * @property {boolean} loading - A boolean indicating whether data is currently being loaded.
 * @property {Object|null} error - An error object containing details about any encountered error.
 * @property {Function} searchNewScs - A function to search for new SCs based on criteria.
 * @property {Function} createNewSc - A function to create a new SC.
 * @property {Function} updateNewSc - A function to update an new SC.
 * @property {Function} getNewSc - A function to get a specific new SC by id.
 * @property {Function} addUserToPlatforms - A function to add a user by id to various software platforms and send automated emails
 */
const useNewScs = () => {
    const [newScs, setNewScs] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [newSc, setNewSc] = useState(null);
    const [addToPlatformsResult, setAddToPlatformsResult] = useState(null);
    const [createNewScResult, setCreateNewScResult] = useState(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    /**
     * Searches for new SCs based on specified criteria.
     *
     * @async
     * @function
     * @param {string} [searchString=''] - The search string for filtering new SCs by name.
     * @param {string} [type='active'] - The status type of new SCs to search (e.g., 'active', 'retired', 'expired', 'awaitingAssignment', 'all').
     * @param {string} [software=null] - the software to filter by
     * @param {number} [currentPage=1] - The current page for pagination.
     * @param {number} [limit=40] - The number of offices to retrieve per page.
     * @param {string} [sortBy='lastName:asc'] - The key to sort by
     * @param {boolean} [paginate=true] - whether to paginate the results
     * @returns {Promise<Array>} A promise that resolves with a list of returned users.
     */
    const searchNewScs = async (
        searchString = '',
        type = 'active',
        software = null,
        currentPage = 1,
        limit = 40,
        sortBy = 'lastName:asc',
        paginate = true
    ) => {
        try {
            setLoading(true);
            setError(null);

            const path = software
                ? `/new-SCs/search?search=${searchString}&type=${type}&software=${software}&limit=${limit}&page=${currentPage}&sortBy=${sortBy}&paginate=${paginate}`
                : `/new-SCs/search?search=${searchString}&type=${type}&limit=${limit}&page=${currentPage}&sortBy=${sortBy}&paginate=${paginate}`;

            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const { results: returnedNewScs, totalPages: returnedTotalPages } =
                await response.json();
            setNewScs(returnedNewScs);
            setTotalPages(returnedTotalPages);
            return returnedNewScs;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
        } finally {
            setLoading(false);
        }
    };

    /**
     * Creates a new SC.
     *
     * @async
     * @function
     * @param {Object} newScFields - The fields for creating the new SC, passed from formik. Reference config/form-fields.js or validation/create-new-sc-validations.js for details on properties.
     * @returns {Promise<Object>} A promise that resolves when the new SC creation is complete with the response object.
     */
    const createNewSc = async (newScFields) => {
        try {
            setLoading(true);
            setError(null);

            const path = `/new-SCs`;
            const response = await callApi(path, 'post', newScFields);

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const processResult = await response.json();
            setCreateNewScResult(processResult);
            return processResult;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Updates a new SC.
     *
     * @async
     * @function
     * @param {String} newScId
     * @param {Object} updateNewScFields - The fields for updating the new SC, passed from formik. Reference config/form-fields.js or validation/create-new-sc-validations.js for details on properties.
     * @returns {Promise<NewSc>} A promise that resolves when the new SC update is complete with the new SC object.
     */
    const updateNewSc = async (newScId, updateNewScFields) => {
        try {
            setLoading(true);
            setError(null);

            const updateNewScFieldsModified = { ...updateNewScFields };

            // Remove any more fields that are not allowed
            delete updateNewScFieldsModified.id;
            if (updateNewScFieldsModified.software) {
                updateNewScFieldsModified.software =
                    updateNewScFieldsModified.software.map((obj) => {
                        // Destructure and remove _id and leave the rest
                        const { _id, ...rest } = obj;
                        return rest;
                    });
            }

            const path = `/new-SCs/${newScId}`;
            const response = await callApi(
                path,
                'patch',
                updateNewScFieldsModified
            );

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const updatedNewSc = await response.json();
            setNewSc(updatedNewSc);
            return updatedNewSc;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Gets a single new SC and it's info by ID.
     *
     * @async
     * @function
     * @param {string} newScId
     * @returns {Promise<NewSc>} A promise that resolves with the returned new SC.
     */
    const getNewSc = async (newScId) => {
        try {
            setLoading(true);
            setError(null);

            const path = `/new-SCs/${newScId}`;
            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const returnedNewSc = await response.json();
            setNewSc(returnedNewSc);
            return returnedNewSc;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Triggers the automation process for adding a user to various software platforms and sending setup emails.
     *
     * @async
     * @function
     * @param {Object} userData - The fields for triggering the automation, passed from formik. Reference config/form-fields.js or validation/add-user-to-platforms-validations.js for details on properties.
     * @returns {Promise<Object>} A promise that resolves when the process is complete with the result object.
     */
    const addUserToPlatforms = async (userData) => {
        try {
            setLoading(true);
            setError(null);

            const path = `/new-SCs/add-to-platforms`;
            const response = await callApi(path, 'post', userData);

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const processResult = await response.json();
            setAddToPlatformsResult(processResult);
            return processResult;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    return {
        newScs,
        totalPages,
        newSc,
        loading,
        error,
        searchNewScs,
        createNewSc,
        updateNewSc,
        getNewSc,
        addUserToPlatforms,
        addToPlatformsResult,
        createNewScResult
    };
};

export default useNewScs;
