import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import SubmitButton from '../common/SubmitButton.jsx';
import { deleteUserFormFields } from '../../config/form-fields.js';
import ErrorMessage from '../common/ErrorMessage.jsx';
import SuccessMessage from '../common/SuccessMessage.jsx';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import useUsers from '../../hooks/useUsers.js';
import deleteUserValidation from '../../validation/delete-user-validation.js';

/**
 * DeleteUserForm component for rendering the form to delete a user.
 *
 * @component
 * @returns {JSX.Element} - The rendered DeleteUserForm component.
 */
const DeleteUserForm = () => {
    const [dropdownOptions, setDropdownOptions] = useState([]);

    const { error, loading, isDeleteSuccess, getAllUsers, deleteUser } =
        useUsers();

    const formik = useFormik({
        initialValues: deleteUserFormFields,
        validationSchema: deleteUserValidation,
        onSubmit: async (values) => {
            await deleteUser(values.userId);
        }
    });

    useEffect(() => {
        const getUsers = async () => {
            let returnedUsers = await getAllUsers();
            returnedUsers = returnedUsers.map((u) => {
                // eslint-disable-next-line no-param-reassign
                u = {
                    value: u.id,
                    label: u.email
                };
                return u;
            });
            setDropdownOptions(returnedUsers);
        };

        getUsers();
    }, []);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="newsc-form-container">
                    <label htmlFor="userId" className="form-label">
                        Select a user to delete
                    </label>
                    <Select
                        id="userId"
                        name="userId"
                        options={dropdownOptions}
                        value={dropdownOptions.find(
                            (option) => option.value === formik.values.userId
                        )}
                        onChange={(selectedOption) =>
                            formik.setFieldValue(
                                'userId',
                                selectedOption ? selectedOption.value : null
                            )
                        }
                    />
                    {formik.touched.userId && formik.errors.userId ? (
                        <ErrorMessage message={formik.errors.userId} />
                    ) : null}
                    <br />
                    <br />
                    <SubmitButton label="Delete" />
                </div>
            </form>
            <br />
            <br />
            {loading && <LoadingSpinner />}
            {Object.keys(formik.errors).length !== 0 && (
                <ErrorMessage message="There are form errors above, please fix." />
            )}
            {error && <ErrorMessage message={error.message} />}
            {isDeleteSuccess && (
                <SuccessMessage message="User has been deleted!" />
            )}
        </>
    );
};

DeleteUserForm.propTypes = {
    objectToUpdate: PropTypes.object
};

export default DeleteUserForm;
