import * as Yup from 'yup';
import { validEassistEmail, validUsPhoneNumber } from './custom-validations.js';

/**
 * Yup validation schema for running the add user to software platforms process automation.
 *
 * @constant
 * @type {object}
 * @property {Yup.StringSchema} firstName
 * @property {Yup.StringSchema} lastName
 * @property {Yup.StringSchema} eassistEmail - Validation for the user eassist email address.
 * @property {Yup.StringSchema} personalEmail - Validation for the user personal email address.
 * @property {Yup.StringSchema} phoneNumber
 */
const addUserToSoftwarePlatformsValidation = Yup.object({
    firstName: Yup.string()
        .trim('Remove any leading or trailing spaces')
        .required('Required'),
    lastName: Yup.string()
        .trim('Remove any leading or trailing spaces')
        .required('Required'),
    eassistEmail: Yup.string()
        .email('Must be a valid email address')
        .trim('Remove any leading or trailing spaces')
        .required('Required')
        .matches(validEassistEmail, {
            message: 'Must be a valid eAssist Email (@eassist.me)'
        }),
    personalEmail: Yup.string()
        .email('Must be a valid email address')
        .trim('Remove any leading or trailing spaces')
        .required('Required'),
    phoneNumber: Yup.string().trim().matches(validUsPhoneNumber, {
        message:
            'Must be a valid US phone number in this format: (801) 123-1234'
    })
});

export default addUserToSoftwarePlatformsValidation;
