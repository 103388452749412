import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setActiveTab } from '../../redux/slices/header-slice.js';
import SubLinks from '../../components/header/SubLinks.jsx';
import { userManagementLinks } from '../../config/standard-links.js';
import DeleteUserForm from '../../components/users/DeleteUserForm.jsx';
import BackArrow from '../../components/common/BackArrow.jsx';

/**
 * Component for deleting a user.
 * @returns {JSX.Element} DeleteUserPage component.
 */
const DeleteUserPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveTab('Users'));

        return () => {
            dispatch(setActiveTab(null));
        };
    });

    return (
        <div>
            <SubLinks links={userManagementLinks} />
            <div className="newsc-container">
                <br />
                <br />
                <br />
                <div className="create-office-heading">
                    <BackArrow navigateTo={-1} />
                    <h2>Delete a user</h2>
                </div>
                <br />
                <DeleteUserForm />
            </div>
        </div>
    );
};

export default DeleteUserPage;
