/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from '../common/ErrorMessage.jsx';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import { selectOfficeObj } from '../../redux/slices/office-slice.js';
import TableTitleHeader from '../common/TableTitleHeader.jsx';
import { yellow } from '../../styles/standard-colors.js';
import CopyOfficeInfo from './CopyOfficeInfo.jsx';
import OfficeInfoTable from './OfficeInfoTable.jsx';
import ConfirmationModal from '../common/ConfirmationModal.jsx';
import useOffices from '../../hooks/useOffices.js';
import CreateUpdateOfficeForm from './CreateUpdateOfficeForm.jsx';
import SettingsDropdown from './SettingsDropdown.jsx';

/**
 * Component for displaying office information.
 * @returns {JSX.Element} JSX element representing the Office Information Container.
 */
const OfficeInfoContainer = () => {
    const navigate = useNavigate();
    const [showDropdown, setShowDropdown] = useState(false);
    const [showCopy, setShowCopy] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDuplicateOfficeNoteModal, setShowDuplicateOfficeNoteModal] =
        useState(false);
    const officeObj = useSelector(selectOfficeObj);
    const { error, loading, duplicateOffice } = useOffices();

    /**
     * Handles duplication of the office.
     * @returns {void}
     */
    const handleDuplication = async () => {
        setShowDuplicateOfficeNoteModal(false);

        const duplicatedOffice = await duplicateOffice(officeObj.id);

        // Check for failure
        if (!duplicatedOffice) {
            return;
        }

        navigate(`/office/${duplicatedOffice.id}`);
    };

    return (
        <div className="office-container">
            <TableTitleHeader
                includeBackArrow={true}
                title={officeObj.officeSysName}
                hasSettingsGear={true}
                settingsGearColor={yellow}
                setShowDropdown={setShowDropdown}
                showDropdown={showDropdown}
                customContainerStyles={{ marginLeft: '30px' }}
            />
            {loading && <LoadingSpinner />}
            {error && <ErrorMessage message={error.message} />}
            {showDropdown && (
                <SettingsDropdown
                    showCopy={showCopy}
                    setShowCopy={setShowCopy}
                    showEdit={showEdit}
                    setShowEdit={setShowEdit}
                    showDuplicateOfficeNoteModal={showDuplicateOfficeNoteModal}
                    setShowDuplicateOfficeNoteModal={
                        setShowDuplicateOfficeNoteModal
                    }
                />
            )}
            {officeObj.inSecureProtocol && (
                <div style={{ marginLeft: '30px' }}>
                    <h3 style={{ marginTop: 0, marginBottom: '2em' }}>
                        **DO NOT CONNECT OFFICE IS IN VIRUS PROTOCOL**
                    </h3>
                </div>
            )}
            {showCopy ? (
                <CopyOfficeInfo />
            ) : showEdit ? (
                <CreateUpdateOfficeForm
                    objectToUpdate={officeObj}
                    setShowEdit={setShowEdit}
                />
            ) : (
                <OfficeInfoTable />
            )}
            {showDuplicateOfficeNoteModal && (
                <ConfirmationModal
                    title="Duplicate Office Note"
                    message="Are you sure you want to duplicate this office note?"
                    onConfirm={handleDuplication}
                    setShowModal={setShowDuplicateOfficeNoteModal}
                />
            )}
        </div>
    );
};

export default OfficeInfoContainer;
