/* eslint-disable import/extensions */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { MdSupportAgent, MdQueryStats, MdAddToQueue } from 'react-icons/md';
import { setActiveTab } from '../../redux/slices/header-slice.js';
import SubLinks from '../../components/header/SubLinks.jsx';
import { reportingLinks } from '../../config/standard-links.js';
import { blue, pinkRed } from '../../styles/standard-colors.js';
import DashboardUser from '../../components/reporting/DashboardUser.jsx';
import QuickStats from '../../components/reporting/QuickStats.jsx';
import QuickGraphs from '../../components/reporting/QuickGraphs.jsx';

/**
 * Component for rendering the Reporting Dashboard page.
 * @returns {JSX.Element} ReportingDashboardPage component.
 */
const ReportingDashboardPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveTab('Reporting'));

        return () => {
            dispatch(setActiveTab(null));
        };
    });

    return (
        <div>
            <SubLinks links={reportingLinks} />
            <div className="reporting-home-container">
                <div className="reporting-home-row" style={{ height: '300px' }}>
                    <div
                        className="reporting-home-tile-hover"
                        style={{ height: '100%' }}>
                        <DashboardUser />
                    </div>
                    <div
                        className="reporting-home-tile-hover"
                        style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <h2>Weekly Stats</h2>
                        <br />
                        <br />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-evenly',
                                width: '100%'
                            }}>
                            <Link
                                to="/hdWeeklyStats"
                                className="department-logos">
                                <MdSupportAgent size={70} color={blue} />
                                <h3
                                    style={{
                                        color: blue
                                    }}>
                                    Help Desk
                                </h3>
                            </Link>
                            <Link
                                to="/newscweeklystats"
                                className="department-logos">
                                <AiOutlineUserAdd size={70} color={blue} />
                                <h3
                                    style={{
                                        color: blue
                                    }}>
                                    New SC/Admin
                                </h3>
                            </Link>
                            <Link
                                to="/ncltweeklystats"
                                className="department-logos">
                                <MdAddToQueue size={70} color={blue} />
                                <h3
                                    style={{
                                        color: blue
                                    }}>
                                    NCLT
                                </h3>
                            </Link>
                        </div>
                    </div>
                    <Link
                        to="/queryTool"
                        className="reporting-home-tile-hover"
                        style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textDecoration: 'none'
                        }}>
                        <h1
                            style={{
                                textDecoration: 'none',
                                color: 'black',
                                transition: 'font-size 0.3s ease'
                            }}>
                            Explore Data
                        </h1>
                        <MdQueryStats
                            size={70}
                            color={pinkRed}
                            style={{
                                transition: 'transform 0.3s ease'
                            }}
                        />
                    </Link>
                </div>
                <div className="reporting-home-row" style={{ height: '700px' }}>
                    <div
                        className="reporting-home-tile-hover"
                        style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <QuickStats />
                    </div>
                    <div
                        className="reporting-home-tile-hover"
                        style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <h1 style={{ fontSize: '50px' }}>Oracle Reporting</h1>
                    </div>
                    <div
                        className="reporting-home-tile-hover"
                        style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <QuickGraphs />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportingDashboardPage;
