import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

/**
 * React component for displaying error messages.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {string} props.message - The error message to be displayed.
 * @returns {JSX.Element} The rendered ErrorMessage component.
 * @example
 * // Example usage of the ErrorMessage component
 * import ErrorMessage from './path/to/ErrorMessage';
 * // ...
 * <ErrorMessage message="An error occurred. Please try again." />
 */
const ErrorMessage = ({ message }) => {
    const messageLines = message.split('\n');

    return (
        <div autoFocus className="error-message-container">
            {messageLines.map((line) => {
                return (
                    <p key={uuidv4()} className="error-message-text">
                        {line}
                    </p>
                );
            })}
        </div>
    );
};

ErrorMessage.propTypes = {
    message: PropTypes.string.isRequired
};

export default ErrorMessage;
