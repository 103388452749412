import PropTypes from 'prop-types';

/**
 * Renders a confirmation modal with a title, message, and confirm/cancel buttons.
 *
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the confirmation modal.
 * @param {string} props.message - The message displayed in the confirmation modal.
 * @param {Function} props.onConfirm - The function to be called when the confirm button is clicked.
 * @param {Function} props.setShowModal - The function to control the visibility of the modal.
 * @returns {JSX.Element} JSX element representing the confirmation modal.
 */
const ConfirmationModal = (props) => {
    const { title, message, onConfirm, setShowModal } = props;

    /**
     * Handles the confirmation action.
     *
     * @returns {Promise<void>} Promise that resolves after the confirmation action is completed.
     */
    const handleConfirm = async () => {
        await onConfirm();
        setShowModal(false);
    };

    return (
        <>
            <div className="modal-overlay">
                <div className="modal-wrapper">
                    <div className="modal">
                        <div className="modal-header">
                            <h5 className="modal-heading">{title}</h5>
                        </div>
                        <div className="modal-message">{message}</div>
                        <div className="modal-btns">
                            <button
                                className="modal-confirm-btn"
                                onClick={() => handleConfirm()}
                                autoFocus={true}>
                                Confirm
                            </button>
                            <button
                                className="modal-cancel-btn"
                                onClick={() => setShowModal(false)}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

// Prop validation
ConfirmationModal.propTypes = {
    setShowModal: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired
};

export default ConfirmationModal;
