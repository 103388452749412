import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setActiveTab } from '../../redux/slices/header-slice.js';
import SubLinks from '../../components/header/SubLinks.jsx';
import { hipaaLinks } from '../../config/standard-links.js';

/**
 * Component for rendering the HIPAA Home Page.
 * @returns {JSX.Element} HIPAA Home Page component.
 */
const HipaaHomePage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveTab('HIPAA'));

        return () => {
            dispatch(setActiveTab(null));
        };
    });

    return (
        <div>
            <SubLinks links={hipaaLinks} />
            <div className="user-container">
                <h2 className="user-title" style={{ textAlign: 'left' }}>
                    HIPAA
                </h2>
                <ul className="user-links-container">
                    <Link to="/hipaaconcerns" className="automation-link">
                        HIPAA Concerns
                    </Link>
                    <Link to="/hipaacorrections" className="automation-link">
                        HIPAA Corrections
                    </Link>
                    <Link to="/hipaaOsEmail" className="automation-link">
                        Send OS Fail Email
                    </Link>
                </ul>
            </div>
        </div>
    );
};

export default HipaaHomePage;
