import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Renders a card component representing a single item.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.newScCard - The card object containing card details.
 * @param {string} props.newScCard.id - The unique identifier of the card.
 * @param {string} props.newScCard.firstName - The first name associated with the card.
 * @param {string} props.newScCard.lastName - The last name associated with the card.
 * @param {string} props.newScCard.createdAt - The creation date of the card.
 * @param {boolean} props.newScCard.isActive - Indicates if the card is active.
 * @returns {JSX.Element} JSX element representing the card component.
 */
const Card = ({ newScCard }) => {
    const [flagged, setFlagged] = useState(false);

    useEffect(() => {
        const dateCreated = newScCard.createdAt
            ? new Date(newScCard.createdAt)
            : null;
        const today = new Date();
        const daysDiff = dateCreated
            ? today.getTime() / (1000 * 60 * 60 * 24) -
              dateCreated.getTime() / (1000 * 60 * 60 * 24)
            : null;
        if (daysDiff && daysDiff >= 121 && newScCard.isActive) setFlagged(true);
    }, []);

    return (
        <Link
            className={
                flagged ? 'newsc-single-card-flagged-days' : 'newsc-single-card'
            }
            to={`/newsc/${newScCard.id}`}>
            <p>{`${newScCard.lastName}, ${newScCard.firstName}`}</p>
        </Link>
    );
};

Card.propTypes = {
    newScCard: PropTypes.object.isRequired
};

export default Card;
