import Select from 'react-select';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SearchBar from '../common/SearchBar.jsx';
import AddButton from '../common/AddButton.jsx';
import { pinkRed } from '../../styles/standard-colors.js';
import ErrorMessage from '../common/ErrorMessage.jsx';
import UserFriendlyError from '../../utils/UserFriendlyError.js';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import {
    newScCardSoftwareOptions,
    newScCardTypeOptions
} from '../../config/dropdown-options.js';

/**
 * NewScCardsSearchOptions component for rendering search and filter options for new SC cards.
 *
 * @component
 * @example
 * // Example usage:
 * <NewScCardsSearchOptions setSearchString={handleSearch} setType={handleTypeChange} setSoftware={handleSoftwareChange} error={error} loading={isLoading} />
 *
 * @param {Object} props - The component props.
 * @param {Function} props.setSearchString - The function to set the search string (required).
 * @param {Function} props.setType - The function to set the type of new SCs (required).
 * @param {Function} props.setSoftware - The function to set the software of new SCs (required).
 * @param {UserFriendlyError} [props.error] - The error object representing a user-friendly error.
 * @param {boolean} props.loading - A boolean indicating whether data is currently being loaded.
 *
 * @returns {JSX.Element} - The rendered NewScCardsSearchOptions component.
 */
const NewScCardsSearchOptions = ({
    setSearchString,
    setType,
    setSoftware,
    error,
    loading
}) => {
    return (
        <>
            <div className="notes-header-container">
                <h2 className="notes-title">New SC Vetting Cards</h2>
                <div className="notes-search-create">
                    <SearchBar
                        placeholder="Search"
                        onChange={setSearchString}
                    />
                    <AddButton
                        color={pinkRed}
                        size={34}
                        navigateTo="/addVettingCard"
                    />
                </div>
                <div className="notes-select">
                    <Select
                        options={newScCardTypeOptions}
                        placeholder="Active Vetted"
                        onChange={(e) => setType(e.value)}
                    />
                </div>
                <Link to="/addSC" className="automation-link top-right-button">
                    Manually Add a User
                </Link>
                <div className="notes-select">
                    <Select
                        options={newScCardSoftwareOptions}
                        placeholder="Filter by practice management software"
                        onChange={(e) => setSoftware(e.value)}
                    />
                </div>
                {loading && <LoadingSpinner />}
                {error && <ErrorMessage message={error.message} />}
            </div>
        </>
    );
};

NewScCardsSearchOptions.propTypes = {
    setSearchString: PropTypes.func.isRequired,
    setType: PropTypes.func.isRequired,
    setSoftware: PropTypes.func.isRequired,
    error: PropTypes.instanceOf(UserFriendlyError),
    loading: PropTypes.bool.isRequired
};

export default NewScCardsSearchOptions;
