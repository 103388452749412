/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ErrorMessage from '../common/ErrorMessage.jsx';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import TableTitleHeader from '../common/TableTitleHeader.jsx';
import { blue, yellow } from '../../styles/standard-colors.js';
import ConfirmationModal from '../common/ConfirmationModal.jsx';
import SettingsDropdown from './SettingsDropdown.jsx';
import {
    selectNewScObj,
    updateScActiveState
} from '../../redux/slices/new-sc-slice.js';
import useNewScs from '../../hooks/useNewScs.js';
import CreateUpdateNewScCardForm from './CreateUpdateNewScCardForm.jsx';
import CardInfoTable from './CardInfoTable.jsx';
import SoftwareTracker from './SoftwareTracker.jsx';

/**
 * Component to display information about a single SC card and manage its settings.
 *
 * @returns {JSX.Element} JSX element representing the SC card information container.
 */
const NewScCardInfoContainer = () => {
    const dispatch = useDispatch();
    const newScObj = useSelector(selectNewScObj);

    const [showDropdown, setShowDropdown] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showChangeActiveStateModal, setShowChangeActiveStateModal] =
        useState(false);
    const [flagged, setFlagged] = useState(false);
    const { error, loading, updateNewSc } = useNewScs();

    /**
     * Handles the change in active state of the SC card.
     */
    const handleChangeActiveState = async () => {
        setShowChangeActiveStateModal(false);

        const updatedNewSc = await updateNewSc(newScObj.id, {
            isActive: !newScObj.isActive
        });

        // Check for failure
        if (!updatedNewSc) {
            return;
        }

        dispatch(updateScActiveState(updatedNewSc.isActive));

        setShowDropdown(false);
    };

    useEffect(() => {
        const dateCreated = newScObj.createdAt
            ? new Date(newScObj.createdAt)
            : null;
        const today = new Date();
        const daysDiff = dateCreated
            ? today.getTime() / (1000 * 60 * 60 * 24) -
              dateCreated.getTime() / (1000 * 60 * 60 * 24)
            : null;
        if (daysDiff && daysDiff >= 121) setFlagged(true);
    }, []);

    return (
        <div className="office-container">
            {flagged && newScObj.isActive ? (
                <TableTitleHeader
                    includeBackArrow={true}
                    title={`${newScObj.lastName}, ${newScObj.firstName}`}
                    hasSettingsGear={true}
                    settingsGearColor={yellow}
                    setShowDropdown={setShowDropdown}
                    showDropdown={showDropdown}
                    hasActiveStateDot={true}
                    activeStateDotSize={40}
                    isRetired={true} // This is just to force it to be red, the object is not retired
                    customContainerStyles={{ marginLeft: '30px' }}
                />
            ) : (
                <TableTitleHeader
                    includeBackArrow={true}
                    title={`${newScObj.lastName}, ${newScObj.firstName}`}
                    hasSettingsGear={true}
                    settingsGearColor={yellow}
                    setShowDropdown={setShowDropdown}
                    showDropdown={showDropdown}
                    customContainerStyles={{ marginLeft: '30px' }}
                />
            )}

            {loading && <LoadingSpinner />}
            {error && <ErrorMessage message={error.message} />}
            {showDropdown && (
                <SettingsDropdown
                    showEdit={showEdit}
                    setShowEdit={setShowEdit}
                    showChangeActiveStateModal={showChangeActiveStateModal}
                    setShowChangeActiveStateModal={
                        setShowChangeActiveStateModal
                    }
                />
            )}
            {showEdit ? (
                <CreateUpdateNewScCardForm
                    objectToUpdate={newScObj}
                    setShowEdit={setShowEdit}
                />
            ) : (
                <CardInfoTable />
            )}
            <br />
            <TableTitleHeader
                title="Practice Management Software"
                underlineColor={blue}
                customContainerStyles={{
                    marginLeft: '30px',
                    justifyContent: 'center'
                }}
            />
            <SoftwareTracker />
            {showChangeActiveStateModal && (
                <ConfirmationModal
                    title={
                        newScObj.isActive
                            ? 'Retire SC Card'
                            : 'Re-activate SC Card'
                    }
                    message={
                        newScObj.isActive
                            ? 'Are you sure you want to retire this SC card?'
                            : 'Are you sure you want to re-activate this SC card?'
                    }
                    onConfirm={handleChangeActiveState}
                    setShowModal={setShowChangeActiveStateModal}
                />
            )}
        </div>
    );
};

export default NewScCardInfoContainer;
