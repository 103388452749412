import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ErrorMessage } from 'formik';
import { jwtDecode } from 'jwt-decode';
import { setActiveTab } from '../../redux/slices/header-slice.js';
import { setNewScObj } from '../../redux/slices/new-sc-slice.js';
import LoadingSpinner from '../../components/common/LoadingSpinner.jsx';
import useNewScs from '../../hooks/useNewScs.js';
import useUsers from '../../hooks/useUsers.js';
import NewScCardInfoContainer from '../../components/new-sc-vetting-cards/NewScCardInfoContainer.jsx';
import OnboardingContainer from '../../components/new-sc-vetting-cards/OnboardingContainer.jsx';

/**
 * React component for the page displaying a single new sc card and all it's info.
 *
 * @component
 * @returns {JSX.Element} The JSX representation of the ViewNewScCardPage component.
 */
const ViewNewScCardPage = () => {
    const dispatch = useDispatch();

    const { cardId } = useParams();
    const { error, loading, getNewSc } = useNewScs();
    const { getUser } = useUsers();
    const [userPermissions, setUserPermissions] = useState([]);

    useEffect(() => {
        dispatch(setActiveTab('New SC'));

        return () => {
            dispatch(setActiveTab(null));
        };
    });

    useEffect(() => {
        const getUserData = async () => {
            const token = sessionStorage.getItem('accessToken');

            const decoded = jwtDecode(token);

            const returnedUser = await getUser(decoded.sub);

            if (returnedUser) {
                setUserPermissions(returnedUser.role.permissions);
            }
        };

        getUserData();
    }, []);

    useEffect(() => {
        const getData = async () => {
            const returnedNewScCard = await getNewSc(cardId);
            dispatch(setNewScObj(returnedNewScCard));
        };

        getData();
    }, [cardId, dispatch]);

    if (loading)
        return (
            <div style={{ marginTop: '300px' }}>
                <LoadingSpinner />
            </div>
        );
    if (error)
        return (
            <div style={{ marginTop: '300px' }}>
                <ErrorMessage message={error.message} />
            </div>
        );

    return (
        <div className="offices-container">
            <NewScCardInfoContainer />
            <OnboardingContainer userPermissions={userPermissions} />
        </div>
    );
};

export default ViewNewScCardPage;
