import * as Yup from 'yup';
import { validObjectId } from './custom-validations.js';

/**
 * Yup validation schema for sending the HIPAA OS Fail email.
 *
 * @constant
 * @type {object}
 * @property {Yup.StringSchema} officeId
 */
const sendOsFailEmailValidation = Yup.object({
    officeId: Yup.string()
        .required('Required')
        .matches(validObjectId, {
            message: 'Must be a valid ObjectId'
        })
        .trim('Remove any leading or trailing spaces')
});

export default sendOsFailEmailValidation;
