/**
 * Converts a given date string to a formatted string representing the date and time in local time zone.
 * @param {string} time - The date string to convert (e.g., 'YYYY-MM-DDTHH:mm:ss.sssZ').
 * @returns {string} A string representing the date and time in the format 'YYYY-MM-DD HH:MM:SS'.
 */
const getTimeStamp = (time) => {
    const date = new Date(time); // UTC date equivalent
    const dateStr = date.toLocaleString(); // convert original UTC date object to the local time but in string format
    const localDate = new Date(dateStr); // convert the locale string back to a Date object
    const year = localDate.getFullYear();
    const month = localDate.getMonth() + 1;
    const day = localDate.getDate();
    const hours = localDate.getHours();
    const minutes = localDate.getMinutes();
    const seconds = localDate.getSeconds();
    return `${year}-${month.toString().padStart(2, '0')}-${day
        .toString()
        .padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

export default getTimeStamp;
