import { useFormik } from 'formik';
import SubmitButton from '../common/SubmitButton.jsx';
import { runUserDataTransferFormFields } from '../../config/form-fields.js';
import ErrorMessage from '../common/ErrorMessage.jsx';
import SuccessMessage from '../common/SuccessMessage.jsx';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import useAutomations from '../../hooks/useAutomations.js';
import { runUserDataTransferValidation } from '../../validation/admin-automation-validations.js';

/**
 * UserDataTransferForm component for rendering the form to trigger the User Data Transfer automation process.
 *
 * @component
 * @returns {JSX.Element} - The rendered UserDataTransferForm component.
 */
const UserDataTransferForm = () => {
    const { error, loading, isDataTransferSuccess, runUserDataTransfer } =
        useAutomations();

    const formik = useFormik({
        initialValues: runUserDataTransferFormFields,
        validationSchema: runUserDataTransferValidation,
        onSubmit: async (values) => {
            await runUserDataTransfer(values);
        }
    });

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="newsc-form-container">
                    <h2>Transfer User Files</h2>
                    <label htmlFor="userEassistEmail" className="form-label">
                        User eAssist Email
                    </label>
                    <input
                        id="userEassistEmail"
                        type="text"
                        className="newsc-input"
                        placeholder="e.g. john.doe@eassist.me"
                        {...formik.getFieldProps('userEassistEmail')}
                    />
                    {formik.touched.userEassistEmail &&
                    formik.errors.userEassistEmail ? (
                        <ErrorMessage
                            autofocus
                            message={formik.errors.userEassistEmail}
                        />
                    ) : null}
                    <label
                        htmlFor="transferToEassistEmail"
                        className="form-label">
                        Transfer files to
                    </label>
                    <input
                        id="transferToEassistEmail"
                        type="text"
                        className="newsc-input"
                        placeholder="e.g. jane.doe@eassist.me"
                        {...formik.getFieldProps('transferToEassistEmail')}
                    />
                    {formik.touched.transferToEassistEmail &&
                    formik.errors.transferToEassistEmail ? (
                        <ErrorMessage
                            autofocus
                            message={formik.errors.transferToEassistEmail}
                        />
                    ) : null}
                    <SubmitButton label="Transfer files" />
                </div>
            </form>
            <br />
            <br />
            {loading && <LoadingSpinner />}
            {Object.keys(formik.errors).length !== 0 && (
                <ErrorMessage message="There are form errors above, please fix." />
            )}
            {error && <ErrorMessage message={error.message} />}
            {isDataTransferSuccess && (
                <SuccessMessage message="User data was transferred successfully" />
            )}
        </>
    );
};

export default UserDataTransferForm;
