import { useState } from 'react';
import convertError from '../utils/error-converter.js';
import oracleApiUrl from '../config/oracle-api-url.js';
import UserFriendlyError from '../utils/UserFriendlyError.js';
import analyzeApiResponse from '../utils/analyze-api-response.js';

/**
 * Custom React hook for handling authentication state and actions.
 *
 * @returns {{
 *   tokens: object | null,
 *   loading: boolean,
 *   error: Error | null,
 *   login: (googleJwt: string) => Promise<void>,
 *   logout: () => Promise<void>,
 * }}
 *
 * @example
 * // Usage
 * const { tokens, loading, error, login, logout } = useAuthentication();
 *
 * if (loading) {
 *   return (
 *      <LoadingSpinner />;
 *   )
 * }
 *
 * if (error) {
 *   return (
 *      <ErrorMessage message={error.message} />
 *   )
 * }
 *
 * return (
 *   <>
 *      <div onClick={login}>
 *          Login
 *       </div>
 *       <div onClick={logout}>
 *          Logout
 *       </div>
 *   </>
 * )
 */
const useAuthentication = () => {
    /**
     * Authentication tokens for the user.
     *
     * @type {object | null}
     */
    const [tokens, setTokens] = useState(null);

    /**
     * Loading state indicating whether authentication actions are in progress.
     *
     * @type {boolean}
     */
    const [loading, setLoading] = useState(false);

    /**
     * Error that occurred during authentication.
     *
     * @type {Error | null}
     */
    const [error, setError] = useState(null);

    /**
     * Logs in a user using a Google JWT.
     *
     * @async
     * @function
     * @param {string} googleJwt - Google JWT for authentication.
     * @returns {Promise<void>} Resolves when the login is successful.
     * @throws {UserFriendlyError} Throws a user-friendly error if the login fails.
     */
    const login = async (googleJwt) => {
        try {
            setLoading(true);
            setError(null);

            const url = `${oracleApiUrl}/auth/login`;
            const body = {
                token: googleJwt
            };
            const response = await fetch(url, {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const { tokens: returnedTokens } = await response.json();
            setTokens(returnedTokens);
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
        } finally {
            setLoading(false);
        }
    };

    /**
     * Logs out the current user by clearing session storage, hitting logout endpoint, and redirecting to the home page..
     *
     * @async
     * @function
     * @returns {Promise<void>} Resolves when the logout is successful.
     * @throws {UserFriendlyError} Throws a user-friendly error if the logout process fails
     */
    const logout = async () => {
        try {
            setLoading(true);
            setError(null);

            const url = `${oracleApiUrl}/auth/logout`;
            const body = {
                refreshToken: sessionStorage.getItem('refreshToken')
            };

            const response = await fetch(url, {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            sessionStorage.removeItem('accessToken');
            sessionStorage.removeItem('refreshToken');
            setTokens(null);

            window.location.href = '/';
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
        } finally {
            setLoading(false);
        }
    };

    return { tokens, loading, error, login, logout };
};

export default useAuthentication;
