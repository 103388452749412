import PropTypes from 'prop-types';
// eslint-disable-next-line import/extensions
import { MdArrowForwardIos, MdArrowBackIos } from 'react-icons/md';
import { black } from '../../styles/standard-colors.js';

/**
 * BackArrow component for displaying a back and forth button with onClick functionality.
 *
 * @component
 * @example
 * // Example usage with custom onClick:
 * <BackandForthArrows onClickForward={() => handleAddButtonClick()} onClickBackward={() => handleAddButtonClick()} size={30} />
 *
 * @param {Object} props - The component props.
 * @param {string} [props.color='#FF355E'] - The color of the button. Defaults to black.
 * @param {number} [props.size=34] - The size of the button icon.
 * @param {string} [props.onClickBackward] - The function to be executed when the back button is clicked.
 * @param {Function} [props.onClickForward] - The function to be executed when the forward button is clicked.
 *
 * @returns {JSX.Element} - The rendered BackandForthArrows component.
 */
const BackandForthArrows = ({
    color,
    size,
    onClickForward,
    onClickBackward
}) => {
    return (
        <div>
            <MdArrowBackIos
                size={size}
                color={color}
                style={{ cursor: 'pointer', marginRight: '2em' }}
                onClick={onClickBackward}
            />
            <MdArrowForwardIos
                size={size}
                color={color}
                style={{ cursor: 'pointer', marginRight: '2em' }}
                onClick={onClickForward}
            />
        </div>
    );
};

BackandForthArrows.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
    onClickForward: PropTypes.func,
    onClickBackward: PropTypes.func
};

BackandForthArrows.defaultProps = {
    color: black,
    size: 32
};

export default BackandForthArrows;
