import Select from 'react-select';
import PropTypes from 'prop-types';
import SearchBar from '../common/SearchBar.jsx';
import AddButton from '../common/AddButton.jsx';
import { blue, pinkRed } from '../../styles/standard-colors.js';
import ErrorMessage from '../common/ErrorMessage.jsx';
import UserFriendlyError from '../../utils/UserFriendlyError.js';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import {
    officeNoteTypeOptions,
    operatingSystemOptions
} from '../../config/dropdown-options.js';

/**
 * Renders search and filter options for office notes.
 * @component
 * @example
 * // Example usage:
 * <OfficeNotesSearchOptions
 *   setOfficeSysName={handleOfficeSysNameChange}
 *   setOfficeName={handleOfficeNameChange}
 *   setPhoneNumber={handlePhoneNumberChange}
 *   setDentalSoftware={handleDentalSoftwareChange}
 *   setEclaims={handleEclaimsChange}
 *   setXrays={handleXraysChange}
 *   setType={handleTypeChange}
 *   showMoreFilterOptions={showMoreOptions}
 *   setShowMoreFilterOptions={setShowMoreOptions}
 *   dentalSoftwareOptions={dentalSoftwareOptions}
 *   eclaimsOptions={eclaimsOptions}
 *   xrayOptions={xrayOptions}
 *   selectedDentalSoftware={selectedDentalSoftware}
 *   selectedEclaims={selectedEclaims}
 *   selectedXrays={selectedXrays}
 *   error={error}
 *   loading={isLoading}
 * />
 * @param {Object} props - The component props.
 * @param {Function} props.setOfficeSysName - Function to set the search string for office systematic name (required).
 * @param {Function} props.setOfficeName - Function to set the search string for office name (required).
 * @param {Function} props.setPhoneNumber - Function to set the search string for phone number (required).
 * @param {Function} props.setDentalSoftware - Function to set the selected dental software (required).
 * @param {Function} props.setEclaims - Function to set the selected eClaims (required).
 * @param {Function} props.setXrays - Function to set the selected X-Rays (required).
 * @param {Function} props.setComputerName - Function to set the search string for computer name (required).
 * @param {Function} props.setOs - Function to set the selected operating system (required).
 * @param {Function} props.setType - Function to set the type of offices (required).
 * @param {boolean} props.showMoreFilterOptions - Boolean indicating whether to show more filter options.
 * @param {Function} props.setShowMoreFilterOptions - Function to toggle the visibility of additional filter options.
 * @param {Array} props.dentalSoftwareOptions - Array of dental software options.
 * @param {Array} props.eclaimsOptions - Array of eClaims options.
 * @param {Array} props.xrayOptions - Array of X-Ray options.
 * @param {Array} props.operatingSystemOptions - Array of operating system options.
 * @param {string} props.selectedDentalSoftware - Selected dental software value.
 * @param {string} props.selectedEclaims - Selected eClaims value.
 * @param {string} props.selectedXrays - Selected X-Rays value.
 * @param {string} props.selectedOs - Selected operating system value.
 * @param {UserFriendlyError} [props.error] - User-friendly error object.
 * @param {boolean} props.loading - Boolean indicating whether data is being loaded.
 * @returns {JSX.Element} The rendered OfficeNotesSearchOptions component.
 */
const OfficeNotesSearchOptions = ({
    setOfficeSysName,
    setOfficeName,
    setPhoneNumber,
    setDentalSoftware,
    setEclaims,
    setXrays,
    setComputerName,
    setOs,
    setType,
    showMoreFilterOptions,
    setShowMoreFilterOptions,
    dentalSoftwareOptions,
    eclaimsOptions,
    xrayOptions,
    selectedDentalSoftware,
    selectedEclaims,
    selectedXrays,
    selectedOs,
    error,
    loading
}) => {
    return (
        <>
            <div className="notes-header-container-new">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: 'fit-content'
                    }}>
                    <h2 className="notes-title">Office Notes</h2>
                    <AddButton
                        color={pinkRed}
                        size={34}
                        navigateTo="/createOffice"
                    />
                </div>

                <div className="notes-search-container">
                    <div className="notes-select">
                        <Select
                            options={officeNoteTypeOptions}
                            placeholder="Active Offices"
                            onChange={(e) => setType(e.value)}
                        />
                    </div>
                    <div className="notes-search-create">
                        <SearchBar
                            placeholder="Search by systematic name"
                            onChange={setOfficeSysName}
                        />
                    </div>
                    <br />
                    {showMoreFilterOptions ? (
                        <>
                            <div className="notes-search-create">
                                <SearchBar
                                    placeholder="Search by office name"
                                    onChange={setOfficeName}
                                />
                            </div>
                            <br />
                            <div className="notes-search-create">
                                <SearchBar
                                    placeholder="Search by phone number"
                                    onChange={setPhoneNumber}
                                />
                            </div>
                            <br />
                            <div className="notes-search-create">
                                <SearchBar
                                    placeholder="Search by computer name"
                                    onChange={setComputerName}
                                />
                            </div>
                            <div className="notes-select">
                                <Select
                                    options={dentalSoftwareOptions}
                                    placeholder="Dental Software"
                                    onChange={(e) => setDentalSoftware(e.value)}
                                    value={dentalSoftwareOptions.find(
                                        (option) =>
                                            option.value ===
                                            selectedDentalSoftware
                                    )}
                                />
                            </div>
                            <div className="notes-select">
                                <Select
                                    options={eclaimsOptions}
                                    placeholder="eClaims"
                                    onChange={(e) => setEclaims(e.value)}
                                    value={eclaimsOptions.find(
                                        (option) =>
                                            option.value === selectedEclaims
                                    )}
                                />
                            </div>
                            <div className="notes-select">
                                <Select
                                    options={xrayOptions}
                                    placeholder="X-Rays"
                                    onChange={(e) => setXrays(e.value)}
                                    value={xrayOptions.find(
                                        (option) =>
                                            option.value === selectedXrays
                                    )}
                                />
                            </div>
                            <div className="notes-select">
                                <Select
                                    options={operatingSystemOptions}
                                    placeholder="Operating system"
                                    onChange={(e) => setOs(e.value)}
                                    value={operatingSystemOptions.find(
                                        (option) => option.value === selectedOs
                                    )}
                                />
                            </div>
                            <div
                                className="notes-search-create"
                                style={{
                                    cursor: 'pointer',
                                    color: blue,
                                    marginLeft: '30px'
                                }}
                                onClick={() => setShowMoreFilterOptions(false)}>
                                <p>Show less options</p>
                            </div>
                        </>
                    ) : (
                        <div
                            className="notes-search-create"
                            style={{
                                cursor: 'pointer',
                                color: blue,
                                marginLeft: '30px'
                            }}
                            onClick={() => setShowMoreFilterOptions(true)}>
                            <p>Show more options</p>
                        </div>
                    )}
                </div>

                {loading && <LoadingSpinner />}
                {error && <ErrorMessage message={error.message} />}
            </div>
        </>
    );
};

OfficeNotesSearchOptions.propTypes = {
    setOfficeSysName: PropTypes.func.isRequired,
    setOfficeName: PropTypes.func.isRequired,
    setPhoneNumber: PropTypes.func.isRequired,
    setDentalSoftware: PropTypes.func.isRequired,
    setEclaims: PropTypes.func.isRequired,
    setXrays: PropTypes.func.isRequired,
    setComputerName: PropTypes.func.isRequired,
    setOs: PropTypes.func.isRequired,
    setType: PropTypes.func.isRequired,
    showMoreFilterOptions: PropTypes.bool.isRequired,
    setShowMoreFilterOptions: PropTypes.func.isRequired,
    dentalSoftwareOptions: PropTypes.array.isRequired,
    eclaimsOptions: PropTypes.array.isRequired,
    xrayOptions: PropTypes.array.isRequired,
    selectedDentalSoftware: PropTypes.string.isRequired,
    selectedEclaims: PropTypes.string.isRequired,
    selectedXrays: PropTypes.string.isRequired,
    selectedOs: PropTypes.string.isRequired,
    error: PropTypes.instanceOf(UserFriendlyError),
    loading: PropTypes.bool.isRequired
};

export default OfficeNotesSearchOptions;
