import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setActiveTab } from '../../redux/slices/header-slice.js';
import useNewScs from '../../hooks/useNewScs.js';
import NewScCardsSearchOptions from '../../components/new-sc-vetting-cards/NewScCardsSearchOptions.jsx';
import NewScCardsList from '../../components/new-sc-vetting-cards/NewScCardsList.jsx';

/**
 * React component for the page displaying a list of New SC (Success Consultant) Cards.
 *
 * @component
 * @returns {JSX.Element} The JSX representation of the NewScCardsListPage component.
 */
const NewScCardsListPage = () => {
    const dispatch = useDispatch();

    const [type, setType] = useState('active');
    const [searchString, setSearchString] = useState('');
    const [software, setSoftware] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const { loading, error, newScs, searchNewScs, totalPages } = useNewScs();

    useEffect(() => {
        dispatch(setActiveTab('New SC'));

        return () => {
            dispatch(setActiveTab(null));
        };
    });

    useEffect(() => {
        const getNewScs = async () => {
            const limit = 40;
            await searchNewScs(
                searchString,
                type,
                software,
                currentPage,
                limit
            );
        };

        getNewScs();
    }, [searchString, type, software, currentPage]);

    return (
        <div className="notes-container">
            <NewScCardsSearchOptions
                setSearchString={setSearchString}
                setType={setType}
                setSoftware={setSoftware}
                error={error}
                loading={loading}
            />
            {newScs.length > 0 && (
                <NewScCardsList
                    newScs={newScs}
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                    customContainerStyles={{ marginTop: '275px' }}
                />
            )}
        </div>
    );
};

export default NewScCardsListPage;
