import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { selectActiveTab } from '../../redux/slices/header-slice.js';

/**
 * Renders a list of sub-links for navigation.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.links - An array of objects representing each sub-link.
 * @param {string} props.links[].to - The URL to navigate to when the sub-link is clicked.
 * @param {string} props.links[].title - The title of the sub-link.
 * @returns {JSX.Element} JSX element representing the list of sub-links.
 */
const SubLinks = ({ links }) => {
    const activeTab = useSelector(selectActiveTab);

    return (
        <div className="header-underneath-links-container">
            {links.map((link) => (
                <Link
                    to={link.to}
                    className={
                        link.title === activeTab
                            ? 'header-underneath-link-active'
                            : 'header-underneath-link-inactive'
                    }
                    key={uuidv4()}>
                    <p>{link.title}</p>
                </Link>
            ))}
        </div>
    );
};

SubLinks.propTypes = {
    links: PropTypes.array
};

export default SubLinks;
