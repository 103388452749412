// eslint-disable-next-line import/extensions
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import PropTypes from 'prop-types';

/**
 * ToggleVisibilityButton component for rendering an eye button to toggle visibility.
 *
 * @component
 * @example
 * // Example usage:
 * <ToggleVisibilityButton condition={isVisible} onClick={() => handleVisibilityToggle()} />
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.condition - The current visibility condition (true for visible, false for hidden) (required).
 * @param {Function} props.onClick - The function to be executed when the button is clicked (required).
 *
 * @returns {JSX.Element} - The rendered ToggleVisibilityButton component.
 */
const ToggleVisibilityButton = ({ condition, onClick }) => {
    return (
        <div
            style={{ cursor: 'pointer', marginLeft: '.5vw' }}
            onClick={onClick}>
            {condition ? <IoMdEyeOff /> : <IoMdEye />}
        </div>
    );
};

ToggleVisibilityButton.propTypes = {
    condition: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired
};

export default ToggleVisibilityButton;
