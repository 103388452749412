import PropTypes from 'prop-types';
// eslint-disable-next-line import/extensions
import { AiOutlinePlus } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { pinkRed } from '../../styles/standard-colors.js';

/**
 * AddButton component for displaying an "Add" (plus sign) button with optional navigation or onClick functionality.
 *
 * @component
 * @example
 * // Example usage with navigation:
 * <AddButton navigateTo="/add-item" color="blue" size={40} />
 *
 * @example
 * // Example usage with custom onClick:
 * <AddButton onClick={() => handleAddButtonClick()} size={30} />
 *
 * @param {Object} props - The component props.
 * @param {string} [props.color='#FF355E'] - The color of the button. Defaults to pinkRed.
 * @param {number} [props.size=34] - The size of the button icon.
 * @param {string} [props.navigateTo] - The URL to navigate to when the button is clicked.
 * @param {Function} [props.onClick] - The function to be executed when the button is clicked.
 *
 * @returns {JSX.Element} - The rendered AddButton component.
 */
const AddButton = ({ color, size, navigateTo, onClick }) => {
    const navigate = useNavigate();

    if (navigateTo) {
        return (
            <AiOutlinePlus
                size={size}
                color={color}
                style={{ cursor: 'pointer', marginLeft: '2em' }}
                onClick={() => navigate(navigateTo)}
            />
        );
    }

    if (onClick) {
        return (
            <AiOutlinePlus
                size={size}
                color={color}
                style={{ cursor: 'pointer', marginLeft: '2em' }}
                onClick={onClick}
            />
        );
    }

    return (
        <AiOutlinePlus
            size={size}
            color={color}
            style={{ marginLeft: '2em' }}
        />
    );
};

AddButton.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
    navigateTo: PropTypes.string,
    onClick: PropTypes.func
};

AddButton.defaultProps = {
    color: pinkRed,
    size: 34
};

export default AddButton;
