import {
    validInternationalPhoneNumber,
    validUsPhoneNumber
} from '../validation/custom-validations.js';

/**
 * Converts a phone number string from international format to national format.
 * The international format should be in the form +18011231234.
 * The national format will be in the form (801) 123-1234.
 *
 * @param {string} internationalNumber - The phone number string in international format.
 * @returns {string} The phone number string converted to national format.
 */
const convertPhoneNumberToNationalFormat = (internationalNumber) => {
    // Make sure its not already in that format
    if (validUsPhoneNumber.test(internationalNumber)) {
        return internationalNumber;
    }

    if (!internationalNumber) {
        return '';
    }

    // If it is not in international format, just return the string as is for now
    if (!validInternationalPhoneNumber.test(internationalNumber)) {
        return internationalNumber;
    }

    // Remove the leading '+' sign and the country code
    const digitsOnly = internationalNumber.replace(/^\+1/, '');

    // Extract the area code and the remaining digits
    const areaCode = digitsOnly.substring(0, 3);
    const remainingDigits = digitsOnly.substring(3);

    // Format the phone number as (XXX) XXX-XXXX
    return `(${areaCode}) ${remainingDigits.substring(
        0,
        3
    )}-${remainingDigits.substring(3)}`;
};

export default convertPhoneNumberToNationalFormat;
