/* eslint-disable no-nested-ternary */
import { useSelector } from 'react-redux';
import { selectNewScObj } from '../../redux/slices/new-sc-slice.js';
import convertPhoneNumberToNationalFormat from '../../utils/convert-phone-number-to-national.js';

/**
 * Renders a table displaying information about a single card object.
 *
 * @returns {JSX.Element} JSX element representing the card information table.
 */
const CardInfoTable = () => {
    const newScObj = useSelector(selectNewScObj);

    return (
        <table className="office-table">
            <tbody>
                <tr style={{ padding: '.25em' }}>
                    <td className="office-table-left-cell">First Name:</td>
                    <td className="office-table-right-cell">
                        {newScObj.firstName}
                    </td>
                </tr>
                <tr>
                    <td className="office-table-left-cell">Last Name:</td>
                    <td className="office-table-right-cell">
                        {newScObj.lastName}
                    </td>
                </tr>
                <tr>
                    <td className="office-table-left-cell">eAssist Email:</td>
                    <td className="office-table-right-cell">
                        {newScObj.eAssistEmail}
                    </td>
                </tr>
                <tr>
                    <td className="office-table-left-cell">Personal Email:</td>
                    <td className="office-table-right-cell">
                        {newScObj.personalEmail}
                    </td>
                </tr>
                <tr>
                    <td className="office-table-left-cell">Phone Number:</td>
                    <td className="office-table-right-cell">
                        {convertPhoneNumberToNationalFormat(newScObj.phone)}
                    </td>
                </tr>
                <tr>
                    <td className="office-table-left-cell">
                        New SC Workflow Type:
                    </td>
                    <td className="office-table-right-cell">
                        {newScObj.workflowType}
                    </td>
                </tr>
                <tr>
                    <td className="office-table-left-cell">Notes:</td>
                    <td
                        className="office-table-right-cell"
                        style={{ whiteSpace: 'pre-wrap' }}>
                        {newScObj.scNotes}
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default CardInfoTable;
