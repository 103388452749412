import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch } from 'react-redux';
import { setActiveTab } from '../../redux/slices/header-slice.js';
import useHipaa from '../../hooks/useHipaa.js';
import SubLinks from '../../components/header/SubLinks.jsx';
import { hipaaLinks } from '../../config/standard-links.js';
import BackArrow from '../../components/common/BackArrow.jsx';
import LoadingSpinner from '../../components/common/LoadingSpinner.jsx';
import ErrorMessage from '../../components/common/ErrorMessage.jsx';
import OfficeNotesList from '../../components/office-notes/OfficeNotesList.jsx';

/**
 * Component for rendering the HIPAA Corrections Page.
 * @returns {JSX.Element} HIPAA Corrections Page component.
 */
const HipaaCorrectionsPage = () => {
    const dispatch = useDispatch();

    const today = new Date();
    const thirtyDays = new Date(today);
    thirtyDays.setDate(thirtyDays.getDate() - 30);

    const [startDate, setStartDate] = useState(thirtyDays);
    const [endDate, setEndDate] = useState(today);
    const [currentPage, setCurrentPage] = useState(1);
    const [showOffices, setShowOffices] = useState(false);

    const {
        error,
        loading,
        offices,
        totalPages,
        specificComplianceCounts,
        getHipaaNewlyCompliantOffices,
        getHipaaSpecificComplianceCounts
    } = useHipaa();

    useEffect(() => {
        dispatch(setActiveTab('HIPAA'));

        return () => {
            dispatch(setActiveTab(null));
        };
    });

    useEffect(() => {
        const getNewlyCompliantOffices = async () => {
            await getHipaaNewlyCompliantOffices(
                startDate,
                endDate,
                currentPage,
                20,
                'officeSysName:asc'
            );
        };

        getNewlyCompliantOffices();
    }, [startDate, endDate, currentPage]);

    useEffect(() => {
        const getSpecificComplianceCounts = async () => {
            await getHipaaSpecificComplianceCounts(startDate, endDate);
        };

        getSpecificComplianceCounts();
    }, [startDate, endDate]);

    return (
        <div>
            <SubLinks links={hipaaLinks} />
            <div className="hipaa-container">
                <br />
                <br />
                <br />
                <div className="create-office-heading">
                    <BackArrow navigateTo={-1} />
                    <h2>Filter for recently corrected HIPAA concern</h2>
                </div>
                <div className="hippa-info-container">
                    <p
                        style={{
                            fontSize: '1em',
                            textAlign: 'left'
                        }}>
                        Pick a date range
                    </p>
                    <div className="hipaa-date-container">
                        <div className="hipaa-date-chunk">
                            <p>Start Date</p>
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                            />
                        </div>
                        <div className="hipaa-date-chunk">
                            <p>End Date</p>
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                            />
                        </div>
                    </div>
                    {loading && <LoadingSpinner />}
                    {error && <ErrorMessage message={error.message} />}
                    {specificComplianceCounts && (
                        <div style={{ marginTop: '2em', textAlign: 'left' }}>
                            <h3>Compliance Breakdown</h3>
                            <p>
                                - {specificComplianceCounts.passwordOfficeCount}{' '}
                                offices became compliant by updating their
                                passwords
                            </p>
                            <p>
                                - {specificComplianceCounts.avOfficeCount}{' '}
                                offices became compliant by getting anti-virus
                            </p>
                            <p>
                                - {specificComplianceCounts.osOfficeCount}{' '}
                                offices became compliant by updating their
                                operating system
                            </p>
                            <p>
                                - {specificComplianceCounts.vpnOfficeCount}{' '}
                                offices became compliant by getting an adendum
                                on file
                            </p>
                        </div>
                    )}
                </div>
                <p
                    onClick={() => setShowOffices(!showOffices)}
                    className="hippa-offices-display-btn">
                    {showOffices ? 'Hide Offices' : 'Show Offices'}
                </p>
                {showOffices && offices.length > 0 ? (
                    <OfficeNotesList
                        offices={offices}
                        totalPages={totalPages}
                        setCurrentPage={setCurrentPage}
                        customContainerStyles={{ marginTop: '10px' }}
                    />
                ) : null}
            </div>
        </div>
    );
};

export default HipaaCorrectionsPage;
