import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import CheckBox from 'rc-checkbox';
import DatePicker from 'react-datepicker';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Line,
    LineChart
} from 'recharts';
import { v4 as uuidv4 } from 'uuid';
import SubmitButton from '../common/SubmitButton.jsx';
import { runQueryFormFields } from '../../config/form-fields.js';
import ErrorMessage from '../common/ErrorMessage.jsx';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import useUsers from '../../hooks/useUsers.js';
import {
    itDeptOptions,
    timePeriodOptions
} from '../../config/dropdown-options.js';
import runQueryValidation from '../../validation/run-query-validation.js';
import StatTable from './StatTable.jsx';
import useReporting from '../../hooks/useReporting.js';
import { green, lightBlue, red } from '../../styles/standard-colors.js';
import chartColors from '../../styles/chart-colors.js';

/**
 * QueryForm component for running a query.
 *
 * @component
 * @returns {JSX.Element} - The rendered QueryForm component.
 */
const QueryForm = () => {
    const [userOptions, setUserOptions] = useState([]);
    const [ticketTagOptions, setTicketTagOptions] = useState([]);
    const [ticketTypeOptions, setTicketTypeOptions] = useState([]);
    const [showCustomTime, setShowCustomTime] = useState(false);
    const [deptQueryActive, setDeptQueryActive] = useState(false);

    const { error: userError, loading: userLoading, searchUsers } = useUsers();

    const {
        error: reportingError,
        loading: reportingLoading,
        table,
        tables,
        charts,
        runQuery,
        queryType,
        reset,
        getTicketTypeOptions,
        getTicketTagOptions
    } = useReporting();

    const formik = useFormik({
        initialValues: runQueryFormFields,
        validationSchema: runQueryValidation,
        onSubmit: async (values) => {
            const modifiedValues = { ...values };

            // Get rid of null fields or empty arrays
            Object.keys(modifiedValues).forEach((key) => {
                if (
                    modifiedValues[key] === null ||
                    (Array.isArray(modifiedValues[key]) &&
                        modifiedValues[key].length === 0)
                ) {
                    delete modifiedValues[key];
                }
            });

            await runQuery(modifiedValues);
        }
    });

    useEffect(() => {
        const getAllUsers = async () => {
            let returnedUsers = [];

            const helpDeskUsers = await searchUsers(
                '',
                1,
                '65c54c7f19a02a87988b158e' // Help desk
            );
            const phoneTechUsers = await searchUsers(
                '',
                1,
                '65c54c7f19a02a87988b1590' // Phone tech
            );
            const newScUsers = await searchUsers(
                '',
                1,
                '65c54c7f19a02a87988b158f' // New SC Install/Admin Tech
            );
            const ncltUsers = await searchUsers(
                '',
                1,
                '65c54c7f19a02a87988b1593' // Office Install
            );

            returnedUsers = returnedUsers.concat(
                helpDeskUsers,
                phoneTechUsers,
                newScUsers,
                ncltUsers
            );

            returnedUsers.sort((a, b) =>
                a.firstName.localeCompare(b.firstName)
            );

            returnedUsers = returnedUsers.map((u) => {
                // eslint-disable-next-line no-param-reassign
                u = {
                    value: u.id,
                    label: `${u.firstName} ${u.lastName}`
                };
                return u;
            });

            formik.setFieldValue('userId', returnedUsers[0].value);

            setUserOptions(returnedUsers);
        };

        const getTicketTags = async () => {
            let tags = await getTicketTagOptions();

            tags = tags.map((t) => {
                // eslint-disable-next-line no-param-reassign
                t = {
                    value: t,
                    label: t
                };
                return t;
            });

            setTicketTagOptions(tags);
        };

        const getTicketTypes = async () => {
            let types = await getTicketTypeOptions();

            types = types.filter((t) => t !== '' && t !== null);

            types = types.map((t) => {
                // eslint-disable-next-line no-param-reassign
                t = {
                    value: t,
                    label: t
                };
                return t;
            });

            setTicketTypeOptions(types);
        };

        getAllUsers();
        getTicketTags();
        getTicketTypes();
    }, []);

    const handleClickLeft = () => {
        if (deptQueryActive) {
            setDeptQueryActive(false);
            reset();
            formik.setFieldValue('userId', userOptions[0].value);
            formik.setFieldValue('itSegment', '');
            formik.setFieldValue('ticketTypes', []);
            formik.setFieldValue('ticketTags', []);
        }
    };

    const handleClickRight = () => {
        if (!deptQueryActive) {
            setDeptQueryActive(true);
            reset();
            formik.setFieldValue('userId', '');
            formik.setFieldValue('itSegment', itDeptOptions[0].value);
            formik.setFieldValue('ticketTypes', []);
            formik.setFieldValue('ticketTags', []);
        }
    };

    useEffect(() => {
        if (showCustomTime) {
            formik.setFieldValue('timePeriod', 'custom');
        } else {
            formik.setFieldValue('timePeriod', '');
        }
    }, [showCustomTime]);

    return (
        <>
            {/* Form and table results */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    height: '50vh',
                    marginBottom: '5vh'
                }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '50%'
                    }}>
                    {/* Toggle button for dept vs individual query */}
                    <div
                        style={{
                            display: 'flex',
                            width: '50%',
                            height: '40px',
                            marginBottom: '2em',
                            marginLeft: '30px',
                            marginTop: '2em'
                        }}>
                        <div
                            className={
                                deptQueryActive
                                    ? 'reporting-query-selector-inactive'
                                    : 'reporting-query-selector-active'
                            }
                            onClick={handleClickLeft}
                            id="reporting-query-selector-left">
                            <p
                                style={{
                                    color: !deptQueryActive
                                        ? 'white'
                                        : '#2361DC'
                                }}>
                                Individual
                            </p>
                        </div>
                        <div
                            className={
                                deptQueryActive
                                    ? 'reporting-query-selector-active'
                                    : 'reporting-query-selector-inactive'
                            }
                            id="reporting-query-selector-right"
                            onClick={handleClickRight}>
                            <p
                                style={{
                                    color: deptQueryActive ? 'white' : '#2361DC'
                                }}>
                                Department
                            </p>
                        </div>
                    </div>

                    {/* Individual Query Form */}
                    {!deptQueryActive && (
                        <form
                            onSubmit={formik.handleSubmit}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                marginLeft: '30px',
                                marginTop: '2em'
                            }}>
                            <div
                                style={{
                                    textAlign: 'left'
                                }}>
                                <label htmlFor="userId">User</label>
                                <br />
                                <br />
                                <div style={{ width: '60%' }}>
                                    <Select
                                        id="userId"
                                        name="userId"
                                        options={userOptions}
                                        value={userOptions.find(
                                            (option) =>
                                                option.value ===
                                                formik.values.userId
                                        )}
                                        onChange={(selectedOption) =>
                                            formik.setFieldValue(
                                                'userId',
                                                selectedOption
                                                    ? selectedOption.value
                                                    : null
                                            )
                                        }
                                    />
                                </div>
                                {userLoading && <LoadingSpinner />}
                                {userError && (
                                    <ErrorMessage message={userError.message} />
                                )}
                                {formik.touched.userId &&
                                formik.errors.userId ? (
                                    <ErrorMessage
                                        message={formik.errors.userId}
                                    />
                                ) : null}
                                <br />

                                <label htmlFor="ticketTypes">
                                    Ticket Types
                                </label>
                                <br />
                                <br />
                                <div style={{ width: '60%' }}>
                                    <Select
                                        id="ticketTypes"
                                        name="ticketTypes"
                                        options={ticketTypeOptions}
                                        value={ticketTypeOptions.filter(
                                            (option) =>
                                                formik.values.ticketTypes.includes(
                                                    option.value
                                                )
                                        )}
                                        onChange={(selectedOptions) =>
                                            formik.setFieldValue(
                                                'ticketTypes',
                                                selectedOptions
                                                    ? selectedOptions.map(
                                                          (option) =>
                                                              option.value
                                                      )
                                                    : []
                                            )
                                        }
                                        isMulti
                                    />
                                </div>
                                {userLoading && <LoadingSpinner />}
                                {userError && (
                                    <ErrorMessage message={userError.message} />
                                )}
                                {formik.touched.ticketTypes &&
                                formik.errors.ticketTypes ? (
                                    <ErrorMessage
                                        message={formik.errors.ticketTypes}
                                    />
                                ) : null}
                                <br />

                                <label htmlFor="ticketTags">Ticket Tags</label>
                                <br />
                                <br />
                                <div style={{ width: '60%' }}>
                                    <Select
                                        id="ticketTags"
                                        name="ticketTags"
                                        options={ticketTagOptions}
                                        value={ticketTagOptions.filter(
                                            (option) =>
                                                formik.values.ticketTags.includes(
                                                    option.value
                                                )
                                        )}
                                        onChange={(selectedOptions) =>
                                            formik.setFieldValue(
                                                'ticketTags',
                                                selectedOptions
                                                    ? selectedOptions.map(
                                                          (option) =>
                                                              option.value
                                                      )
                                                    : []
                                            )
                                        }
                                        isMulti
                                    />
                                </div>
                                {userLoading && <LoadingSpinner />}
                                {userError && (
                                    <ErrorMessage message={userError.message} />
                                )}
                                {formik.touched.ticketTags &&
                                formik.errors.ticketTags ? (
                                    <ErrorMessage
                                        message={formik.errors.ticketTags}
                                    />
                                ) : null}
                                <br />

                                <label htmlFor="timePeriod">Time Period</label>
                                <br />
                                <br />
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '80%',
                                        justifyContent: 'space-between'
                                    }}>
                                    {showCustomTime ? (
                                        <div className="hipaa-date-container">
                                            <div
                                                className="hipaa-date-chunk"
                                                style={{ zIndex: 0 }}>
                                                <p>Start Date</p>
                                                <DatePicker
                                                    onChange={(date) =>
                                                        formik.setFieldValue(
                                                            'customStartDate',
                                                            date
                                                        )
                                                    }
                                                    selected={
                                                        formik.values
                                                            .customStartDate
                                                    }
                                                    maxDate={new Date()}
                                                    minDate={
                                                        new Date('2015-10-27')
                                                    }
                                                    isClearable
                                                    showYearDropdown
                                                    scrollableMonthYearDropdown
                                                />
                                            </div>
                                            <div
                                                className="hipaa-date-chunk"
                                                style={{ zIndex: 0 }}>
                                                <p>End Date</p>
                                                <DatePicker
                                                    onChange={(date) =>
                                                        formik.setFieldValue(
                                                            'customEndDate',
                                                            date
                                                        )
                                                    }
                                                    selected={
                                                        formik.values
                                                            .customEndDate
                                                    }
                                                    maxDate={new Date()}
                                                    minDate={
                                                        new Date('2015-10-27')
                                                    }
                                                    isClearable
                                                    showYearDropdown
                                                    scrollableMonthYearDropdown
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div style={{ width: '75%' }}>
                                            <Select
                                                id="timePeriod"
                                                name="timePeriod"
                                                options={timePeriodOptions}
                                                value={timePeriodOptions.find(
                                                    (option) =>
                                                        option.value ===
                                                        formik.values.timePeriod
                                                )}
                                                onChange={(selectedOption) =>
                                                    formik.setFieldValue(
                                                        'timePeriod',
                                                        selectedOption
                                                            ? selectedOption.value
                                                            : null
                                                    )
                                                }
                                            />
                                        </div>
                                    )}
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                        <CheckBox
                                            checked={showCustomTime}
                                            onChange={() =>
                                                setShowCustomTime(
                                                    !showCustomTime
                                                )
                                            }
                                        />
                                        <p>Use Custom</p>
                                    </div>
                                </div>
                                {formik.touched.timePeriod &&
                                formik.errors.timePeriod ? (
                                    <div
                                        style={{
                                            textAlign: 'center'
                                        }}>
                                        <ErrorMessage
                                            message={formik.errors.timePeriod}
                                        />
                                    </div>
                                ) : null}
                            </div>
                            <br />
                            <br />
                            {Object.keys(formik.errors).length !== 0 &&
                            Object.keys(formik.touched).length !== 0 ? (
                                <ErrorMessage message="There are form errors above, please fix." />
                            ) : null}

                            <SubmitButton label="Run query" />
                        </form>
                    )}

                    {/* Dept Query Form */}
                    {deptQueryActive && (
                        <form
                            onSubmit={formik.handleSubmit}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                marginLeft: '30px',
                                marginTop: '2em'
                            }}>
                            <div
                                style={{
                                    textAlign: 'left'
                                }}>
                                <label htmlFor="itSegment">Department</label>
                                <br />
                                <br />
                                <div style={{ width: '60%' }}>
                                    <Select
                                        id="itSegment"
                                        name="itSegment"
                                        options={itDeptOptions}
                                        value={itDeptOptions.find(
                                            (option) =>
                                                option.value ===
                                                formik.values.itSegment
                                        )}
                                        onChange={(selectedOption) =>
                                            formik.setFieldValue(
                                                'itSegment',
                                                selectedOption
                                                    ? selectedOption.value
                                                    : null
                                            )
                                        }
                                    />
                                </div>
                                {formik.touched.itSegment &&
                                formik.errors.itSegment ? (
                                    <ErrorMessage
                                        message={formik.errors.itSegment}
                                    />
                                ) : null}
                                <br />

                                <label htmlFor="ticketTypes">
                                    Ticket Types
                                </label>
                                <br />
                                <br />
                                <div style={{ width: '60%' }}>
                                    <Select
                                        id="ticketTypes"
                                        name="ticketTypes"
                                        options={ticketTypeOptions}
                                        value={ticketTypeOptions.filter(
                                            (option) =>
                                                formik.values.ticketTypes.includes(
                                                    option.value
                                                )
                                        )}
                                        onChange={(selectedOptions) =>
                                            formik.setFieldValue(
                                                'ticketTypes',
                                                selectedOptions
                                                    ? selectedOptions.map(
                                                          (option) =>
                                                              option.value
                                                      )
                                                    : []
                                            )
                                        }
                                        isMulti
                                    />
                                </div>
                                {userLoading && <LoadingSpinner />}
                                {userError && (
                                    <ErrorMessage message={userError.message} />
                                )}
                                {formik.touched.ticketTypes &&
                                formik.errors.ticketTypes ? (
                                    <ErrorMessage
                                        message={formik.errors.ticketTypes}
                                    />
                                ) : null}
                                <br />

                                <label htmlFor="ticketTags">Ticket Tags</label>
                                <br />
                                <br />
                                <div style={{ width: '60%' }}>
                                    <Select
                                        id="ticketTags"
                                        name="ticketTags"
                                        options={ticketTagOptions}
                                        value={ticketTagOptions.filter(
                                            (option) =>
                                                formik.values.ticketTags.includes(
                                                    option.value
                                                )
                                        )}
                                        onChange={(selectedOptions) =>
                                            formik.setFieldValue(
                                                'ticketTags',
                                                selectedOptions
                                                    ? selectedOptions.map(
                                                          (option) =>
                                                              option.value
                                                      )
                                                    : []
                                            )
                                        }
                                        isMulti
                                    />
                                </div>
                                {userLoading && <LoadingSpinner />}
                                {userError && (
                                    <ErrorMessage message={userError.message} />
                                )}
                                {formik.touched.ticketTags &&
                                formik.errors.ticketTags ? (
                                    <ErrorMessage
                                        message={formik.errors.ticketTags}
                                    />
                                ) : null}
                                <br />

                                <label htmlFor="timePeriod">Time Period</label>
                                <br />
                                <br />
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '80%',
                                        justifyContent: 'space-between'
                                    }}>
                                    {showCustomTime ? (
                                        <div className="hipaa-date-container">
                                            <div
                                                className="hipaa-date-chunk"
                                                style={{ zIndex: 0 }}>
                                                <p>Start Date</p>
                                                <DatePicker
                                                    onChange={(date) =>
                                                        formik.setFieldValue(
                                                            'customStartDate',
                                                            date
                                                        )
                                                    }
                                                    selected={
                                                        formik.values
                                                            .customStartDate
                                                    }
                                                    maxDate={new Date()}
                                                    minDate={
                                                        new Date('2015-10-27')
                                                    }
                                                    isClearable
                                                    showYearDropdown
                                                    scrollableMonthYearDropdown
                                                />
                                            </div>
                                            <div
                                                className="hipaa-date-chunk"
                                                style={{ zIndex: 0 }}>
                                                <p>End Date</p>
                                                <DatePicker
                                                    onChange={(date) =>
                                                        formik.setFieldValue(
                                                            'customEndDate',
                                                            date
                                                        )
                                                    }
                                                    selected={
                                                        formik.values
                                                            .customEndDate
                                                    }
                                                    maxDate={new Date()}
                                                    minDate={
                                                        new Date('2015-10-27')
                                                    }
                                                    isClearable
                                                    showYearDropdown
                                                    scrollableMonthYearDropdown
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div style={{ width: '75%' }}>
                                            <Select
                                                id="timePeriod"
                                                name="timePeriod"
                                                options={timePeriodOptions}
                                                value={timePeriodOptions.find(
                                                    (option) =>
                                                        option.value ===
                                                        formik.values.timePeriod
                                                )}
                                                onChange={(selectedOption) =>
                                                    formik.setFieldValue(
                                                        'timePeriod',
                                                        selectedOption
                                                            ? selectedOption.value
                                                            : null
                                                    )
                                                }
                                            />
                                        </div>
                                    )}
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                        <CheckBox
                                            checked={showCustomTime}
                                            onChange={() =>
                                                setShowCustomTime(
                                                    !showCustomTime
                                                )
                                            }
                                        />
                                        <p>Use Custom</p>
                                    </div>
                                </div>
                                {formik.touched.timePeriod &&
                                formik.errors.timePeriod ? (
                                    <div
                                        style={{
                                            textAlign: 'center'
                                        }}>
                                        <ErrorMessage
                                            message={formik.errors.timePeriod}
                                        />
                                    </div>
                                ) : null}
                            </div>
                            <br />
                            <br />
                            {Object.keys(formik.errors).length !== 0 &&
                            Object.keys(formik.touched).length !== 0 ? (
                                <ErrorMessage message="There are form errors above, please fix." />
                            ) : null}

                            <SubmitButton label="Run query" />
                        </form>
                    )}
                </div>

                <br />

                {/* Table results */}
                {queryType !== 'nclt' && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                            alignItems: 'center'
                        }}>
                        {reportingLoading && <LoadingSpinner />}
                        {reportingError && (
                            <ErrorMessage message={reportingError.message} />
                        )}
                        {table && (
                            <>
                                <StatTable
                                    title={table.title}
                                    rows={table.rows}
                                    columnHeaders={table.headers}
                                />
                                <br />
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '100%',
                                        justifyContent: 'space-evenly'
                                    }}>
                                    <button
                                        className="newsc-btn"
                                        style={{ marginBottom: '5em' }}
                                        onClick={reset}>
                                        Reset
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                )}
                {queryType === 'nclt' && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                            alignItems: 'center'
                        }}>
                        {reportingLoading && <LoadingSpinner />}
                        {reportingError && (
                            <ErrorMessage message={reportingError.message} />
                        )}
                        {tables && tables.length > 0 && (
                            <>
                                {tables.map((t) => (
                                    <StatTable
                                        title={t.title}
                                        key={uuidv4()}
                                        rows={t.rows}
                                        columnHeaders={t.headers}
                                    />
                                ))}
                                <br />
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '100%',
                                        justifyContent: 'space-evenly'
                                    }}>
                                    <button
                                        className="newsc-btn"
                                        style={{ marginBottom: '5em' }}
                                        onClick={reset}>
                                        Reset
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>

            {/* Chart results */}
            {queryType === 'hd' && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        height: '50vh',
                        alignItems: 'center'
                    }}>
                    {' '}
                    {charts && charts.length > 0 && (
                        <>
                            <h3 style={{ fontStyle: 'italic' }}>
                                Tickets Resolved
                            </h3>
                            <ResponsiveContainer width="95%" height={450}>
                                <BarChart
                                    width={500}
                                    height={300}
                                    data={charts}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5
                                    }}>
                                    <CartesianGrid />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar
                                        dataKey="# of Tickets Resolved"
                                        fill="#155180"
                                    />
                                </BarChart>
                            </ResponsiveContainer>
                            <br />
                            <br />
                            <br />
                            <br />
                            <h3 style={{ fontStyle: 'italic' }}>
                                Tickets Not Resolved Within Brand Promise
                            </h3>
                            <ResponsiveContainer width="95%" height={450}>
                                <LineChart
                                    width={500}
                                    height={300}
                                    data={charts}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5
                                    }}>
                                    <CartesianGrid />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line
                                        type="linear"
                                        dataKey="% of Tickets Not Resolved Within Brand Promise"
                                        fill="#155180"
                                        stroke="#155180"
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                            <br />
                            <br />
                            <br />
                            <br />
                            <h3 style={{ fontStyle: 'italic' }}>
                                Average Survey Score
                            </h3>
                            <ResponsiveContainer width="95%" height={450}>
                                <LineChart
                                    width={500}
                                    height={300}
                                    data={charts}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5
                                    }}>
                                    <CartesianGrid />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line
                                        type="linear"
                                        dataKey="Avg Survey Score"
                                        fill="#155180"
                                        stroke="#155180"
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </>
                    )}
                </div>
            )}

            {queryType === 'newsc/admin' && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        height: '50vh',
                        alignItems: 'center'
                    }}>
                    {' '}
                    {charts && charts.length > 0 && (
                        <>
                            <h3 style={{ fontStyle: 'italic' }}>
                                Tickets/Tasks Resolved
                            </h3>
                            <ResponsiveContainer width="95%" height={450}>
                                <BarChart
                                    width={500}
                                    height={300}
                                    data={charts}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5
                                    }}>
                                    <CartesianGrid />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar
                                        dataKey="# of Tickets Resolved"
                                        fill={lightBlue}
                                    />
                                    <Bar
                                        dataKey="# of Admin Tasks Completed"
                                        fill={red}
                                    />
                                    <Bar dataKey="# of Installs" fill={green} />
                                </BarChart>
                            </ResponsiveContainer>
                            <br />
                            <br />
                            <br />
                            <br />
                            <h3 style={{ fontStyle: 'italic' }}>
                                Tickets/Tasks Not Resolved Within Brand Promise
                            </h3>
                            <ResponsiveContainer width="95%" height={450}>
                                <LineChart
                                    width={500}
                                    height={300}
                                    data={charts}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5
                                    }}>
                                    <CartesianGrid />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line
                                        type="linear"
                                        dataKey="% of Tasks Outside SLA"
                                        fill={lightBlue}
                                        stroke={lightBlue}
                                    />
                                    <Line
                                        type="linear"
                                        dataKey="% of OACs Completed Within 24 hrs or less"
                                        fill={red}
                                        stroke={red}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                            <br />
                            <br />
                            <br />
                            <br />
                            <h3 style={{ fontStyle: 'italic' }}>
                                Average Completion Time
                            </h3>
                            <ResponsiveContainer width="95%" height={450}>
                                <LineChart
                                    width={500}
                                    height={300}
                                    data={charts}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5
                                    }}>
                                    <CartesianGrid />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line
                                        type="linear"
                                        dataKey="Completion Time Avg (Days)"
                                        fill={lightBlue}
                                        stroke={lightBlue}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </>
                    )}
                </div>
            )}

            {queryType === 'nclt' && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        height: '50vh',
                        alignItems: 'center'
                    }}>
                    {' '}
                    {charts && charts.length > 0 && (
                        <>
                            <h3
                                key={`days-in-it-chart`}
                                style={{ fontStyle: 'italic' }}>
                                Avg Days in IT
                            </h3>
                            <ResponsiveContainer width="95%" height={450}>
                                <LineChart
                                    width={500}
                                    height={300}
                                    data={charts}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5
                                    }}>
                                    <CartesianGrid />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line
                                        dataKey="Total Avg Days in IT"
                                        fill="#155180"
                                        stroke="#155180"
                                    />
                                    {tables[1].rows.map((service, index) => (
                                        <Line
                                            key={uuidv4()}
                                            dataKey={`${service[0].data} Avg Days in IT`}
                                            fill={chartColors[index]}
                                            stroke={chartColors[index]}
                                        />
                                    ))}
                                </LineChart>
                            </ResponsiveContainer>
                            <br />
                            <br />
                            <br />
                            <br />
                            <h3
                                key={`5days-chart`}
                                style={{ fontStyle: 'italic' }}>
                                % Completed Outside 5 Days
                            </h3>
                            <ResponsiveContainer width="95%" height={450}>
                                <LineChart
                                    width={500}
                                    height={300}
                                    data={charts}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5
                                    }}>
                                    <CartesianGrid />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line
                                        dataKey="Total % Completed Outside 5 Days"
                                        fill="#155180"
                                        stroke="#155180"
                                    />
                                    {tables[1].rows.map((service, index) => (
                                        <Line
                                            key={uuidv4()}
                                            dataKey={`${service[0].data} % Completed Outside 5 Days`}
                                            fill={chartColors[index]}
                                            stroke={chartColors[index]}
                                        />
                                    ))}
                                </LineChart>
                            </ResponsiveContainer>
                            <br />
                            <br />
                            <br />
                            <br />
                            <h3
                                key={`launches-chart`}
                                style={{ fontStyle: 'italic' }}>
                                Launches
                            </h3>
                            <ResponsiveContainer width="95%" height={450}>
                                <BarChart
                                    width={500}
                                    height={300}
                                    data={charts}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5
                                    }}>
                                    <CartesianGrid />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar
                                        dataKey="Total Launches"
                                        fill="#155180"
                                    />
                                    {tables[1].rows.map((service, index) => (
                                        <Bar
                                            key={uuidv4()}
                                            dataKey={`${service[0].data} Launches`}
                                            fill={chartColors[index]}
                                        />
                                    ))}
                                </BarChart>
                            </ResponsiveContainer>
                            <br />
                            <br />
                            <br />
                            <br />
                            <h3
                                key={`avg-launch-time-chart`}
                                style={{ fontStyle: 'italic' }}>
                                Avg Launch Time (Days)
                            </h3>
                            <ResponsiveContainer width="95%" height={450}>
                                <LineChart
                                    width={500}
                                    height={300}
                                    data={charts}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5
                                    }}>
                                    <CartesianGrid />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line
                                        dataKey="Total Avg Launch Time (Days)"
                                        fill="#155180"
                                        stroke="#155180"
                                    />
                                    {tables[1].rows.map((service, index) => (
                                        <Line
                                            key={uuidv4()}
                                            dataKey={`${service[0].data} Avg Launch Time (Days)`}
                                            fill={chartColors[index]}
                                            stroke={chartColors[index]}
                                        />
                                    ))}
                                </LineChart>
                            </ResponsiveContainer>
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default QueryForm;
