import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectNewScObj } from '../../redux/slices/new-sc-slice.js';

/**
 * Dropdown component for managing settings of the SC card.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.showEdit - Boolean indicating whether to show the edit option.
 * @param {Function} props.setShowEdit - Function to toggle the edit option.
 * @param {boolean} props.showChangeActiveStateModal - Boolean indicating whether to show the change active state modal.
 * @param {Function} props.setShowChangeActiveStateModal - Function to toggle the change active state modal.
 * @returns {JSX.Element} JSX element representing the Settings Dropdown.
 */
const SettingsDropdown = ({
    showEdit,
    setShowEdit,
    showChangeActiveStateModal,
    setShowChangeActiveStateModal
}) => {
    const newScObj = useSelector(selectNewScObj);

    return (
        <>
            <div className="office-settings-dropdown-container">
                <div className="office-settings-dropdown">
                    <p
                        className="office-settings-dropdown-item"
                        onClick={() => setShowEdit(!showEdit)}>
                        {showEdit ? 'Cancel Edit' : 'Edit SC Info'}
                    </p>
                    <p
                        className="office-settings-dropdown-item"
                        onClick={() =>
                            setShowChangeActiveStateModal(
                                !showChangeActiveStateModal
                            )
                        }>
                        {newScObj.isActive ? 'Retire SC' : 'Re-activate SC'}
                    </p>
                </div>
            </div>
        </>
    );
};

SettingsDropdown.propTypes = {
    showEdit: PropTypes.bool.isRequired,
    setShowEdit: PropTypes.func.isRequired,
    showChangeActiveStateModal: PropTypes.bool.isRequired,
    setShowChangeActiveStateModal: PropTypes.func.isRequired
};

export default SettingsDropdown;
