/**
 * Capitalizes the first letter of each word in a given string.
 *
 * @param {string|number} input - The input string to capitalize.
 * @returns {string} The input string with the first letter of each word capitalized.
 */
const capitalize = (input) => {
    // Make sure input is a string
    // eslint-disable-next-line no-param-reassign
    input = input.toString();

    // Split the input string into words
    const words = input.split(/\s+/);

    // Capitalize the first letter of each word
    const capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );

    // Join the capitalized words back into a single string
    return capitalizedWords.join(' ');
};

export default capitalize;
