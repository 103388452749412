/**
 * Analyzes the response from an API call and generates a user-friendly error message based on the status code.
 *
 * @param {Object} response - The response object from the API call.
 * @param {number} response.status - The HTTP status code of the response.
 * @returns {string} A user-friendly error message based on the response status code.
 * @example
 * // Example usage:
 * const response = {
 *     status: 404,
 *     // Other properties in the response object
 * };
 * const errorMessage = await analyzeApiResponse(response);
 * console.log(errorMessage); // "Resource not found"
 */
const analyzeApiResponse = async (response) => {
    let message =
        'A system error occured. Please try again and if unsuccessful, report the bug to the Autobots team.';

    switch (response.status) {
        case 400: {
            message =
                'Bad request. There was a problem with the fields passed.';
            break;
        }
        case 401: {
            message = 'Unauthorized. Please log back in.';
            break;
        }
        case 403: {
            message = 'You do not have permission to access this resource';
            break;
        }
        case 404: {
            message = 'Resource not found';
            break;
        }
        case 422: {
            const responseData = await response.json();
            if (responseData.message.includes('opted out')) {
                message =
                    'Office has opted out of receiving HIPAA related emails.';
            } else if (responseData.message.includes('no longer active')) {
                message =
                    'Office is no longer active and cannot receive emails.';
            } else if (
                responseData.message.includes('outdated operating systems')
            ) {
                message =
                    'Office does not have any PCs with outdated operating systems.';
            }
            break;
        }
        default:
    }

    return message;
};

export default analyzeApiResponse;
