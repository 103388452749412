import { useState } from 'react';
import convertError from '../utils/error-converter.js';
import UserFriendlyError from '../utils/UserFriendlyError.js';
import callApi from '../utils/call-api.js';
import analyzeApiResponse from '../utils/analyze-api-response.js';

/**
 * Custom React hook for managing user-related data.
 *
 * @returns {Object} An object containing state variables and functions related to user data.
 * @property {Array<Object>} offices - The array of returned offices (empty if none returned).
 * @property {number} totalPages - The total number of pages for pagination.
 * @property {Object|null} office - The returned office (null if none returned).
 * @property {boolean} loading - A boolean indicating whether data is currently being loaded.
 * @property {Object|null} error - An error object containing details about any encountered error.
 * @property {Function} searchOffices - A function to search for offices based on criteria.
 * @property {Function} createOffice - A function to create a new office.
 * @property {Function} updateOffice - A function to update an office.
 * @property {Function} getOffice - A function to get a specific office by id.
 */
const useUsers = () => {
    const [users, setUsers] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [user, setUser] = useState(null);
    const [isDeleteSuccess, setIsDeleteSuccess] = useState(false);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    /**
     * Searches for users based on specified criteria.
     *
     * @async
     * @function
     * @param {string} [email=''] - The search email for filtering users.
     * @param {number} [currentPage=1] - The current page for pagination.
     * @param {number} [limit=40] - The number of offices to retrieve per page.
     * @returns {Promise<Array>} A promise that resolves with a list of returned users.
     */
    const searchUsers = async (
        email = '',
        currentPage = 1,
        role = '',
        limit = 100,
        sortBy = 'email:asc'
    ) => {
        try {
            setLoading(true);
            setError(null);

            let path = `/users?&limit=${limit}&page=${currentPage}&sortBy=${sortBy}`;
            if (email) {
                path += `&email=${email}`;
            }
            if (role) {
                path += `&role=${role}`;
            }

            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const { results: returnedUsers, totalPages: returnedTotalPages } =
                await response.json();
            setUsers(returnedUsers);
            setTotalPages(returnedTotalPages);
            return returnedUsers;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
        } finally {
            setLoading(false);
        }
    };

    /**
     * Gets all users.
     *
     * @async
     * @function
     * @returns {Promise<Array>} A promise that resolves with a list of returned users.
     */
    const getAllUsers = async () => {
        try {
            setLoading(true);
            setError(null);

            const path = `/users?paginate=${false}`;

            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const returnedUsers = await response.json();
            setUsers(returnedUsers);
            return returnedUsers;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
        } finally {
            setLoading(false);
        }
    };

    /**
     * Creates a new user.
     *
     * @async
     * @function
     * @param {Object} newUserFields - The fields for creating the new user, passed from formik. Reference config/form-fields.js or validation/create-user-validations.js for details on properties.
     * @returns {Promise<User>} A promise that resolves when the user creation is complete with the new user object.
     */
    const createUser = async (newUserFields) => {
        try {
            setLoading(true);
            setError(null);

            const returnedUsers = await searchUsers(newUserFields.email);
            if (returnedUsers.length > 0) {
                throw new UserFriendlyError('User email already exists');
            }

            if (newUserFields.helpDeskTeam === '') {
                // eslint-disable-next-line no-param-reassign
                delete newUserFields.helpDeskTeam;
            }

            const path = `/users`;
            const response = await callApi(path, 'post', newUserFields);

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const newUser = await response.json();
            setUser(newUser);
            return newUser;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Updates a user.
     *
     * @async
     * @function
     * @param {String} userId
     * @param {Object} updateUserFields - The fields for updating the user, passed from formik. Reference config/form-fields.js or validation/create-user-validations.js for details on properties.
     * @param {Object} originalUser - The original user before changes for reference.
     * @returns {Promise<User>} A promise that resolves when the user update is complete with the user object.
     */
    const updateUser = async (userId, updateUserFields, originalUser) => {
        try {
            setLoading(true);
            setError(null);

            const updateUserFieldsModified = { ...updateUserFields };

            // If the email has changed, then check if one already exsits
            if (
                updateUserFields.email &&
                updateUserFields.email !== originalUser.email
            ) {
                const returnedUsers = await searchUsers(updateUserFields.email);
                if (returnedUsers.length > 0) {
                    throw new UserFriendlyError('User email already exists');
                }
            }
            // email has not changed, remove it from fields to pass to backend
            else {
                delete updateUserFieldsModified.officeSysName;
            }

            // Remove any more fields that are not allowed
            delete updateUserFieldsModified.id;
            delete updateUserFieldsModified.picture;

            const path = `/users/${userId}`;
            const response = await callApi(
                path,
                'patch',
                updateUserFieldsModified
            );

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const updatedUser = await response.json();
            setUser(updatedUser);
            return updatedUser;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Gets a single user and it's info by ID.
     *
     * @async
     * @function
     * @param {string} userId
     * @returns {Promise<User>} A promise that resolves with the returned user.
     */
    const getUser = async (userId) => {
        try {
            setLoading(true);
            setError(null);

            const path = `/users/${userId}`;
            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const returnedUser = await response.json();
            setUser(returnedUser);
            return returnedUser;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Deletes a single user and it's info by ID.
     *
     * @async
     * @function
     * @param {string} userId
     * @returns {Promise<Void>} A promise that resolves.
     */
    const deleteUser = async (userId) => {
        try {
            setLoading(true);
            setError(null);

            const path = `/users/${userId}`;
            const response = await callApi(path, 'delete');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            setIsDeleteSuccess(true);
            return true;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return false;
        } finally {
            setLoading(false);
        }
    };

    return {
        users,
        totalPages,
        user,
        loading,
        error,
        searchUsers,
        createUser,
        updateUser,
        getUser,
        deleteUser,
        isDeleteSuccess,
        getAllUsers
    };
};

export default useUsers;
