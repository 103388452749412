import { useState } from 'react';
import convertError from '../utils/error-converter.js';
import UserFriendlyError from '../utils/UserFriendlyError.js';
import callApi from '../utils/call-api.js';
import analyzeApiResponse from '../utils/analyze-api-response.js';

/**
 * Custom React hook for managing admin automation-related data.
 *
 * @returns {Object} An object containing state variables and functions related to admin automation data.
 * @property {Array<Object>} aoarEmailJobs - The array of returned AOAR email jobs (empty if none returned).
 * @property {Object|null} processResult - The result of the process.
 * @property {boolean} isDataTransferSuccess - A boolean indicating whether data transfer succeeded.
 * @property {boolean} isAoarSuccess - A boolean indicating whether AOAR succeeded.
 * @property {boolean} isDeleteSuccess - A boolean indicating whether deletion succeeded.
 * @property {Object|null} error - An error object containing details about any encountered error.
 * @property {number} totalPages - Total number of pages.
 * @property {boolean} loading - A boolean indicating whether loading is active.
 */
const useAutomations = () => {
    const [processResult, setProcessResult] = useState(null);
    const [isDataTransferSuccess, setIsDataTransferSuccess] = useState(false);
    const [isAoarSuccess, setIsAoarSuccess] = useState(false);
    const [isDeleteSuccess, setIsDeleteSuccess] = useState(false);
    const [aoarEmailJobs, setAoarEmailJobs] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    /**
     * Triggers the OAC (Office Assignment Change) automation process.
     *
     * @async
     * @function
     * @param {Object} oacData - The fields for triggering the automation, passed from formik. Reference config/form-fields.js or validation/admin-automation-validations.js for details on properties.
     * @returns {Promise<Object>} A promise that resolves when the process is complete with the result object.
     */
    const runOac = async (oacData) => {
        try {
            setLoading(true);
            setError(null);

            const path = `/automations/oac`;
            const response = await callApi(path, 'post', oacData);

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const oacResult = await response.json();
            setProcessResult(oacResult);
            return oacResult;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Triggers the OAR (Office Assignment Removal) automation process.
     *
     * @async
     * @function
     * @param {Object} oarData - The fields for triggering the automation, passed from formik. Reference config/form-fields.js or validation/admin-automation-validations.js for details on properties.
     * @returns {Promise<Object>} A promise that resolves when the process is complete with the result object.
     */
    const runOar = async (oarData) => {
        try {
            setLoading(true);
            setError(null);

            const path = `/automations/oar`;
            const response = await callApi(path, 'post', oarData);

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const oarResult = await response.json();
            setProcessResult(oarResult);
            return oarResult;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Triggers the AOAR (All Office Assignments Removed) automation process.
     *
     * @async
     * @function
     * @param {Object} aoarData - The fields for triggering the automation, passed from formik. Reference config/form-fields.js or validation/admin-automation-validations.js for details on properties.
     * @returns {Promise<boolean>} A promise that resolves when the process is complete.
     */
    const runAoar = async (aoarData) => {
        try {
            setLoading(true);
            setError(null);

            const path = `/automations/aoar`;
            const response = await callApi(path, 'post', aoarData);

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            setIsAoarSuccess(true);
            return true;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            setIsAoarSuccess(false);
            return false;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Triggers the User Removal automation process.
     *
     * @async
     * @function
     * @param {Object} userRemovalData - The fields for triggering the automation, passed from formik. Reference config/form-fields.js or validation/admin-automation-validations.js for details on properties.
     * @returns {Promise<Object>} A promise that resolves when the process is complete with the result object.
     */
    const runUserRemoval = async (userRemovalData) => {
        try {
            setLoading(true);
            setError(null);

            const path = `/automations/userRemoval`;
            const response = await callApi(path, 'post', userRemovalData);

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const userRemovalResult = await response.json();
            setProcessResult(userRemovalResult);
            return userRemovalResult;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Triggers the User Data Transfer automation process.
     *
     * @async
     * @function
     * @param {Object} userDataTransferData - The fields for triggering the automation, passed from formik. Reference config/form-fields.js or validation/admin-automation-validations.js for details on properties.
     * @returns {Promise<Boolean>} A promise that resolves when the process is complete with the result boolean status.
     */
    const runUserDataTransfer = async (userDataTransferData) => {
        try {
            setLoading(true);
            setError(null);

            const path = `/automations/userDataTransfer`;
            const response = await callApi(path, 'post', userDataTransferData);

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            setIsDataTransferSuccess(true);
            return true;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            setIsDataTransferSuccess(false);
            return false;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Searches for AOAR email jobs based on specified criteria.
     *
     * @async
     * @function
     * @param {string} [searchString=''] - The search string for filtering by eassist email.
     * @param {number} [currentPage=1] - The current page for pagination.
     * @param {number} [limit=10] - The number of jobs to retrieve per page.
     * @returns {Promise<Array>} A promise that resolves with a list of returned jobs.
     */
    const searchAoarEmailJobs = async (
        searchString = '',
        currentPage = 1,
        limit = 5
    ) => {
        try {
            setLoading(true);
            setError(null);

            const path = `/automations/aoar-email-jobs/search/query?searchString=${searchString}&limit=${limit}&page=${currentPage}`;
            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const { results: returnedJobs, totalPages: returnedTotalPages } =
                await response.json();
            setAoarEmailJobs(returnedJobs);
            setTotalPages(returnedTotalPages);
            return returnedJobs;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
        } finally {
            setLoading(false);
        }
    };

    /**
     * Deletes an AOAR email job by ID.
     *
     * @async
     * @function
     * @param {string} [jobId] - The ID of the job to be deleted.
     * @returns {Promise<Void>} A promise that resolves when the job is deleted.
     */
    const deleteAoarEmailJob = async (jobId) => {
        try {
            setLoading(true);
            setError(null);

            const path = `/automations/aoar-email-jobs/${jobId}`;
            const response = await callApi(path, 'delete');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            setIsDeleteSuccess(true);
            return true;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return false;
        } finally {
            setLoading(false);
        }
    };

    return {
        processResult,
        runOac,
        runOar,
        runAoar,
        runUserRemoval,
        runUserDataTransfer,
        isDataTransferSuccess,
        isAoarSuccess,
        searchAoarEmailJobs,
        aoarEmailJobs,
        totalPages,
        loading,
        error,
        deleteAoarEmailJob,
        isDeleteSuccess
    };
};

export default useAutomations;
