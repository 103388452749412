import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useOffices from '../../hooks/useOffices.js';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import ErrorMessage from '../common/ErrorMessage.jsx';
import { setOfficeObj } from '../../redux/slices/office-slice.js';

/**
 * Renders a modal for uploading a QR code image.
 *
 * @param {Object} props - The component props.
 * @param {string} props.officeId - The id of the office.
 * @param {Function} props.setShowModal - The function to control the visibility of the modal.
 * @returns {JSX.Element} JSX element representing the modal.
 */
const UploadQrCodeImageModal = (props) => {
    const { officeId, setShowModal } = props;
    const dispatch = useDispatch();

    const { loading, error, uploadQrCodeImage } = useOffices();

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileSelection = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    /**
     * Handles the upload action.
     *
     * @returns {Promise<void>} Promise that resolves after the upload action is completed.
     */
    const handleUpload = async () => {
        const updatedOffice = await uploadQrCodeImage(officeId, selectedFile);

        if (updatedOffice) {
            dispatch(setOfficeObj(updatedOffice));
            setShowModal(false);
        }
    };

    return (
        <>
            <div className="modal-overlay">
                <div className="modal-wrapper">
                    <div className="modal">
                        <div className="modal-header">
                            <h5 className="modal-heading">
                                Upload QR Code Image
                            </h5>
                        </div>
                        <br />
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileSelection}
                            autoFocus={true}
                        />
                        {loading && <LoadingSpinner />}
                        {error && <ErrorMessage message={error.message} />}
                        <br />
                        <div className="modal-btns">
                            <button
                                className="modal-confirm-btn"
                                onClick={() => handleUpload()}>
                                Upload
                            </button>
                            <button
                                className="modal-cancel-btn"
                                onClick={() => setShowModal(false)}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

// Prop validation
UploadQrCodeImageModal.propTypes = {
    setShowModal: PropTypes.func.isRequired,
    officeId: PropTypes.string.isRequired
};

export default UploadQrCodeImageModal;
