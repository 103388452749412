import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import httpStatus from 'http-status';
import SubmitButton from '../common/SubmitButton.jsx';
import { runUserRemovalFormFields } from '../../config/form-fields.js';
import ErrorMessage from '../common/ErrorMessage.jsx';
import SuccessMessage from '../common/SuccessMessage.jsx';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import useAutomations from '../../hooks/useAutomations.js';
import { runUserRemovalValidation } from '../../validation/admin-automation-validations.js';
import camelCaseToSentence from '../../utils/camel-case-to-sentence.js';

/**
 * UserRemovalForm component for rendering the form to trigger the User Removal automation process.
 *
 * @component
 * @returns {JSX.Element} - The rendered UserRemovalForm component.
 */
const UserRemovalForm = () => {
    const [processResultAllOk, setProcessResultAllOk] = useState(true);
    const [specificErrorsMessage, setSpecificErrorsMessage] = useState('');

    const { error, loading, processResult, runUserRemoval } = useAutomations();

    const formik = useFormik({
        initialValues: runUserRemovalFormFields,
        validationSchema: runUserRemovalValidation,
        onSubmit: async (values) => {
            await runUserRemoval(values);
        }
    });

    useEffect(() => {
        /**
         * Checks the status codes of the result object and handles proper messaging
         */
        const handleStatusCodes = () => {
            let errorMessage = 'The following failed:\n\n';

            if (processResult) {
                // Grab status codes
                const statusCodes = Object.values(processResult);
                if (statusCodes.every((code) => code === 200)) {
                    setProcessResultAllOk(true);
                } else {
                    setProcessResultAllOk(false);

                    const keys = Object.keys(processResult);
                    keys.forEach((key) => {
                        if (processResult[key] !== 200) {
                            // Converts result codes to user friendly text and sets to message
                            errorMessage += `${camelCaseToSentence(key)}: ${
                                httpStatus[processResult[key].toString()]
                            }\n`;
                        }
                    });
                }
            }

            setSpecificErrorsMessage(errorMessage);
        };

        handleStatusCodes();
    }, [processResult]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="newsc-form-container">
                    <h2>Remove User</h2>
                    <label htmlFor="scFullName" className="form-label">
                        SC Full Name
                    </label>
                    <input
                        id="scFullName"
                        type="text"
                        className="newsc-input"
                        placeholder="e.g. John Doe"
                        {...formik.getFieldProps('scFullName')}
                    />
                    {formik.touched.scFullName && formik.errors.scFullName ? (
                        <ErrorMessage message={formik.errors.scFullName} />
                    ) : null}

                    <label htmlFor="scEassistEmail" className="form-label">
                        SC eAssist Email
                    </label>
                    <input
                        id="scEassistEmail"
                        type="text"
                        className="newsc-input"
                        placeholder="e.g. john.doe@eassist.me"
                        {...formik.getFieldProps('scEassistEmail')}
                    />
                    {formik.touched.scEassistEmail &&
                    formik.errors.scEassistEmail ? (
                        <ErrorMessage
                            autofocus
                            message={formik.errors.scEassistEmail}
                        />
                    ) : null}
                    <label htmlFor="scPersonalEmail" className="form-label">
                        SC Personal Email
                    </label>
                    <input
                        id="scPersonalEmail"
                        type="text"
                        className="newsc-input"
                        placeholder="e.g. john.doe@gmail.com"
                        {...formik.getFieldProps('scPersonalEmail')}
                    />
                    {formik.touched.scPersonalEmail &&
                    formik.errors.scPersonalEmail ? (
                        <ErrorMessage
                            autofocus
                            message={formik.errors.scPersonalEmail}
                        />
                    ) : null}
                    <SubmitButton label="Remove User" />
                </div>
            </form>
            <br />
            <br />
            {loading && <LoadingSpinner />}
            {Object.keys(formik.errors).length !== 0 && (
                <ErrorMessage message="There are form errors above, please fix." />
            )}
            {error && <ErrorMessage message={error.message} />}
            {processResult && processResultAllOk ? (
                <SuccessMessage message="User Removal process ran successfully" />
            ) : null}
            {processResult && !processResultAllOk ? (
                <ErrorMessage message={specificErrorsMessage} />
            ) : null}
        </>
    );
};

export default UserRemovalForm;
