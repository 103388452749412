import React from 'react';
import PropTypes from 'prop-types';
import DeleteButton from '../common/DeleteButton.jsx';

/**
 * Renders a single AOAR email job item with delete functionality.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.aoarEmailJob - The AOAR email job object containing job details.
 * @param {string} props.aoarEmailJob.id - The unique identifier of the email job.
 * @param {string} props.aoarEmailJob.scName - The name associated with the email job.
 * @param {string} props.aoarEmailJob.scEassistEmail - The email associated with the email job.
 * @param {string} props.aoarEmailJob.executeDate - The execution date of the email job.
 * @param {Function} props.handleReloadData - Function to reload data after deleting the email job.
 * @param {Function} props.deleteAoarEmailJob - Function to delete the AOAR email job.
 * @returns {JSX.Element} JSX element representing the AOAR email job component.
 */
const AoarEmailJob = ({
    aoarEmailJob,
    handleReloadData,
    deleteAoarEmailJob
}) => {
    /**
     * Handles the deletion of the AOAR email job.
     *
     * @returns {Promise<void>} Promise that resolves after the email job is deleted.
     */
    const handleDelete = async () => {
        await deleteAoarEmailJob(aoarEmailJob.id);

        handleReloadData();
    };

    return (
        <div className="admin-email-job-item">
            <div>
                <p>Name: {aoarEmailJob.scName}</p>
                <p>SC Email: {aoarEmailJob.scEassistEmail}</p>
                <p>Execute Date: {aoarEmailJob.executeDate}</p>
            </div>
            <DeleteButton label="Delete Job" onClick={handleDelete} />
        </div>
    );
};

AoarEmailJob.propTypes = {
    aoarEmailJob: PropTypes.object.isRequired,
    handleReloadData: PropTypes.func.isRequired,
    deleteAoarEmailJob: PropTypes.func.isRequired
};

export default AoarEmailJob;
