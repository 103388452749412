import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setActiveTab } from '../../redux/slices/header-slice.js';
import useOffices from '../../hooks/useOffices.js';
import OfficeNotesList from '../../components/office-notes/OfficeNotesList.jsx';
import OfficeNotesSearchOptions from '../../components/office-notes/OfficeNotesSearchOptions.jsx';

/**
 * React component for the page displaying a list of office notes.
 *
 * @component
 * @returns {JSX.Element} The JSX representation of the OfficeNotesListPage component.
 */
const OfficeNotesListPage = () => {
    const dispatch = useDispatch();

    const [showMoreFilterOptions, setShowMoreFilterOptions] = useState(false);
    const [type, setType] = useState('active');
    const [officeSysName, setOfficeSysName] = useState('');
    const [officeName, setOfficeName] = useState('');
    const [dentalSoftware, setDentalSoftware] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [eClaims, setEclaims] = useState('');
    const [xrays, setXrays] = useState('');
    const [computerName, setComputerName] = useState('');
    const [os, setOs] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [dentalSoftwareOptions, setDentalSoftwareOptions] = useState([]);
    const [eclaimsOptions, setEclaimsOptions] = useState([]);
    const [xrayOptions, setXrayOptions] = useState([]);
    const {
        offices,
        totalPages,
        error,
        loading,
        searchOffices,
        getDistinctOfficeProperty
    } = useOffices();

    // Sets the navigation tab
    useEffect(() => {
        dispatch(setActiveTab('Notes'));

        return () => {
            dispatch(setActiveTab(null));
        };
    });

    // Retrieves distinct values from the database for three properties, this is so we can load dropdown options with accurate options
    useEffect(() => {
        const getDropdownOptions = async () => {
            // Grab values
            let dentalSoftwareDistinctValues =
                await getDistinctOfficeProperty('dentalSoftware');
            let eclaimsDistinctValues =
                await getDistinctOfficeProperty('eClaims');
            let xrayDistinctValues = await getDistinctOfficeProperty('xrays');

            // Get rid of empty strings if they exist and convert to dropdown options format
            dentalSoftwareDistinctValues = dentalSoftwareDistinctValues.filter(
                (value) => value.trim() !== ''
            );
            dentalSoftwareDistinctValues = dentalSoftwareDistinctValues.map(
                (value) => {
                    return { value, label: value };
                }
            );
            eclaimsDistinctValues = eclaimsDistinctValues.filter(
                (value) => value.trim() !== ''
            );
            eclaimsDistinctValues = eclaimsDistinctValues.map((value) => {
                return { value, label: value };
            });
            xrayDistinctValues = xrayDistinctValues.filter(
                (value) => value.trim() !== ''
            );
            xrayDistinctValues = xrayDistinctValues.map((value) => {
                return { value, label: value };
            });

            // Set final arrays to state variables
            setDentalSoftwareOptions(dentalSoftwareDistinctValues);
            setEclaimsOptions(eclaimsDistinctValues);
            setXrayOptions(xrayDistinctValues);
        };

        getDropdownOptions();
    }, []);

    // Grabs offices everytime a filter field is changed
    useEffect(() => {
        const getOffices = async () => {
            // If show more is on, we need to display less on the page to fit properly. Mostly for styling.
            const limit = showMoreFilterOptions ? 28 : 36;
            // get rid of + and get rid of any spaces, hashes, or parenthesis
            const cleanedPhoneNumber = phoneNumber.replace(/[+\s#()-]/g, '');
            await searchOffices(
                officeSysName,
                type,
                officeName,
                computerName,
                cleanedPhoneNumber,
                dentalSoftware,
                eClaims,
                xrays,
                os,
                currentPage,
                limit
            );
        };

        getOffices();
    }, [
        officeSysName,
        type,
        currentPage,
        showMoreFilterOptions,
        dentalSoftware,
        phoneNumber,
        eClaims,
        xrays,
        officeName,
        computerName,
        os
    ]);

    return (
        <div className="notes-container">
            <OfficeNotesSearchOptions
                setOfficeSysName={setOfficeSysName}
                setOfficeName={setOfficeName}
                setPhoneNumber={setPhoneNumber}
                setDentalSoftware={setDentalSoftware}
                setEclaims={setEclaims}
                setXrays={setXrays}
                setComputerName={setComputerName}
                setOs={setOs}
                setType={setType}
                showMoreFilterOptions={showMoreFilterOptions}
                setShowMoreFilterOptions={setShowMoreFilterOptions}
                dentalSoftwareOptions={dentalSoftwareOptions}
                eclaimsOptions={eclaimsOptions}
                xrayOptions={xrayOptions}
                selectedDentalSoftware={dentalSoftware}
                selectedEclaims={eClaims}
                selectedXrays={xrays}
                selectedOs={os}
                error={error}
                loading={loading}
            />
            {offices.length > 0 && (
                <OfficeNotesList
                    offices={offices}
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                    customContainerStyles={{ marginTop: '30px' }}
                />
            )}
        </div>
    );
};

export default OfficeNotesListPage;
