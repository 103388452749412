import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import Card from './Card.jsx';

/**
 * NewScCardsList component for displaying a paginated list of new SCs.
 *
 * @component
 * @example
 * // Example usage:
 * <NewScCardsList newScs={newScList} totalPages={5} setCurrentPage={handlePageChange} />
 *
 * @param {Object} props - The component props.
 * @param {Array} props.newScs - The array of new SC card objects to be displayed in the list (required).
 * @param {number} props.totalPages - The total number of pages for pagination (required).
 * @param {Function} props.setCurrentPage - The function to set the current page when pagination changes (required).
 *
 * @returns {JSX.Element} - The rendered NewScCardsList component.
 */
const NewScCardsList = ({
    newScs,
    totalPages,
    setCurrentPage,
    customContainerStyles
}) => {
    return (
        <>
            <div
                className="notes-offices-cushion"
                style={{ ...customContainerStyles }}>
                <div className="notes-offices-container">
                    {newScs.map((newSc) => (
                        <Card key={uuidv4()} newScCard={newSc} />
                    ))}
                </div>
            </div>
            <div>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel="Next >"
                    previousLabel="< Previous"
                    pageCount={totalPages}
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageClassName="page-num"
                    previousLinkClassName="page-num"
                    nextLinkClassName="page-num"
                    activeLinkClassName="active-page"
                    onPageChange={(e) => setCurrentPage(e.selected + 1)}
                    disabledClassName="pagination-disabled"
                />
            </div>
        </>
    );
};

NewScCardsList.propTypes = {
    newScs: PropTypes.arrayOf(PropTypes.object).isRequired,
    totalPages: PropTypes.number.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
    customContainerStyles: PropTypes.object
};

export default NewScCardsList;
