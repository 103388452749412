/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ErrorMessage from '../common/ErrorMessage.jsx';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import TableTitleHeader from '../common/TableTitleHeader.jsx';
import { yellow } from '../../styles/standard-colors.js';
import ConfirmationModal from '../common/ConfirmationModal.jsx';
import {
    selectNewScObj,
    setNewScObj
} from '../../redux/slices/new-sc-slice.js';
import useNewScs from '../../hooks/useNewScs.js';
import getTimeStamp from '../../utils/get-timestamp.js';

/**
 * Container component for displaying and managing onboarding information.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.userPermissions - Array of user permissions.
 * @returns {JSX.Element} JSX element representing the Onboarding Container.
 */
const OnboardingContainer = ({ userPermissions }) => {
    const newScObj = useSelector(selectNewScObj);
    const dispatch = useDispatch();

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const [updateBody, setUpdateBody] = useState(null);

    const [pass, setPass] = useState(
        Object.keys(newScObj).length > 0
            ? newScObj.screenshots.passComplete
            : false
    );
    const [showEditFailureNotes, setShowEditFailureNotes] = useState(false);
    const [failureNotes, setFailureNotes] = useState(
        Object.keys(newScObj).length > 0
            ? newScObj.screenshots.reasonFailed
            : ''
    );
    const [showEditNotes, setShowEditNotes] = useState(false);
    const [onboardingNotes, setOnboardingNotes] = useState(
        newScObj.onboardingNotes ? newScObj.onboardingNotes : ''
    );
    const dateValue =
        Object.keys(newScObj).length > 0
            ? getTimeStamp(newScObj.orientationScheduled.dateScheduled)
            : null; // date object based on timestamp string
    const [orientationDate, setOrientationDate] = useState(dateValue);

    const { error, loading, updateNewSc } = useNewScs();

    // Function to handle radio button change for screenshots selection
    const changeScreenshotsSelection = (e) => {
        setPass(e.target.value === 'pass');
    };

    // Function to open confirmation modal
    const handleModalOpen = async (title, message, updateObject) => {
        setModalTitle(title);
        setModalMessage(message);
        setUpdateBody(updateObject);

        setShowConfirmationModal(true);
    };

    // Function to close confirmation modal
    const handleModalClose = async () => {
        setModalTitle('');
        setModalMessage('');

        setShowConfirmationModal(false);
    };

    // Function to handle update action
    const handleUpdate = async () => {
        handleModalClose();

        const updatedNewSc = await updateNewSc(newScObj.id, updateBody);

        // Check for failure
        if (!updatedNewSc) {
            return;
        }

        dispatch(setNewScObj(updatedNewSc));
    };

    if (Object.keys(newScObj).length === 0) {
        return <></>;
    }

    return (
        <div className="office-computers-container">
            <TableTitleHeader
                title="Onboarding"
                underlineColor={yellow}
                customContainerStyles={{
                    marginLeft: '30px',
                    justifyContent: 'center'
                }}
            />

            {loading && <LoadingSpinner />}
            {error && <ErrorMessage message={error.message} />}

            <div className="office-computer-container">
                <table className="newsc-tasks-table">
                    <tbody>
                        <tr style={{ padding: '.25em' }}>
                            <td className="newsc-tasks-table-left-cell">
                                Screenshots Received
                            </td>
                            {userPermissions.includes('newsc:full') ||
                            userPermissions.includes(
                                'newsc:readwrite-screenshots'
                            ) ? (
                                <td className="newsc-tasks-table-right-cell">
                                    {/* conditional could also be if the pass object returns as not empty */}
                                    {newScObj.screenshots.passComplete ? (
                                        <>
                                            <p className="newsc-subtitle">
                                                Passed
                                            </p>
                                            <p className="newsc-completed-stamp">
                                                {newScObj.screenshots.techEmail}{' '}
                                                {getTimeStamp(
                                                    newScObj.screenshots
                                                        .timeStamp
                                                )}
                                            </p>
                                        </>
                                    ) : (
                                        <div className="newsc-tasks-screenshots-container">
                                            <div>
                                                <input
                                                    type="radio"
                                                    value="pass"
                                                    name="screenshots"
                                                    onChange={(e) =>
                                                        changeScreenshotsSelection(
                                                            e
                                                        )
                                                    }
                                                />
                                                Pass
                                                <input
                                                    type="radio"
                                                    value="fail"
                                                    name="screenshots"
                                                    onChange={(e) =>
                                                        changeScreenshotsSelection(
                                                            e
                                                        )
                                                    }
                                                    checked={!pass}
                                                />
                                                Fail
                                            </div>
                                            {/* if the user selects fail the textarea input will appear */}
                                            {!pass ? (
                                                <>
                                                    <p className="newsc-subtitle">
                                                        Failure Notes:
                                                    </p>
                                                    {showEditFailureNotes ? (
                                                        <>
                                                            <textarea
                                                                rows="5"
                                                                cols="20"
                                                                name="failNotes"
                                                                onChange={(e) =>
                                                                    setFailureNotes(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                value={
                                                                    failureNotes
                                                                }
                                                            />
                                                            <button
                                                                className="newsc-btn"
                                                                onClick={() =>
                                                                    handleModalOpen(
                                                                        'Edit failure notes',
                                                                        'Are you sure you want to edit failure notes?',
                                                                        {
                                                                            screenshots:
                                                                                {
                                                                                    passComplete: false,
                                                                                    reasonFailed:
                                                                                        failureNotes
                                                                                }
                                                                        }
                                                                    )
                                                                }>
                                                                Save
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <p className="newsc-subtitle">
                                                                {failureNotes}
                                                            </p>
                                                            <p className="newsc-completed-stamp">
                                                                {newScObj
                                                                    .screenshots
                                                                    .techEmail
                                                                    ? newScObj
                                                                          .screenshots
                                                                          .techEmail
                                                                    : 'Tech Email: N/A'}{' '}
                                                                {newScObj
                                                                    .screenshots
                                                                    .timeStamp
                                                                    ? getTimeStamp(
                                                                          newScObj
                                                                              .screenshots
                                                                              .timeStamp
                                                                      )
                                                                    : 'Timestamp: N/A'}
                                                            </p>
                                                            <button
                                                                className="newsc-btn"
                                                                onClick={() =>
                                                                    setShowEditFailureNotes(
                                                                        true
                                                                    )
                                                                }>
                                                                Edit
                                                            </button>
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <button
                                                    className="newsc-btn"
                                                    onClick={() =>
                                                        handleModalOpen(
                                                            'Screenshots received',
                                                            'Are you sure you want to mark screenshots as received?',
                                                            {
                                                                screenshots: {
                                                                    passComplete: true
                                                                }
                                                            }
                                                        )
                                                    }>
                                                    Complete
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </td>
                            ) : (
                                <td className="newsc-tasks-table-right-cell">
                                    {newScObj.screenshots.passComplete ? (
                                        <>
                                            <p className="newsc-subtitle">
                                                Passed
                                            </p>
                                            <p className="newsc-completed-stamp">
                                                {newScObj.screenshots.techEmail}{' '}
                                                {getTimeStamp(
                                                    newScObj.screenshots
                                                        .timeStamp
                                                )}
                                            </p>
                                        </>
                                    ) : (
                                        <div className="newsc-tasks-screenshots-container">
                                            <p>Failing</p>
                                            <p className="newsc-subtitle">
                                                Failure Notes:
                                            </p>
                                            <>
                                                <p className="newsc-subtitle">
                                                    {
                                                        newScObj.screenshots
                                                            .reasonFailed
                                                    }
                                                </p>
                                                <p className="newsc-completed-stamp">
                                                    {newScObj.screenshots
                                                        .techEmail
                                                        ? newScObj.screenshots
                                                              .techEmail
                                                        : 'Tech Email: N/A'}
                                                    {newScObj.screenshots
                                                        .timeStamp
                                                        ? getTimeStamp(
                                                              newScObj
                                                                  .screenshots
                                                                  .timeStamp
                                                          )
                                                        : 'Timestamp: N/A'}
                                                </p>
                                            </>
                                        </div>
                                    )}
                                </td>
                            )}
                        </tr>

                        <tr>
                            <td className="newsc-tasks-table-left-cell">
                                Sent Invitation to Class 2
                            </td>
                            <td className="newsc-tasks-table-right-cell">
                                {newScObj.classInvite.passComplete ? (
                                    <>
                                        <p className="newsc-subtitle">
                                            Completed
                                        </p>
                                        <p className="newsc-completed-stamp">
                                            {newScObj.classInvite.techEmail}{' '}
                                            {getTimeStamp(
                                                newScObj.classInvite.timeStamp
                                            )}
                                        </p>
                                    </>
                                ) : userPermissions.includes('newsc:full') ||
                                  userPermissions.includes(
                                      'newsc:readwrite-classinvite'
                                  ) ? (
                                    <button
                                        className="newsc-btn"
                                        onClick={() =>
                                            handleModalOpen(
                                                'Confirmation',
                                                'Are you sure you want to confirm the invite for class 2 was sent to this user?',
                                                {
                                                    classInvite: {
                                                        passComplete: true
                                                    }
                                                }
                                            )
                                        }>
                                        Complete
                                    </button>
                                ) : (
                                    <p>Not Complete</p>
                                )}
                            </td>
                        </tr>

                        <tr>
                            <td className="newsc-tasks-table-left-cell">
                                Class 2 Mod 1
                            </td>
                            <td className="newsc-tasks-table-right-cell">
                                {newScObj.class2Mod1.passComplete ? (
                                    <>
                                        <p className="newsc-subtitle">
                                            Completed
                                        </p>
                                        <p className="newsc-completed-stamp">
                                            {newScObj.class2Mod1.techEmail}{' '}
                                            {getTimeStamp(
                                                newScObj.class2Mod1.timeStamp
                                            )}
                                        </p>
                                    </>
                                ) : userPermissions.includes('newsc:full') ||
                                  userPermissions.includes(
                                      'newsc:readwrite-class2mod1'
                                  ) ? (
                                    <button
                                        className="newsc-btn"
                                        onClick={() =>
                                            handleModalOpen(
                                                'Confirmation',
                                                'Are you sure you want to confirm the Class 2 Mod 1 was completed for this user?',
                                                {
                                                    class2Mod1: {
                                                        passComplete: true
                                                    }
                                                }
                                            )
                                        }>
                                        Complete
                                    </button>
                                ) : (
                                    <p>Not Complete</p>
                                )}
                            </td>
                        </tr>

                        <tr>
                            <td className="newsc-tasks-table-left-cell">
                                Class 2 Mod 2
                            </td>
                            <td className="newsc-tasks-table-right-cell">
                                {newScObj.class2Mod2.passComplete ? (
                                    <>
                                        <p className="newsc-subtitle">
                                            Completed
                                        </p>
                                        <p className="newsc-completed-stamp">
                                            {newScObj.class2Mod2.techEmail}{' '}
                                            {getTimeStamp(
                                                newScObj.class2Mod2.timeStamp
                                            )}
                                        </p>
                                    </>
                                ) : userPermissions.includes('newsc:full') ||
                                  userPermissions.includes(
                                      'newsc:readwrite-class2mod2'
                                  ) ? (
                                    <button
                                        className="newsc-btn"
                                        onClick={() =>
                                            handleModalOpen(
                                                'Confirmation',
                                                'Are you sure you want to confirm the Class 2 Mod 2 was completed for this user?',
                                                {
                                                    class2Mod2: {
                                                        passComplete: true
                                                    }
                                                }
                                            )
                                        }>
                                        Complete
                                    </button>
                                ) : (
                                    <p>Not Complete</p>
                                )}
                            </td>
                        </tr>

                        <tr>
                            <td className="newsc-tasks-table-left-cell">
                                Class 2 Mod 3
                            </td>
                            <td className="newsc-tasks-table-right-cell">
                                {newScObj.class2Mod3.passComplete ? (
                                    <>
                                        <p className="newsc-subtitle">
                                            Completed
                                        </p>
                                        <p className="newsc-completed-stamp">
                                            {newScObj.class2Mod3.techEmail}{' '}
                                            {getTimeStamp(
                                                newScObj.class2Mod3.timeStamp
                                            )}
                                        </p>
                                    </>
                                ) : userPermissions.includes('newsc:full') ||
                                  userPermissions.includes(
                                      'newsc:readwrite-class2mod3'
                                  ) ? (
                                    <button
                                        className="newsc-btn"
                                        onClick={() =>
                                            handleModalOpen(
                                                'Confirmation',
                                                'Are you sure you want to confirm the Class 2 Mod 3 was completed for this user?',
                                                {
                                                    class2Mod3: {
                                                        passComplete: true
                                                    }
                                                }
                                            )
                                        }>
                                        Complete
                                    </button>
                                ) : (
                                    <p>Not Complete</p>
                                )}
                            </td>
                        </tr>

                        <tr>
                            <td className="newsc-tasks-table-left-cell">
                                Class 2 Mod 4
                            </td>
                            <td className="newsc-tasks-table-right-cell">
                                {newScObj.class2Mod4.passComplete ? (
                                    <>
                                        <p className="newsc-subtitle">
                                            Completed
                                        </p>
                                        <p className="newsc-completed-stamp">
                                            {newScObj.class2Mod4.techEmail}{' '}
                                            {getTimeStamp(
                                                newScObj.class2Mod4.timeStamp
                                            )}
                                        </p>
                                    </>
                                ) : userPermissions.includes('newsc:full') ||
                                  userPermissions.includes(
                                      'newsc:readwrite-class2mod4'
                                  ) ? (
                                    <button
                                        className="newsc-btn"
                                        onClick={() =>
                                            handleModalOpen(
                                                'Confirmation',
                                                'Are you sure you want to confirm the Class 2 Mod 4 was completed for this user?',
                                                {
                                                    class2Mod4: {
                                                        passComplete: true
                                                    }
                                                }
                                            )
                                        }>
                                        Complete
                                    </button>
                                ) : (
                                    <p>Not Complete</p>
                                )}
                            </td>
                        </tr>

                        <tr>
                            <td className="newsc-tasks-table-left-cell">
                                Orientation Link Sent
                            </td>
                            <td className="newsc-tasks-table-right-cell">
                                {newScObj.orientationLinkSent.passComplete ? (
                                    <>
                                        <p className="newsc-subtitle">
                                            Completed
                                        </p>
                                        <p className="newsc-completed-stamp">
                                            {
                                                newScObj.orientationLinkSent
                                                    .techEmail
                                            }{' '}
                                            {getTimeStamp(
                                                newScObj.orientationLinkSent
                                                    .timeStamp
                                            )}
                                        </p>
                                    </>
                                ) : userPermissions.includes('newsc:full') ||
                                  userPermissions.includes(
                                      'newsc:readwrite-orientationlinksent'
                                  ) ? (
                                    <button
                                        className="newsc-btn"
                                        onClick={() =>
                                            handleModalOpen(
                                                'Confirmation',
                                                'Are you sure you want to confirm the orientation link was sent for this user?',
                                                {
                                                    orientationLinkSent: {
                                                        passComplete: true
                                                    }
                                                }
                                            )
                                        }>
                                        Complete
                                    </button>
                                ) : (
                                    <p>Not Complete</p>
                                )}
                            </td>
                        </tr>

                        <tr>
                            <td className="newsc-tasks-table-left-cell">
                                Orientation Scheduled
                            </td>
                            <td className="newsc-tasks-table-right-cell">
                                {newScObj.orientationScheduled.passComplete ? (
                                    <>
                                        <label htmlFor="date-of-orientation">
                                            Date of Orientation:
                                        </label>
                                        {userPermissions.includes(
                                            'newsc:full'
                                        ) ||
                                        userPermissions.includes(
                                            'newsc:readwrite-orientationscheduled'
                                        ) ? (
                                            <input
                                                type="datetime-local"
                                                id="date-of-orientation"
                                                name="date-of-orientation"
                                                value={orientationDate}
                                                onChange={(e) =>
                                                    setOrientationDate(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        ) : (
                                            <p>{orientationDate}</p>
                                        )}

                                        <br />
                                        <br />
                                        <p className="newsc-completed-stamp">
                                            {
                                                newScObj.orientationScheduled
                                                    .techEmail
                                            }{' '}
                                            {getTimeStamp(
                                                newScObj.orientationScheduled
                                                    .timeStamp
                                            )}
                                        </p>
                                        {userPermissions.includes(
                                            'newsc:full'
                                        ) ||
                                        userPermissions.includes(
                                            'newsc:readwrite-orientationscheduled'
                                        ) ? (
                                            <button
                                                className="newsc-btn"
                                                onClick={() =>
                                                    handleModalOpen(
                                                        'Confirmation',
                                                        'Are you sure you want to confirm Orientation Scheduled for this user?',
                                                        {
                                                            orientationScheduled:
                                                                {
                                                                    dateScheduled:
                                                                        orientationDate
                                                                }
                                                        }
                                                    )
                                                }>
                                                Save Edit
                                            </button>
                                        ) : null}
                                    </>
                                ) : userPermissions.includes('newsc:full') ||
                                  userPermissions.includes(
                                      'newsc:readwrite-orientationscheduled'
                                  ) ? (
                                    <>
                                        <label>Date of Orientation:</label>
                                        <br />
                                        <input
                                            type="datetime-local"
                                            value={orientationDate}
                                            onChange={(e) =>
                                                setOrientationDate(
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <br />
                                        <button
                                            className="newsc-btn"
                                            onClick={() =>
                                                handleModalOpen(
                                                    'Confirmation',
                                                    'Are you sure you want to confirm Orientation Scheduled for this user?',
                                                    {
                                                        orientationScheduled: {
                                                            passComplete: true,
                                                            dateScheduled:
                                                                orientationDate
                                                        }
                                                    }
                                                )
                                            }>
                                            Submit
                                        </button>
                                    </>
                                ) : (
                                    <p>Not Complete</p>
                                )}
                            </td>
                        </tr>

                        <tr>
                            <td className="newsc-tasks-table-left-cell">
                                Orientation Completed
                            </td>
                            <td className="newsc-tasks-table-right-cell">
                                {newScObj.orientation.passComplete ? (
                                    <>
                                        <p className="newsc-subtitle">
                                            Completed
                                        </p>
                                        <p className="newsc-completed-stamp">
                                            {newScObj.orientation.techEmail}{' '}
                                            {getTimeStamp(
                                                newScObj.orientation.timeStamp
                                            )}
                                        </p>
                                    </>
                                ) : userPermissions.includes('newsc:full') ||
                                  userPermissions.includes(
                                      'newsc:readwrite-orientation'
                                  ) ? (
                                    <button
                                        className="newsc-btn"
                                        onClick={() =>
                                            handleModalOpen(
                                                'Confirmation',
                                                'Are you sure you want to confirm Orientation for this user? Upon confirmation, an orientation complete email will be sent.',
                                                {
                                                    orientation: {
                                                        passComplete: true
                                                    }
                                                }
                                            )
                                        }>
                                        Complete
                                    </button>
                                ) : (
                                    <p>Not Complete</p>
                                )}
                            </td>
                        </tr>

                        <tr>
                            <td className="newsc-tasks-table-left-cell">
                                Total Completion Time
                            </td>
                            <td className="newsc-tasks-table-right-cell">
                                {newScObj.screenshots.passComplete &&
                                newScObj.orientation.passComplete ? (
                                    <p>
                                        {(
                                            (new Date(
                                                newScObj.orientation.timeStamp
                                            ).getTime() -
                                                new Date(
                                                    newScObj.screenshots.timeStamp
                                                ).getTime()) /
                                            (1000 * 60 * 60 * 24)
                                        ).toFixed(2)}
                                        day(s)
                                    </p>
                                ) : (
                                    <p>Not yet complete</p>
                                )}
                            </td>
                        </tr>

                        <tr>
                            <td className="newsc-tasks-table-left-cell">
                                First Assignment
                            </td>
                            <td className="newsc-tasks-table-right-cell">
                                {newScObj.firstAssignment.passComplete ? (
                                    <>
                                        <p className="newsc-subtitle">
                                            Completed
                                        </p>
                                        <p className="newsc-completed-stamp">
                                            {newScObj.firstAssignment.techEmail}{' '}
                                            {getTimeStamp(
                                                newScObj.firstAssignment
                                                    .timeStamp
                                            )}
                                        </p>
                                    </>
                                ) : userPermissions.includes('newsc:full') ||
                                  userPermissions.includes(
                                      'newsc:readwrite-firstAssignment'
                                  ) ? (
                                    <button
                                        className="newsc-btn"
                                        onClick={() =>
                                            handleModalOpen(
                                                'Confirmation',
                                                'Are you sure you want to confirm First Assignment for this user? An automated message will be sent to New SC Team chat.',
                                                {
                                                    firstAssignment: {
                                                        passComplete: true
                                                    }
                                                }
                                            )
                                        }>
                                        Complete
                                    </button>
                                ) : (
                                    <p>Not yet complete</p>
                                )}
                            </td>
                        </tr>

                        <tr>
                            <td
                                style={{
                                    borderRight: '1px #DCDCDC solid',
                                    padding: '.5em'
                                }}>
                                Notes:
                            </td>
                            {showEditNotes ? (
                                <>
                                    <p className="newsc-subtitle" />
                                    <textarea
                                        rows="5"
                                        cols="20"
                                        name="failNotes"
                                        value={onboardingNotes}
                                        onChange={(e) =>
                                            setOnboardingNotes(e.target.value)
                                        }
                                        style={{ maxWidth: '100%' }} // Add this line to set max-width
                                    />
                                    <button
                                        className="newsc-btn"
                                        onClick={() =>
                                            handleModalOpen(
                                                'Confirmation',
                                                'Are you sure you want to edit onboarding notes?',
                                                {
                                                    onboardingNotes
                                                }
                                            )
                                        }>
                                        Save
                                    </button>
                                </>
                            ) : (
                                <>
                                    <td style={{ padding: '.5em' }}>
                                        <p style={{ whiteSpace: 'pre-wrap' }}>
                                            {newScObj.onboardingNotes}
                                        </p>
                                        {/* Add this line to respect spaces and line breaks */}
                                        {userPermissions.includes(
                                            'newsc:full'
                                        ) ||
                                        userPermissions.includes(
                                            'newsc:readwrite-notes'
                                        ) ? (
                                            <button
                                                className="newsc-btn"
                                                onClick={() =>
                                                    setShowEditNotes(true)
                                                }>
                                                Edit
                                            </button>
                                        ) : null}
                                    </td>
                                </>
                            )}
                        </tr>
                    </tbody>
                </table>
            </div>

            {showConfirmationModal && (
                <ConfirmationModal
                    title={modalTitle}
                    message={modalMessage}
                    onConfirm={handleUpdate}
                    setShowModal={handleModalClose}
                />
            )}
        </div>
    );
};

OnboardingContainer.propTypes = {
    userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default OnboardingContainer;
