import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import ReactPaginate from 'react-paginate';
import { setActiveTab } from '../../redux/slices/header-slice.js';
import SubLinks from '../../components/header/SubLinks.jsx';
import { adminLinks } from '../../config/standard-links.js';
import useAutomations from '../../hooks/useAutomations.js';
import ErrorMessage from '../../components/common/ErrorMessage.jsx';
import LoadingSpinner from '../../components/common/LoadingSpinner.jsx';
import AoarEmailJob from '../../components/admin-automations/AoarEmailJob.jsx';
import SearchBar from '../../components/common/SearchBar.jsx';
import BackArrow from '../../components/common/BackArrow.jsx';

/**
 * Component for rendering the AOAR pending email jobs page.
 * @returns {JSX.Element} AOAR email jobs page component.
 */
const AoarEmailJobsPage = () => {
    const dispatch = useDispatch();

    const [searchString, setSearchString] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [reloadData, setReloadData] = useState(0);
    const {
        error,
        loading,
        aoarEmailJobs,
        totalPages,
        searchAoarEmailJobs,
        deleteAoarEmailJob
    } = useAutomations();

    // Simply changes the reloadData variable to force the refresh of email jobs when one is deleted
    const handleReloadData = () => {
        setReloadData(reloadData + 1);
    };

    useEffect(() => {
        dispatch(setActiveTab('Admin'));

        return () => {
            dispatch(setActiveTab(null));
        };
    });

    useEffect(() => {
        const getEmailJobs = async () => {
            const limit = 5;
            await searchAoarEmailJobs(searchString, currentPage, limit);
        };

        getEmailJobs();
    }, [searchString, currentPage, reloadData]);

    return (
        <div>
            <SubLinks links={adminLinks} />
            <div className="newsc-container">
                <br />
                <br />
                <br />
                <div className="create-office-heading">
                    <BackArrow navigateTo={-1} />
                    <h2>AOAR Pending Email Jobs</h2>
                </div>
                <br />
                <SearchBar
                    placeholder="Search for an email job by eAssist email"
                    onChange={setSearchString}
                />
                <div className="admin-search-results-container">
                    {loading && <LoadingSpinner />}
                    {error && <ErrorMessage message={error.message} />}
                    {!loading && !error && aoarEmailJobs.length < 1 ? (
                        <p>No jobs matched your search</p>
                    ) : null}
                    {aoarEmailJobs.map((job) => (
                        <AoarEmailJob
                            key={uuidv4()}
                            aoarEmailJob={job}
                            handleReloadData={handleReloadData}
                            deleteAoarEmailJob={deleteAoarEmailJob}
                        />
                    ))}
                    <div>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="Next >"
                            previousLabel="< Previous"
                            pageCount={totalPages}
                            renderOnZeroPageCount={null}
                            containerClassName="pagination"
                            pageClassName="page-num"
                            previousLinkClassName="page-num"
                            nextLinkClassName="page-num"
                            activeLinkClassName="active-page"
                            onPageChange={(e) => setCurrentPage(e.selected + 1)}
                            disabledClassName="pagination-disabled"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AoarEmailJobsPage;
