import GoogleLoginButton from '../components/login/GoogleLoginButton.jsx';
import eAssistLogo from '../assets/eAssist-icon.png';

/**
 * React component representing the login page of the Oracle application.
 *
 * @component
 */
const LoginPage = () => {
    return (
        <div id="login" className="login-spacing">
            <div className="register">
                <img
                    className="site__logo"
                    src={eAssistLogo}
                    alt="Eassist Icon"
                />
                <h2 id="sign_in">Sign In</h2>
                <GoogleLoginButton />
            </div>
        </div>
    );
};

export default LoginPage;
