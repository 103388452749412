import React, { useEffect } from 'react';
import useAuthentication from '../../hooks/useAuthentication.js';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import ErrorMessage from '../common/ErrorMessage.jsx';

/**
 * React component representing a Google login button for authentication.
 *
 * @component
 */
const GoogleLoginButton = () => {
    const { tokens, loading, error, login } = useAuthentication();

    /**
     * Handles the callback response from the Google login button.
     *
     * @async
     * @function
     * @param {Object} response - Google login callback response.
     * @returns {Promise<void>} Resolves when the login is successful.
     */
    const handleCallbackResponse = async (response) => {
        const googleJwt = response.credential;
        await login(googleJwt);
    };

    /**
     * useEffect hook to initialize and render the Google login button.
     */
    useEffect(() => {
        /* global google */
        google.accounts.id.initialize({
            client_id:
                process.env.REACT_APP_ENV === 'production'
                    ? '647372609192-i6884avf9t7eqhqk7fj5jtlk2004tpq7.apps.googleusercontent.com'
                    : '647372609192-gi8bmk9le80lsad0hs0pmbrmg75lhidc.apps.googleusercontent.com',
            callback: handleCallbackResponse
        });

        google.accounts.id.renderButton(
            document.getElementById('google-sign-in-button'),
            {
                theme: 'outline',
                size: 'large'
            }
        );
    }, []);

    /**
     * useEffect hook to reload the page once tokens are received.
     */
    useEffect(() => {
        if (tokens) {
            sessionStorage.setItem('accessToken', tokens.access.token);
            sessionStorage.setItem('refreshToken', tokens.refresh.token);
            window.location.reload();
        }
    }, [tokens]);

    return (
        <>
            <div id="google-sign-in-button"></div>
            <br />
            {loading && <LoadingSpinner />}
            {error && <ErrorMessage message={error.message} />}
        </>
    );
};

export default GoogleLoginButton;
