import React, { useState } from 'react';
// eslint-disable-next-line import/extensions
import { VscThreeBars } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import { pinkRed } from '../../styles/standard-colors.js';
import useAuthentication from '../../hooks/useAuthentication.js';

/**
 * React component representing collapsed navigation links in the header.
 *
 * @component
 * @returns {JSX.Element} The rendered LinksCollapse component.
 */
const LinksCollapse = () => {
    const [showDropdown, setShowDropdown] = useState(false);
    const { logout } = useAuthentication();

    return (
        <div className="header-bars-container">
            <VscThreeBars
                color={pinkRed}
                size={40}
                style={{ marginBottom: '10px', cursor: 'pointer' }}
                onClick={() => setShowDropdown(!showDropdown)}
            />
            {showDropdown && (
                <div className="header-dropdown-container">
                    <Link
                        to="/"
                        className="header-dropdown-item"
                        onClick={() => setShowDropdown(false)}>
                        <p className="header-drowpdown-text">Home</p>
                    </Link>
                    <Link
                        to="/notes"
                        className="header-dropdown-item"
                        onClick={() => setShowDropdown(false)}>
                        <p className="header-drowpdown-text">Notes</p>
                    </Link>
                    <Link
                        to="/newsc"
                        className="header-dropdown-item"
                        onClick={() => setShowDropdown(false)}>
                        <p className="header-drowpdown-text">New SC</p>
                    </Link>
                    <Link
                        to="/admin"
                        className="header-dropdown-item"
                        onClick={() => setShowDropdown(false)}>
                        <p className="header-drowpdown-text">Admin</p>
                    </Link>
                    <Link
                        to="/hipaa"
                        className="header-dropdown-item"
                        onClick={() => setShowDropdown(false)}>
                        <p className="header-drowpdown-text">HIPAA</p>
                    </Link>
                    <Link
                        to="/"
                        className="header-dropdown-item"
                        onClick={() => setShowDropdown(false)}>
                        <p className="header-drowpdown-text">Install Fails</p>
                    </Link>
                    <Link
                        to="/"
                        className="header-dropdown-item"
                        onClick={() => setShowDropdown(false)}>
                        <p className="header-drowpdown-text">Users</p>
                    </Link>
                    <Link
                        to="/"
                        className="header-dropdown-item"
                        onClick={() => setShowDropdown(false)}>
                        <p className="header-drowpdown-text">Reporting</p>
                    </Link>
                    <Link to="/" className="header-dropdown-item">
                        <p
                            className="header-drowpdown-text"
                            onClick={() => logout()}>
                            Logout
                        </p>
                    </Link>
                </div>
            )}
        </div>
    );
};

export default LinksCollapse;
