import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import OacForm from '../../components/admin-automations/OacForm.jsx';
import { setActiveTab } from '../../redux/slices/header-slice.js';
import SubLinks from '../../components/header/SubLinks.jsx';
import { adminLinks } from '../../config/standard-links.js';
import BackArrow from '../../components/common/BackArrow.jsx';

/**
 * Component for rendering the OAC automation page.
 * @returns {JSX.Element} OAC automation page component.
 */
const OacPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveTab('Admin'));

        return () => {
            dispatch(setActiveTab(null));
        };
    });

    return (
        <div>
            <SubLinks links={adminLinks} />
            <div className="newsc-container">
                <br />
                <br />
                <br />
                <div className="create-office-heading">
                    <BackArrow navigateTo={-1} />
                    <h2>OAC Automation</h2>
                </div>
                <br />
                <OacForm />
            </div>
        </div>
    );
};

export default OacPage;
