import ErrorMessage from '../components/common/ErrorMessage.jsx';
import BackArrow from '../components/common/BackArrow.jsx';

/**
 * Component for displaying a temporary page with a deactivation message.
 * @returns {JSX.Element} TemporaryPage component.
 */
const TemporaryPage = () => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '150px',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '50%',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}>
                    <BackArrow navigateTo={-1} />
                    <p>Go back</p>
                </div>

                <ErrorMessage message="This page has been temporarily deactivated." />
            </div>
        </div>
    );
};

export default TemporaryPage;
