import PropTypes from 'prop-types';

/**
 * SubmitButton component for rendering a submit button.
 *
 * @component
 * @example
 * // Example usage:
 * <SubmitButton label="Submit" style={{ backgroundColor: 'green', color: 'white' }} />
 *
 * @param {Object} props - The component props.
 * @param {string} props.label - The label or text content of the button (required).
 * @param {Object} [props.style] - The inline style object for the button.
 *
 * @returns {JSX.Element} - The rendered SubmitButton component.
 */
function SubmitButton({ label, style }) {
    return (
        <button type="submit" className="submit-btn" style={style}>
            {label}
        </button>
    );
}

// Prop validation
SubmitButton.propTypes = {
    label: PropTypes.string.isRequired,
    style: PropTypes.object
};

export default SubmitButton;
