import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

/**
 * React component representing a homepage navigation tile with an optional link.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {string} props.title - The title or label for the navigation tile.
 * @param {string} props.color - The color of the navigation tile's contents.
 * @param {string} props.to - The target URL if the navigation tile is a link.
 * @param {string} props.id - The unique identifier for the navigation tile.
 * @param {React.ElementType} props.icon - The icon component for the navigation tile.
 * @param {number} props.iconSize - The size of the icon within the navigation tile.
 * @returns {JSX.Element} The rendered NavTile component.
 * @example
 * // Example usage of the NavTile component
 * import NavTile from './path/to/NavTile';
 * // ...
 * <NavTile title="Office Notes" color="#3498db" to="/notes" icon={CgNotes} />
 */
const NavTile = ({ title, color, to, id, icon: IconComponent, iconSize }) => {
    if (!to) {
        return (
            <div className="homepage-icon" id={id}>
                {IconComponent && (
                    <IconComponent size={iconSize} color={color} />
                )}
                {title && (
                    <p className="homepage-icon-text" style={{ color }}>
                        {title}
                    </p>
                )}
            </div>
        );
    }

    return (
        <Link to={to} className="homepage-icon" id={id}>
            {IconComponent && <IconComponent size={iconSize} color={color} />}
            {title && (
                <p className="homepage-icon-text" style={{ color }}>
                    {title}
                </p>
            )}
        </Link>
    );
};

// Prop validation
NavTile.propTypes = {
    title: PropTypes.string,
    color: PropTypes.string,
    to: PropTypes.string,
    id: PropTypes.string,
    icon: PropTypes.elementType,
    iconSize: PropTypes.number
};

NavTile.defaultProps = {
    color: '',
    iconSize: 50,
    id: uuid()
};

export default NavTile;
