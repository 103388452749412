import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setActiveTab } from '../../redux/slices/header-slice.js';
import useOffices from '../../hooks/useOffices.js';
import OfficeNotesList from '../../components/office-notes/OfficeNotesList.jsx';
import SearchBar from '../../components/common/SearchBar.jsx';
import LoadingSpinner from '../../components/common/LoadingSpinner.jsx';
import ErrorMessage from '../../components/common/ErrorMessage.jsx';

/**
 * React component for the page displaying a list of offices who are failing on install.
 *
 * @component
 * @returns {JSX.Element} The JSX representation of the InstallFailsListPage component.
 */
const InstallFailsListPage = () => {
    const dispatch = useDispatch();

    const [searchString, setSearchString] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const { offices, totalPages, error, loading, searchInstallFailOffices } =
        useOffices();

    useEffect(() => {
        dispatch(setActiveTab('Install Fails'));

        return () => {
            dispatch(setActiveTab(null));
        };
    });

    useEffect(() => {
        const getInstallFailOffices = async () => {
            const limit = 40;
            await searchInstallFailOffices(searchString, currentPage, limit);
        };

        getInstallFailOffices();
    }, [searchString, currentPage]);

    return (
        <div className="notes-container">
            <div className="notes-header-container">
                <h2 className="notes-title">Install Fails</h2>
                <div className="notes-search-create">
                    <SearchBar
                        placeholder="Search a failing office by systematic name"
                        onChange={setSearchString}
                    />
                </div>
                <br />
                <br />
                {loading && <LoadingSpinner />}
                {error && <ErrorMessage message={error.message} />}
                {!loading && !error && offices.length < 1 ? (
                    <p>No failing offices</p>
                ) : null}
            </div>
            {offices.length > 0 && (
                <OfficeNotesList
                    offices={offices}
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                />
            )}
        </div>
    );
};

export default InstallFailsListPage;
