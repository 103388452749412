import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setActiveTab } from '../../redux/slices/header-slice.js';
import BackArrow from '../../components/common/BackArrow.jsx';
import ManuallyAddUserForm from '../../components/new-sc-vetting-cards/ManuallyAddUserForm.jsx';

/**
 * React component for the page to add a user to software platforms.
 *
 * @component
 * @returns {JSX.Element} The JSX representation of the AddUserToPlatformsPage component.
 */
const AddUserToPlatformsPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveTab('New SC'));

        return () => {
            dispatch(setActiveTab(null));
        };
    });

    return (
        <div className="create-office-container">
            <div className="create-office-heading">
                <BackArrow navigateTo={-1} />
                <h2>Manually add a user to software platforms</h2>
            </div>
            <p style={{ textAlign: 'left', marginLeft: '30px' }}>
                (This will not create a vetting card, but will simply run the
                automation that adds user to various software platforms)
            </p>
            <br />
            <ManuallyAddUserForm />
        </div>
    );
};

export default AddUserToPlatformsPage;
