import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import StatTableRow from './StatTableRow.jsx';

/**
 * Component for rendering a statistical table.
 * @param {object} props - The props object.
 * @param {string} props.title - The title of the table.
 * @param {Array<object>} props.rows - An array of objects representing the rows of the table.
 * @param {Array<string>} props.columnHeaders - An array of strings representing the column headers of the table.
 * @returns {JSX.Element} The JSX element representing the StatTable component.
 */
const StatTable = ({ title, rows, columnHeaders }) => {
    return (
        <div className="reporting-stat-table-container">
            <h3>{title}</h3>
            <table
                style={{
                    borderTop: '1px #DCDCDC solid',
                    borderLeft: '1px #DCDCDC solid'
                }}>
                <tbody>
                    <tr>
                        {columnHeaders.map((header) => (
                            <td
                                key={uuidv4()}
                                style={{
                                    borderRight: '1px #DCDCDC solid',
                                    borderBottom: '1px #DCDCDC solid',
                                    padding: '.5em',
                                    fontWeight: 'bold'
                                }}>
                                {header}
                            </td>
                        ))}
                    </tr>
                    {rows.map((row) => (
                        <StatTableRow key={uuidv4()} data={row} />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

StatTable.propTypes = {
    title: PropTypes.string,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    columnHeaders: PropTypes.arrayOf(PropTypes.string).isRequired
};

StatTable.defaultProps = {
    title: ''
};

export default StatTable;
