import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

/**
 * OfficeNotesList component for displaying a paginated list of office notes.
 *
 * @component
 * @example
 * // Example usage:
 * <OfficeNotesList offices={officeList} totalPages={5} setCurrentPage={handlePageChange} />
 *
 * @param {Object} props - The component props.
 * @param {Array} props.offices - The array of office objects to be displayed in the list (required).
 * @param {number} props.totalPages - The total number of pages for pagination (required).
 * @param {Function} props.setCurrentPage - The function to set the current page when pagination changes (required).
 *
 * @returns {JSX.Element} - The rendered OfficeNotesList component.
 */
const OfficeNotesList = ({
    offices,
    totalPages,
    setCurrentPage,
    customContainerStyles
}) => {
    const checkIsHipaaCompliant = (office) => {
        if (office.active && office.isKoc) {
            if (!office.hcPasswords && !office.hcPasswordsExempt) {
                return false;
            }
            if (!office.hcAV && !office.hcAVExempt) {
                return false;
            }
            if (!office.hcVPN && !office.hcVPNExempt) {
                return false;
            }
            if (!office.hcOS && !office.hcOSExempt) {
                return false;
            }
            return true;
        }
        return true;
    };

    return (
        <>
            <div
                className="notes-offices-cushion"
                style={{ ...customContainerStyles }}>
                <div className="notes-offices-container">
                    {offices.map((office) => (
                        <>
                            {checkIsHipaaCompliant(office) ? (
                                <Link
                                    key={uuidv4()}
                                    className="notes-single-office"
                                    to={`/office/${office.id}`}>
                                    <p>{office.officeSysName}</p>
                                </Link>
                            ) : (
                                <Link
                                    key={uuidv4()}
                                    className="notes-single-office-flagged"
                                    to={`/office/${office.id}`}>
                                    <p>{office.officeSysName}</p>
                                </Link>
                            )}
                        </>
                    ))}
                </div>
            </div>
            <div>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel="Next >"
                    previousLabel="< Previous"
                    pageCount={totalPages}
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageClassName="page-num"
                    previousLinkClassName="page-num"
                    nextLinkClassName="page-num"
                    activeLinkClassName="active-page"
                    onPageChange={(e) => setCurrentPage(e.selected + 1)}
                    disabledClassName="pagination-disabled"
                />
            </div>
        </>
    );
};

OfficeNotesList.propTypes = {
    offices: PropTypes.arrayOf(PropTypes.object).isRequired,
    totalPages: PropTypes.number.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
    customContainerStyles: PropTypes.object
};

export default OfficeNotesList;
