/**
 * Determines the Oracle API URL based on the current environment.
 *
 * @type {string}
 * @throws {Error} Throws an error if an unrecognized React App environment is detected.
 * @example
 * // Usage
 * import oracleApiUrl from './path/to/your/file';
 *
 * console.log(oracleApiUrl); // Outputs the determined Oracle API URL
 */
const oracleApiUrl = (() => {
    switch (process.env.REACT_APP_ENV) {
        case 'development': {
            // return 'https://oracle-api-testing.eassist.me'; // Development API URL
            return 'http://localhost:8080'; // Development API URL
        }
        case 'testing': {
            return 'https://oracle-api-testing.eassist.me'; // Testing API URL
        }
        case 'production': {
            return 'https://oracle-api.eassist.me'; // Production API URL
        }
        default: {
            throw new Error('Unrecognized React App Environment');
        }
    }
})();
export default oracleApiUrl;
