import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import LoadingSpinner from '../../components/common/LoadingSpinner.jsx';
import ErrorMessage from '../../components/common/ErrorMessage.jsx';
import useReporting from '../../hooks/useReporting.js';
import StatTable from '../../components/reporting/StatTable.jsx';
import { setActiveTab } from '../../redux/slices/header-slice.js';
import SubLinks from '../../components/header/SubLinks.jsx';
import { reportingLinks } from '../../config/standard-links.js';
import BackArrow from '../../components/common/BackArrow.jsx';

/**
 * Component for rendering weekly statistics page.
 * @param {Object} props - Component props.
 * @param {string} props.title - Title of the page.
 * @param {string} props.itSegment - IT segment for which statistics are displayed.
 * @returns {JSX.Element} WeeklyStatsPage component.
 */
const WeeklyStatsPage = ({ title, itSegment }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveTab('Reporting'));

        return () => {
            dispatch(setActiveTab(null));
        };
    });

    const { loading, error, tables, getWeeklyStats } = useReporting();

    const endDate = new Date();
    endDate.setUTCDate(
        endDate.getUTCDate() - ((endDate.getUTCDay() + 6) % 7) - 1
    );
    const startDate = new Date(endDate);
    startDate.setUTCDate(startDate.getUTCDate() - (startDate.getUTCDay() + 6));

    useEffect(() => {
        const fetchData = async () => {
            await getWeeklyStats(itSegment);
        };

        fetchData();
    }, [itSegment]);

    return (
        <div>
            <SubLinks links={reportingLinks} />
            <div className="newsc-container" style={{ marginBottom: '2em' }}>
                <br />
                <br />
                <br />
                <div className="create-office-heading">
                    <BackArrow navigateTo={-1} />
                    <h2>{`${title} ${
                        startDate.getUTCMonth() + 1
                    }/${startDate.getDate()}/${startDate.getUTCFullYear()}-${
                        endDate.getUTCMonth() + 1
                    }/${endDate.getDate()}/${endDate.getUTCFullYear()}`}</h2>
                </div>
                {loading && <LoadingSpinner />}
                {error && <ErrorMessage message={error.message} />}
                {tables && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                        {tables.map((table) => (
                            <StatTable
                                title={table.title}
                                key={uuidv4()}
                                rows={table.rows}
                                columnHeaders={table.headers}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

WeeklyStatsPage.propTypes = {
    title: PropTypes.string,
    itSegment: PropTypes.string.isRequired
};

WeeklyStatsPage.defaultProps = {
    title: ''
};

export default WeeklyStatsPage;
