import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import httpStatus from 'http-status';
import Select from 'react-select';
import SubmitButton from '../common/SubmitButton.jsx';
import { createNewScCardFormFields } from '../../config/form-fields.js';
import ErrorMessage from '../common/ErrorMessage.jsx';
import SuccessMessage from '../common/SuccessMessage.jsx';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import useNewScs from '../../hooks/useNewScs.js';
import createNewScValidation from '../../validation/create-new-sc-validation.js';
import { setNewScObj } from '../../redux/slices/new-sc-slice.js';
import camelCaseToSentence from '../../utils/camel-case-to-sentence.js';
import convertPhoneNumberToNationalFormat from '../../utils/convert-phone-number-to-national.js';
import { newScWorkflowTypeOptions } from '../../config/dropdown-options.js';

/**
 * CreateUpdateNewScCardForm component for rendering the form to create a new SC card.
 *
 * @component
 * @returns {JSX.Element} - The rendered CreateUpdateNewScCardForm component.
 */
const CreateUpdateNewScCardForm = ({ objectToUpdate, setShowEdit }) => {
    const dispatch = useDispatch();

    const [processResultAllOk, setProcessResultAllOk] = useState(true);
    const [specificErrorsMessage, setSpecificErrorsMessage] = useState(
        'The following failed:\n\n'
    );

    if (objectToUpdate) {
        // eslint-disable-next-line no-param-reassign
        objectToUpdate = { ...objectToUpdate };
        // eslint-disable-next-line no-param-reassign
        objectToUpdate.phone = convertPhoneNumberToNationalFormat(
            objectToUpdate.phone
        );
    }

    const {
        error,
        loading,
        createNewSc,
        updateNewSc,
        newSc,
        createNewScResult
    } = useNewScs();

    const formik = useFormik({
        initialValues: objectToUpdate || createNewScCardFormFields,
        validationSchema: createNewScValidation,
        onSubmit: async (values) => {
            if (objectToUpdate) {
                const {
                    class2Mod1,
                    class2Mod2,
                    class2Mod3,
                    class2Mod4,
                    classInvite,
                    createdAt,
                    firstAssignment,
                    id,
                    isActive,
                    orientation,
                    orientationLinkSent,
                    orientationScheduled,
                    screenshots,
                    software,
                    onboardingNotes,
                    ...formValues
                } = values;
                const updatedSc = await updateNewSc(
                    objectToUpdate.id,
                    formValues
                );
                if (updatedSc) {
                    dispatch(setNewScObj(updatedSc));
                    setShowEdit(false);
                }
            } else {
                await createNewSc(values);
            }
        }
    });

    const formatPhoneNumber = (value) => {
        if (!value) return value;

        // Remove all non-numeric characters
        const phoneNumber = value.replace(/[^\d]/g, '');

        const phoneNumberLength = phoneNumber.length;

        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }

        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
            3,
            6
        )}-${phoneNumber.slice(6, 10)}`;
    };

    useEffect(() => {
        /**
         * Checks the status codes of the result object and handles proper messaging
         */
        const handleStatusCodes = () => {
            setSpecificErrorsMessage('The following failed:\n\n');

            if (createNewScResult) {
                // Grab status codes
                const statusCodes = Object.values(createNewScResult);
                if (statusCodes.every((code) => code === 200)) {
                    setProcessResultAllOk(true);
                } else {
                    setProcessResultAllOk(false);

                    const keys = Object.keys(createNewScResult);
                    keys.forEach((key) => {
                        if (createNewScResult[key] !== 200) {
                            // Converts result codes to user friendly text and sets to message
                            setSpecificErrorsMessage(
                                `${specificErrorsMessage}${camelCaseToSentence(
                                    key
                                )}: ${
                                    httpStatus[
                                        createNewScResult[key].toString()
                                    ]
                                } ${
                                    createNewScResult[key] ===
                                    httpStatus.CONFLICT
                                        ? '(User already exists)'
                                        : ''
                                }\n`
                            );
                        }
                    });
                }
            }
        };

        handleStatusCodes();
    }, [createNewScResult]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="newsc-form-container">
                    <label htmlFor="firstName" className="form-label">
                        First Name
                    </label>
                    <input
                        id="firstName"
                        type="text"
                        className="newsc-input"
                        placeholder="e.g. John"
                        {...formik.getFieldProps('firstName')}
                    />
                    {formik.touched.firstName && formik.errors.firstName ? (
                        <ErrorMessage
                            autofocus
                            message={formik.errors.firstName}
                        />
                    ) : null}

                    <label htmlFor="lastName" className="form-label">
                        Last Name
                    </label>
                    <input
                        id="lastName"
                        type="text"
                        className="newsc-input"
                        placeholder="e.g. Doe"
                        {...formik.getFieldProps('lastName')}
                    />
                    {formik.touched.lastName && formik.errors.lastName ? (
                        <ErrorMessage message={formik.errors.lastName} />
                    ) : null}

                    <label htmlFor="eAssistEmail" className="form-label">
                        eAssist Email
                    </label>
                    <input
                        id="eAssistEmail"
                        type="text"
                        className="newsc-input"
                        placeholder="eAssist email e.g. john.doe@eassist.me"
                        {...formik.getFieldProps('eAssistEmail')}
                    />
                    {formik.touched.eAssistEmail &&
                    formik.errors.eAssistEmail ? (
                        <ErrorMessage message={formik.errors.eAssistEmail} />
                    ) : null}

                    <label htmlFor="personalEmail" className="form-label">
                        Personal Email
                    </label>
                    <input
                        id="personalEmail"
                        type="text"
                        className="newsc-input"
                        placeholder="e.g. john.doe@gmail.com"
                        {...formik.getFieldProps('personalEmail')}
                    />
                    {formik.touched.personalEmail &&
                    formik.errors.personalEmail ? (
                        <ErrorMessage message={formik.errors.personalEmail} />
                    ) : null}

                    <label htmlFor="phone" className="form-label">
                        Phone Number
                    </label>
                    <input
                        id="phone"
                        type="text"
                        className="newsc-input"
                        placeholder="e.g. (801) 123-1234"
                        {...formik.getFieldProps('phone')}
                        onChange={(e) => {
                            formik.setFieldValue(
                                'phone',
                                formatPhoneNumber(e.target.value)
                            );
                        }}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                        <ErrorMessage message={formik.errors.phone} />
                    ) : null}

                    <label htmlFor="workflowType" className="form-label">
                        New SC Workflow Type
                    </label>
                    <Select
                        id="workflowType"
                        name="workflowType"
                        options={newScWorkflowTypeOptions}
                        value={newScWorkflowTypeOptions.find(
                            (option) =>
                                option.value === formik.values.workflowType
                        )}
                        onChange={(selectedOption) =>
                            formik.setFieldValue(
                                'workflowType',
                                selectedOption ? selectedOption.value : null
                            )
                        }
                    />
                    {formik.touched.workflowType &&
                    formik.errors.workflowType ? (
                        <ErrorMessage message={formik.errors.workflowType} />
                    ) : null}

                    <label htmlFor="scNotes" className="form-label">
                        Notes
                    </label>
                    <textarea
                        id="scNotes"
                        className="newsc-input"
                        {...formik.getFieldProps('scNotes')}
                    />
                    {formik.touched.scNotes && formik.errors.scNotes ? (
                        <ErrorMessage message={formik.errors.scNotes} />
                    ) : null}

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-evenly'
                        }}>
                        <SubmitButton
                            label={objectToUpdate ? 'Save' : 'Create'}
                        />
                        {objectToUpdate && (
                            <button
                                className="submit-btn"
                                onClick={() => setShowEdit(false)}>
                                Cancel
                            </button>
                        )}
                    </div>
                </div>
            </form>
            <br />
            <br />
            {loading && <LoadingSpinner />}
            {Object.keys(formik.errors).length !== 0 &&
                Object.keys(formik.touched).length !== 0 && (
                    <ErrorMessage message="There are form errors above, please fix." />
                )}
            {error && <ErrorMessage message={error.message} />}
            {newSc && objectToUpdate && (
                <SuccessMessage message="New SC card has been updated!" />
            )}
            {createNewScResult && processResultAllOk && (
                <SuccessMessage message="New SC card has been created!" />
            )}
            {createNewScResult && !processResultAllOk && (
                <ErrorMessage message={specificErrorsMessage} />
            )}
        </>
    );
};

CreateUpdateNewScCardForm.propTypes = {
    objectToUpdate: PropTypes.object,
    setShowEdit: PropTypes.func
};

export default CreateUpdateNewScCardForm;
