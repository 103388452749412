/**
 * Custom error class representing user-friendly errors.
 * These error messages can be directly displayed to the user in any component.
 * When an error needs to be displayed to the user, use this class.
 * When catching random system errors, the default message can be used.
 *
 * @class UserFriendlyError
 * @extends {Error}
 * @param {string} [message] - The error message. Defaults to a generic system error message.
 * @property {string} name - The name of the error class ('UserFriendlyError').
 * @throws {UserFriendlyError} Throws an instance of UserFriendlyError with the specified message.
 * @example
 * // Example usage:
 * try {
 *   throw new UserFriendlyError('This is a user-friendly error message');
 * } catch (error) {
 *   console.error(error.name);    // UserFriendlyError
 *   console.error(error.message); // This is a user-friendly error message
 * }
 */
class UserFriendlyError extends Error {
    constructor(
        message = 'A system error occured. Please try again and if unsuccessful, report the bug to the Autobots team.'
    ) {
        super(message);
        this.name = 'UserFriendlyError';
    }
}

export default UserFriendlyError;
