import {
    green,
    lightBlue,
    lightPink,
    purple,
    red,
    yellow
} from './standard-colors.js';

/**
 * Array containing standard colors for charts.
 * @module chartColors
 */
const chartColors = [red, yellow, purple, green, lightBlue, lightPink];

export default chartColors;
