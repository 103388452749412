import { useFormik } from 'formik';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import SubmitButton from '../common/SubmitButton.jsx';
import { createUserFormFields } from '../../config/form-fields.js';
import ErrorMessage from '../common/ErrorMessage.jsx';
import SuccessMessage from '../common/SuccessMessage.jsx';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import useUsers from '../../hooks/useUsers.js';
import createUserValidation from '../../validation/create-user-validation.js';
import { userRoleOptions } from '../../config/dropdown-options.js';

/**
 * CreateUpdateUserForm component for rendering the form to create a new user.
 *
 * @component
 * @returns {JSX.Element} - The rendered CreateUpdateUserForm component.
 */
const CreateUpdateUserForm = ({ objectToUpdate }) => {
    let objectToUpdateModified;
    if (objectToUpdate) {
        // Convert object role from object to id
        objectToUpdateModified = { ...objectToUpdate };
        objectToUpdateModified.role = objectToUpdateModified.role.id;
    }

    const { error, loading, createUser, updateUser, user } = useUsers();

    const formik = useFormik({
        initialValues: objectToUpdateModified || createUserFormFields,
        validationSchema: createUserValidation,
        onSubmit: async (values) => {
            if (objectToUpdateModified) {
                await updateUser(
                    objectToUpdateModified.id,
                    values,
                    objectToUpdateModified
                );
            } else {
                await createUser(values);
            }
        }
    });

    useEffect(() => {
        /**
         * Checks if the error passed back from submission is because the email is taken.
         * If it is, sets the proper field error
         */
        const callCheckEmail = async () => {
            if (error && error.message === 'User email already exists') {
                formik.setFieldError(
                    'email',
                    'That email is taken, please select another'
                );
            }
        };

        callCheckEmail();
    }, [error]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="newsc-form-container">
                    <label htmlFor="email" className="form-label">
                        Email
                    </label>
                    <input
                        id="email"
                        type="text"
                        className="newsc-input"
                        placeholder="eAssist email e.g. john.doe@eassist.me"
                        {...formik.getFieldProps('email')}
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <ErrorMessage message={formik.errors.email} />
                    ) : null}

                    <label htmlFor="firstName" className="form-label">
                        First Name
                    </label>
                    <input
                        id="firstName"
                        type="text"
                        className="newsc-input"
                        placeholder="e.g. John"
                        {...formik.getFieldProps('firstName')}
                    />
                    {formik.touched.firstName && formik.errors.firstName ? (
                        <ErrorMessage
                            autofocus
                            message={formik.errors.firstName}
                        />
                    ) : null}
                    <label htmlFor="lastName" className="form-label">
                        Last Name
                    </label>
                    <input
                        id="lastName"
                        type="text"
                        className="newsc-input"
                        placeholder="e.g. Doe"
                        {...formik.getFieldProps('lastName')}
                    />
                    {formik.touched.lastName && formik.errors.lastName ? (
                        <ErrorMessage message={formik.errors.lastName} />
                    ) : null}
                    <label htmlFor="role" className="form-label">
                        Role
                    </label>
                    <Select
                        id="role"
                        name="role"
                        options={userRoleOptions}
                        value={userRoleOptions.find(
                            (option) => option.value === formik.values.role
                        )}
                        onChange={(selectedOption) =>
                            formik.setFieldValue(
                                'role',
                                selectedOption ? selectedOption.value : null
                            )
                        }
                    />
                    {formik.touched.role && formik.errors.role ? (
                        <ErrorMessage message={formik.errors.role} />
                    ) : null}
                    <br />
                    <br />
                    <SubmitButton label={objectToUpdate ? 'Save' : 'Create'} />
                </div>
            </form>
            <br />
            <br />
            {loading && <LoadingSpinner />}
            {Object.keys(formik.errors).length !== 0 && (
                <ErrorMessage message="There are form errors above, please fix." />
            )}
            {error && <ErrorMessage message={error.message} />}
            {user && objectToUpdate ? (
                <SuccessMessage message="User has been updated!" />
            ) : null}
            {user && !objectToUpdate ? (
                <SuccessMessage message="User has been created!" />
            ) : null}
        </>
    );
};

CreateUpdateUserForm.propTypes = {
    objectToUpdate: PropTypes.object
};

export default CreateUpdateUserForm;
