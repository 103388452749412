/**
 * Initial form fields for creating an office.
 *
 * @type {Object}
 * @property {number} hubId - the hub ID of the office.
 * @property {string} officeName - The name of the office.
 * @property {string} officeSysName - The systematic name of the office.
 * @property {string} officeEmail - The email address of the office.
 * @property {string} itName - The name of the IT contact.
 * @property {string} itPhoneNumber - The phone number of the IT contact.
 * @property {string} itEmail - The email address of the IT contact.
 * @property {string} connectionSoftware - The selected connection software.
 * @property {string} dentalSoftware - The dental software used.
 * @property {string} dsUsername - The username for dental software.
 * @property {string} dsPassword - The password for dental software.
 * @property {string} dsUsername2 - Additional username for dental software.
 * @property {string} dsPassword2 - Additional password for dental software.
 * @property {string} xrays - Information about X-rays.
 * @property {string} eClaims - Information about electronic claims.
 * @property {string} eClaimsUsername - The username for electronic claims.
 * @property {string} eClaimsPassword - The password for electronic claims.
 * @property {string} eClaimsInstructions - Additional instructions for electronic claims.
 * @property {string} practiceBoosterUsername - Username for PracticeBooster.
 * @property {string} practiceBoosterPassword - Password for PracticeBooster.
 * @property {string} pdfPrinter - Information about PDF printer.
 * @property {string} attachments - Information about attachments.
 * @property {string} phoneNumber - The phone number of the office.
 * @property {string} production - Information about production.
 * @property {string} installTech - The email of the installation technician.
 * @property {Date|null} installDate - The date of installation (null if not applicable).
 * @property {number} numOfDBConnections - Number of database connections.
 * @property {number} numOfIVConnections - Number of IV connections.
 * @property {number} numOfPPConnections - Number of PP connections.
 * @property {number} numOfFSConnections - Number of FS connections.
 * @property {string} eobPath - The path for EOB (Explanation of Benefits).
 * @property {string} notes - Additional notes.
 * @property {Object} uniqueVPN - Object containing unique VPN information.
 * @property {boolean} uniqueVPN.hasUniqueVPN - Indicates if the office has a unique VPN.
 * @property {boolean} uniqueVPN.addendumOnFile - Indicates if there is an addendum on file for unique VPN.
 * @property {string} firewallInfo - Information about the firewall.
 * @property {string} backupTimes - Information about backup times.
 * @property {string} poc - Point of contact information.
 * @property {boolean} isKoc - Indicates if the office is a KOC (Kingdom Orthodontics Co).
 * @property {string} dsURL - The URL for dental software.
 * @property {boolean} isCopilotOffice - Indicates if it is a Copilot office.
 * @property {string} copilotServerName - The Copilot server name.
 * @property {string} copilotServerUsername - The Copilot server username.
 * @property {string} copilotServerPassword - The Copilot server password.
 * @property {string} copilotNotes - Additional notes for Copilot.
 */
export const createOfficeFormFields = {
    hubId: null,
    officeName: '',
    officeSysName: '',
    officeEmail: '',
    itName: '',
    itPhoneNumber: '',
    itEmail: '',
    connectionSoftware: '',
    dentalSoftware: '',
    dsUsername: '',
    dsPassword: '',
    dsUsername2: '',
    dsPassword2: '',
    xrays: '',
    eClaims: '',
    eClaimsUsername: '',
    eClaimsPassword: '',
    eClaimsInstructions: '',
    practiceBoosterUsername: '',
    practiceBoosterPassword: '',
    pdfPrinter: '',
    attachments: '',
    phoneNumber: '',
    production: '',
    installTech: '',
    installDate: null,
    numOfDBConnections: 0,
    numOfIVConnections: 0,
    numOfPPConnections: 0,
    numOfFSConnections: 0,
    eobPath: '',
    notes: '',
    uniqueVPN: {
        hasUniqueVPN: false,
        addendumOnFile: false
    },
    firewallInfo: '',
    backupTimes: '',
    poc: '',
    isKoc: false,
    dsURL: '',
    isCopilotOffice: false,
    copilotServerName: 'N/A',
    copilotServerUsername: 'N/A',
    copilotServerPassword: 'N/A',
    copilotNotes: 'N/A',
    nextCloudPassword: ''
};

/**
 * Initial form fields for running the OAC process (Office Assignment Change).
 *
 * @type {Object}
 * @property {string} scFullName - The full name of the SC (Success Consultant).
 * @property {string} scEassistEmail - The eassist email of the SC.
 * @property {string} officeSystematicName - The office systematic name to assign them to.
 */
export const runOacFormFields = {
    scFullName: '',
    scEassistEmail: '',
    officeSystematicName: ''
};

/**
 * Initial form fields for running the OAR process (Office Assignment Removal).
 *
 * @type {Object}
 * @property {string} scFullName - The full name of the SC (Success Consultant).
 * @property {string} scEassistEmail - The eassist email of the SC.
 * @property {string} officeSystematicName - The office systematic name to assign them to.
 */
export const runOarFormFields = {
    scFullName: '',
    scEassistEmail: '',
    officeSystematicName: ''
};

/**
 * Initial form fields for running the AOAR process (All Office Assignment Removed).
 *
 * @type {Object}
 * @property {string} scFullName - The full name of the SC (Success Consultant).
 * @property {string} scEassistEmail - The eassist email of the SC.
 * @property {string} scPersonalEmail - The personal email of the SC.
 */
export const runAoarFormFields = {
    scFullName: '',
    scEassistEmail: '',
    scPersonalEmail: ''
};

/**
 * Initial form fields for running the User Data Transfer process.
 *
 * @type {Object}
 * @property {string} userEassistEmail - The eassist email of the user who's data is being transferred.
 * @property {string} transferToEassistEmail - The eassist email of the user who will be receiving the data.
 */
export const runUserDataTransferFormFields = {
    userEassistEmail: '',
    transferToEassistEmail: ''
};

/**
 * Initial form fields for running the User Removal process.
 *
 * @type {Object}
 * @property {string} scFullName - The full name of the user.
 * @property {string} scEassistEmail - The eassist email of the user.
 * @property {string} scPersonalEmail - The personal email of the user.
 */
export const runUserRemovalFormFields = {
    scFullName: '',
    scEassistEmail: '',
    scPersonalEmail: ''
};

/**
 * Initial form fields for creating a user.
 *
 * @type {Object}
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} email
 * @property {string} role
 */
export const createUserFormFields = {
    firstName: '',
    lastName: '',
    email: '',
    role: ''
};

/**
 * Initial form fields for deleting a user.
 *
 * @type {Object}
 * @property {string} userId
 */
export const deleteUserFormFields = {
    userId: ''
};

/**
 * Initial form fields for creating a computer.
 *
 * @type {Object}
 * @property {string} computerName
 * @property {string} computerSysName
 * @property {string} OS
 * @property {boolean} avInstalled
 * @property {string} avName
 * @property {string} location
 * @property {string} syncroStatus
 * @property {string} domain
 * @property {string} note
 * @property {boolean} isITOnly
 * @property {string} installTech
 * @property {Date} installDate
 * @property {number} splashtopId
 */
export const createComputerFormFields = {
    computerName: '',
    computerSysName: '',
    OS: '',
    avInstalled: false,
    avName: '',
    location: '',
    syncroStatus: '',
    domain: '',
    note: '',
    isITOnly: false,
    installTech: '',
    installDate: null,
    splashtopId: null
};

/**
 * Initial form fields for creating a credential.
 *
 * @type {Object}
 * @property {string} username
 * @property {string} password
 * @property {string} domain
 * @property {string} scannerModel
 */
export const createCredentialFormFields = {
    username: '',
    password: '',
    domain: '',
    scannerModel: '',
    isITOnly: false
};

/**
 * Initial form fields for sending a HIPAA OS fail email.
 *
 * @type {Object}
 * @property {string} officeId
 */
export const sendOsFailEmailFormFields = {
    officeId: ''
};

/**
 * Initial form fields for creating a new SC card.
 *
 * @type {Object}
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} eAssistEmail
 * @property {string} personalEmail
 * @property {string} phone
 * @property {string} scNotes
 * @property {string} workflowType
 */
export const createNewScCardFormFields = {
    firstName: '',
    lastName: '',
    eAssistEmail: '',
    personalEmail: '',
    phone: '',
    scNotes: '',
    workflowType: ''
};

/**
 * Initial form fields for running the add user to software platforms process.
 *
 * @type {Object}
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} eAssistEmail - The eassist email of the user.
 * @property {string} personalEmail - The personal email of the user.
 * @property {string} phoneNumber - The phone number of the user.
 */
export const addUserToSoftwarePlatformsFormFields = {
    firstName: '',
    lastName: '',
    eassistEmail: '',
    personalEmail: '',
    phoneNumber: ''
};

/**
 * Initial form fields for running the query process.
 *
 * @type {Object}
 * @property {string} itSegment - The IT segment.
 * @property {string} timePeriod - The time period for the query.
 * @property {Date|null} customStartDate - The custom start date for the query.
 * @property {Date|null} customEndDate - The custom end date for the query.
 * @property {string} userId - The user ID associated with the query.
 * @property {string[]} ticketTags - An array of ticket tags for the query.
 * @property {string[]} ticketTypes - An array of ticket types for the query.
 */
export const runQueryFormFields = {
    itSegment: '',
    timePeriod: '',
    customStartDate: null,
    customEndDate: null,
    userId: '',
    ticketTags: [],
    ticketTypes: []
};
