/**
 * Validates the office systematic name is in the following format: state.city.lastName
 * where state is a two letter abbreviation, city and last name cannot have any spaces (if mutliple words, will use _).
 * For example, ut.roy.anderson or ks.kansas_city.mahomes
 */
export const validOfficeSysName = /^[a-z]{2}\.[a-z_\d_'&()]+\.[a-z_\d_'&()]+$/;

/**
 * Validates the computer systematic name is in the following format: state.city.lastName.computerDescription
 * where state is a two letter abbreviation, city, last name, and computer description cannot have any spaces (if mutliple words, will use _).
 * For example, ut.roy.anderson.server1 or ks.kansas_city.mahomes.scanner
 */
export const validComputerSysName =
    // /^[a-zA-Z]{2}\.[a-zA-Z]+(\s[a-zA-Z]+)*\.[a-zA-Z]+\.[a-zA-Z]+(?:_[a-zA-Z]+)*$/;
    /^[a-z]{2}\.[a-z_\d_'&()-]+\.[a-z_\d_'-]+\.[a-zA-Z_\d_'&()-]+$/;

/**
 * Validates an email is an eassist.me domain
 */
export const validEassistEmail = /^[a-zA-Z0-9._%+-]+@eassist\.me$/;

/**
 * Validates a password is hipaa compliant (at least one special character, number, lowercase, and uppercase character, at least 8 characters in length)
 */
export const hipaaCompliantPassword =
    /^(?=.*[\W_])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

/**
 * Validates a string is an valid IPv4 address
 */
export const validIpv4Address = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;

/**
 * Validates a string is an valid us phone number in the following format: (801) 123-1234
 */
export const validUsPhoneNumber = /^\(\d{3}\) \d{3}-\d{4}$/;

/**
 * Validates the phone number is in the following format: +18011231234
 */
export const validInternationalPhoneNumber = /^\+\d{11}$/;

/**
 * Validates a string is an valid mongodb ObjectId
 */
export const validObjectId = /^[0-9a-fA-F]{24}$/;
