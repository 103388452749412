/**
 * React component for displaying a loading spinner.
 *
 * @component
 * @returns {JSX.Element} The rendered LoadingSpinner component.
 */
const LoadingSpinner = () => {
    return (
        <div className="loading-container">
            <div className="loading-container">
                <div className="lds-dual-ring" />
            </div>
        </div>
    );
};

export default LoadingSpinner;
