import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setActiveTab } from '../../redux/slices/header-slice.js';
import CreateUpdateOfficeForm from '../../components/office-notes/CreateUpdateOfficeForm.jsx';
import BackArrow from '../../components/common/BackArrow.jsx';

/**
 * React component for the page to create a new office.
 *
 * @component
 * @returns {JSX.Element} The JSX representation of the CreateOfficePage component.
 */
const CreateOfficePage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveTab('Notes'));

        return () => {
            dispatch(setActiveTab(null));
        };
    });

    return (
        <div className="create-office-container">
            <div className="create-office-heading">
                <BackArrow navigateTo={-1} />
                <h2>Create an Office</h2>
            </div>
            <CreateUpdateOfficeForm />
        </div>
    );
};

export default CreateOfficePage;
