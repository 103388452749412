import * as Yup from 'yup';
import { validObjectId } from './custom-validations.js';

/**
 * Yup validation schema for deleting a user.
 */
const deleteUserValidation = Yup.object({
    userId: Yup.string()
        .trim('Remove any leading or trailing spaces')
        .required('Required')
        .matches(validObjectId, {
            message: 'Must be a valid object id'
        })
});

export default deleteUserValidation;
