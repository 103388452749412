/**
 * Configures and creates a Redux store with the specified reducers.
 * @returns {Object} Redux store.
 */

import { configureStore } from '@reduxjs/toolkit';
import headerSliceReducer from './slices/header-slice.js';
import officeSliceReducer from './slices/office-slice.js';
import newScSliceReducer from './slices/new-sc-slice.js';

const store = configureStore({
    reducer: {
        header: headerSliceReducer,
        office: officeSliceReducer,
        newSc: newScSliceReducer
    }
});

export default store;
