/**
 * Redux slice responsible for managing office-related state including computers and office object.
 * @module officeSlice
 */

/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

// Initial state of the office slice
const initialState = {
    officeObj: {},
    computers: []
};

/**
 * Slice containing reducers for managing office-related state.
 */
export const officeSlice = createSlice({
    name: 'office',
    initialState,
    reducers: {
        /**
         * Action creator to set the list of computers.
         * @param {Object} state - Current state of the office slice.
         * @param {Array} action - Payload containing the list of computers.
         */
        setComputers: (state, action) => {
            const computers = [...action.payload];
            const sortedComputers = computers.sort((x) => {
                // eslint-disable-next-line no-nested-ternary
                return x.retired === true ? 0 : x ? -1 : 1;
            });
            state.computers = sortedComputers;
        },
        /**
         * Action creator to set the office object.
         * @param {Object} state - Current state of the office slice.
         * @param {Object} action - Payload containing the office object.
         */
        setOfficeObj: (state, action) => {
            state.officeObj = action.payload;
        },
        addComputer: (state, action) => {
            state.computers = [...state.computers, action.payload];
        },
        removeComputer: (state, action) => {
            state.computers = state.computers.filter(
                (computer) => computer.id !== action.payload
            );
        },
        addCredential: (state, action) => {
            const computerIndex = state.computers.findIndex(
                (x) => x.id === action.payload.computerId
            );
            state.computers[computerIndex].credentials = [
                ...state.computers[computerIndex].credentials,
                action.payload
            ];
        },
        removeCredential: (state, action) => {
            const computerIndex = state.computers.findIndex(
                (x) => x.id === action.payload.computerId
            );
            state.computers[computerIndex].credentials = state.computers[
                computerIndex
            ].credentials.filter(
                (credential) => credential.id !== action.payload.credentialId
            );
        },
        updateComputerRetiredState: (state, action) => {
            const computerIndex = state.computers.findIndex(
                (x) => x.id === action.payload.computerId
            );
            const updatedComputer = state.computers[computerIndex];
            updatedComputer.retired = action.payload.retiredState;
            updatedComputer.dateRetired = action.payload.dateRetired;
            updatedComputer.retiredBy = action.payload.retiredBy;

            if (action.payload.retiredState === false) {
                const computers = state.computers.filter(
                    (computer) => computer.id !== action.payload.computerId
                );
                const sortedComputers = [updatedComputer, ...computers];
                state.computers = sortedComputers;
            } else {
                const computers = state.computers.filter(
                    (computer) => computer.id !== action.payload.computerId
                );
                computers.push(updatedComputer);
                state.computers = computers;
            }
        },
        updateComputer: (state, action) => {
            const computerIndex = state.computers.findIndex(
                (x) => x.id === action.payload.id
            );
            if (
                !state.computers[computerIndex].retired &&
                action.payload.retired
            ) {
                const computers = state.computers.filter(
                    (computer) => computer.id !== action.payload.id
                );
                computers.push(action.payload);
                state.computers = computers;
            } else {
                state.computers[computerIndex] = action.payload;
            }
        },
        updateCredential: (state, action) => {
            const computerIndex = state.computers.findIndex(
                (x) => x.id === action.payload.computerId
            );
            const credentialIndex = state.computers[
                computerIndex
            ].credentials.findIndex((x) => x.id === action.payload.id);
            state.computers[computerIndex].credentials[credentialIndex] =
                action.payload;
        },
        updateOfficeSecureProtocol: (state, action) => {
            state.officeObj.inSecureProtocol = action.payload;
        },
        updateOfficeActiveState: (state, action) => {
            state.officeObj.active = action.payload;
        },
        updateOfficeSysName: (state, action) => {
            state.officeObj.officeSysName = action.payload;
        }
    }
});

/**
 * Selector function to retrieve the list of computers from the state.
 * @param {Object} state - Redux state containing the office slice.
 * @returns {Array} The list of computers.
 */
export const selectComputers = (state) => state.office.computers;

/**
 * Selector function to retrieve the office object from the state.
 * @param {Object} state - Redux state containing the office slice.
 * @returns {Object} The office object.
 */
export const selectOfficeObj = (state) => state.office.officeObj;

export const {
    setComputers,
    addComputer,
    removeComputer,
    addCredential,
    removeCredential,
    updateComputerRetiredState,
    updateComputer,
    updateCredential,
    setOfficeObj,
    updateOfficeSecureProtocol,
    updateOfficeActiveState,
    updateOfficeSysName
} = officeSlice.actions;

const officeSliceReducer = officeSlice.reducer;

export default officeSliceReducer;
