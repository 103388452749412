import * as Yup from 'yup';

/**
 * Yup validation schema for running a query.
 *
 * @constant
 * @type {object}
 * @property {Yup.StringSchema} itSegment - Validation for the IT segment.
 * @property {Yup.StringSchema} timePeriod - Validation for the time period.
 * @property {Yup.DateSchema} customStartDate - Validation for the custom start date.
 * @property {Yup.DateSchema} customEndDate - Validation for the custom end date.
 * @property {Yup.StringSchema} userId - Validation for the user ID.
 * @property {Yup.StringSchema} teamId - Validation for the team ID.
 * @property {Yup.ArraySchema} ticketTags - Validation for the ticket tags.
 * @property {Yup.ArraySchema} ticketTypes - Validation for the ticket types.
 */
const runQueryValidation = Yup.object({
    itSegment: Yup.string()
        .trim('Remove any leading or trailing spaces')
        .lowercase(),
    timePeriod: Yup.string()
        .trim('Remove any leading or trailing spaces')
        .required('Required'),
    customStartDate: Yup.date().nullable(),
    customEndDate: Yup.date().nullable(),
    userId: Yup.string().trim('Remove any leading or trailing spaces'),
    ticketTags: Yup.array(),
    ticketTypes: Yup.array()
});

export default runQueryValidation;
