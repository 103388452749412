import * as Yup from 'yup';
import { validEassistEmail, validOfficeSysName } from './custom-validations.js';

/**
 * Yup validation schema for running the OAC (Office Assignment Change) process automation.
 *
 * @constant
 * @type {object}
 * @property {Yup.StringSchema} scFullName - Validation for the SC (sucess consultant) name.
 * @property {Yup.StringSchema} officeSystematicName - Validation for the office systematic name.
 * @property {Yup.StringSchema} scEassistEmail - Validation for the SC eassist email address.
 */
export const runOacValidation = Yup.object({
    scFullName: Yup.string()
        .trim('Remove any leading or trailing spaces')
        .required('Required'),
    officeSystematicName: Yup.string()
        .required('Required')
        .matches(validOfficeSysName, {
            message:
                'Must match the following format: ut.shire.baggins, representing the state, city, and doctor last name. If multiple words, should be separated by underscore e.g. ut.the_shire.baggins. Should be all lowercase.'
        })
        .trim('Remove any leading or trailing spaces'),
    scEassistEmail: Yup.string()
        .email('Must be a valid email address')
        .trim('Remove any leading or trailing spaces')
        .required('Required')
        .matches(validEassistEmail, {
            message: 'Must be a valid eAssist Email (@eassist.me)'
        })
});

/**
 * Yup validation schema for running the OAR (Office Assignment Removal) process automation.
 *
 * @constant
 * @type {object}
 * @property {Yup.StringSchema} scFullName - Validation for the SC (sucess consultant) name.
 * @property {Yup.StringSchema} officeSystematicName - Validation for the office systematic name.
 * @property {Yup.StringSchema} scEassistEmail - Validation for the SC eassist email address.
 */
export const runOarValidation = Yup.object({
    scFullName: Yup.string()
        .trim('Remove any leading or trailing spaces')
        .required('Required'),
    officeSystematicName: Yup.string()
        .required('Required')
        .matches(validOfficeSysName, {
            message:
                'Must match the following format: ut.shire.baggins, representing the state, city, and doctor last name. If multiple words, should be separated by underscore e.g. ut.the_shire.baggins. Should be all lowercase.'
        })
        .trim('Remove any leading or trailing spaces'),
    scEassistEmail: Yup.string()
        .email('Must be a valid email address')
        .trim('Remove any leading or trailing spaces')
        .required('Required')
        .matches(validEassistEmail, {
            message: 'Must be a valid eAssist Email (@eassist.me)'
        })
});

/**
 * Yup validation schema for running the AOAR (All Office Assignments Removed) process automation.
 *
 * @constant
 * @type {object}
 * @property {Yup.StringSchema} scFullName - Validation for the SC (sucess consultant) name.
 * @property {Yup.StringSchema} scEassistEmail - Validation for the SC eassist email address.
 * @property {Yup.StringSchema} scPersonalEmail - Validation for the SC personal email address.
 */
export const runAoarValidation = Yup.object({
    scFullName: Yup.string()
        .trim('Remove any leading or trailing spaces')
        .required('Required'),
    scEassistEmail: Yup.string()
        .email('Must be a valid email address')
        .trim('Remove any leading or trailing spaces')
        .required('Required')
        .matches(validEassistEmail, {
            message: 'Must be a valid eAssist Email (@eassist.me)'
        }),
    scPersonalEmail: Yup.string()
        .email('Must be a valid email address')
        .trim('Remove any leading or trailing spaces')
        .required('Required')
});

/**
 * Yup validation schema for running the User Data Transfer process automation.
 *
 * @constant
 * @type {object}
 * @property {Yup.StringSchema} userEassistEmail - Validation for the user eassist email address who's data is transferring.
 * @property {Yup.StringSchema} transferToEassistEmail - Validation for the user eassist email address who's receiving the data.
 */
export const runUserDataTransferValidation = Yup.object({
    userEassistEmail: Yup.string()
        .email('Must be a valid email address')
        .trim('Remove any leading or trailing spaces')
        .required('Required')
        .matches(validEassistEmail, {
            message: 'Must be a valid eAssist Email (@eassist.me)'
        }),
    transferToEassistEmail: Yup.string()
        .email('Must be a valid email address')
        .trim('Remove any leading or trailing spaces')
        .required('Required')
        .matches(validEassistEmail, {
            message: 'Must be a valid eAssist Email (@eassist.me)'
        })
});

/**
 * Yup validation schema for running the User Removal process automation.
 *
 * @constant
 * @type {object}
 * @property {Yup.StringSchema} scFullName - Validation for the user name.
 * @property {Yup.StringSchema} scEassistEmail - Validation for the user eassist email address.
 * @property {Yup.StringSchema} scPersonalEmail - Validation for the user personal email address.
 */
export const runUserRemovalValidation = Yup.object({
    scFullName: Yup.string()
        .trim('Remove any leading or trailing spaces')
        .required('Required'),
    scEassistEmail: Yup.string()
        .email('Must be a valid email address')
        .trim('Remove any leading or trailing spaces')
        .required('Required')
        .matches(validEassistEmail, {
            message: 'Must be a valid eAssist Email (@eassist.me)'
        }),
    scPersonalEmail: Yup.string()
        .email('Must be a valid email address')
        .trim('Remove any leading or trailing spaces')
        .required('Required')
});
