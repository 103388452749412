import PropTypes from 'prop-types';

/**
 * SearchBar component for displaying an input field for searching.
 *
 * @component
 * @example
 * // Example usage:
 * <SearchBar placeholder="Type to search" onChange={(value) => handleSearchChange(value)} />
 *
 * @param {Object} props - The component props.
 * @param {string} [props.placeholder='Search'] - The placeholder text for the search input.
 * @param {Function} props.onChange - The function to be executed when the input value changes.
 *
 * @returns {JSX.Element} - The rendered SearchBar component.
 */
const SearchBar = ({ placeholder, onChange }) => {
    return (
        <input
            className="search-input"
            placeholder={placeholder}
            onChange={(e) => onChange(e.target.value)}
        />
    );
};

SearchBar.propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

SearchBar.defaultProps = {
    placeholder: 'Search'
};

export default SearchBar;
