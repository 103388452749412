import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { selectNewScObj } from '../../redux/slices/new-sc-slice.js';
import useNewScs from '../../hooks/useNewScs.js';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import ErrorMessage from '../common/ErrorMessage.jsx';
import SuccessMessage from '../common/SuccessMessage.jsx';

/**
 * Component for tracking software details of an SC card.
 *
 * @returns {JSX.Element} JSX element representing the Software Tracker.
 */
const SoftwareTracker = () => {
    const newScObj = useSelector(selectNewScObj);

    const [softwareTracker, setSoftwareTracker] = useState(newScObj.software);
    // state variable that determines if the other text input should appear
    const [other, setOther] = useState(
        softwareTracker ? softwareTracker[28].value : ''
    );

    // Custom hook for handling SC card operations
    const { error, loading, updateNewSc, newSc } = useNewScs();

    /**
     * Handler function for checkbox change.
     *
     * @param {Object} x - The software object.
     */
    const handleChange = (x) => {
        setSoftwareTracker(
            softwareTracker.map((temp) => {
                if (temp.name === x.name && temp.value === false) {
                    if (x.name === 'other') {
                        setOther(true);
                    }
                    return { ...temp, value: true };
                }
                if (temp.name === x.name && temp.value === true) {
                    if (x.name === 'other') {
                        setOther(false);
                    }
                    return { ...temp, value: false };
                }
                return temp;
            })
        );
    };

    /**
     * Handler function for other text input change.
     *
     * @param {Object} e - The event object.
     */
    const handleOther = (e) => {
        const textInput = e.target.value;
        setSoftwareTracker(
            softwareTracker.map((temp) => {
                if (temp.name === 'other') {
                    return { ...temp, input: textInput };
                }
                return temp;
            })
        );
    };

    /**
     * Handler function for saving software tracker changes.
     */
    const handleSave = async () => {
        await updateNewSc(newScObj.id, { software: softwareTracker });
    };

    // Return null if software tracker is not available
    if (!softwareTracker) return null;

    return (
        <>
            <div className="newsc-software-column">
                {softwareTracker.map((x) => (
                    <label key={uuidv4()}>
                        <input
                            type="checkbox"
                            defaultChecked={x.value}
                            onChange={() => handleChange(x)}></input>
                        {x.prettyName}
                    </label>
                ))}
                {other ? (
                    <input
                        type="text"
                        value={softwareTracker[28].input}
                        onChange={(e) => handleOther(e)}></input>
                ) : null}
            </div>
            <div className="newsc-btn-container">
                {loading && <LoadingSpinner />}
                {error && <ErrorMessage message={error.message} />}
                {newSc && <SuccessMessage message="Saved successfully!" />}
                <button
                    className="newsc-btn"
                    style={{ maxWidth: '20%' }}
                    onClick={handleSave}>
                    Save
                </button>
            </div>
        </>
    );
};

export default SoftwareTracker;
