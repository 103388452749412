import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { setActiveTab } from '../../redux/slices/header-slice.js';
import SubLinks from '../../components/header/SubLinks.jsx';
import { userManagementLinks } from '../../config/standard-links.js';
import CreateUpdateUserForm from '../../components/users/CreateUpdateUserForm.jsx';
import useUsers from '../../hooks/useUsers.js';
import ErrorMessage from '../../components/common/ErrorMessage.jsx';
import LoadingSpinner from '../../components/common/LoadingSpinner.jsx';
import BackArrow from '../../components/common/BackArrow.jsx';

/**
 * Component for editing a user.
 * @returns {JSX.Element} EditUserPage component.
 */
const EditUserPage = () => {
    const dispatch = useDispatch();

    const [selectedUserId, setSelectedUserId] = useState(null);
    const [dropdownOptions, setDropdownOptions] = useState([]);

    const { error, loading, user, getAllUsers, getUser } = useUsers();

    useEffect(() => {
        dispatch(setActiveTab('Users'));

        return () => {
            dispatch(setActiveTab(null));
        };
    });

    useEffect(() => {
        const getUsers = async () => {
            let returnedUsers = await getAllUsers();
            returnedUsers = returnedUsers.map((u) => {
                // eslint-disable-next-line no-param-reassign
                u = {
                    value: u.id,
                    label: u.email
                };
                return u;
            });
            setDropdownOptions(returnedUsers);
        };

        getUsers();
    }, []);

    useEffect(() => {
        if (selectedUserId) {
            getUser(selectedUserId);
        }
    }, [selectedUserId]);

    return (
        <div>
            <SubLinks links={userManagementLinks} />
            <div className="newsc-container">
                <br />
                <br />
                <br />
                <div className="create-office-heading">
                    <BackArrow navigateTo={-1} />
                    <h2>Edit a user</h2>
                </div>
                <br />
                <div className="newsc-form-container">
                    <p className="form-label">Select a user to edit</p>
                    <Select
                        id="selectUser"
                        name="selectUser"
                        options={dropdownOptions}
                        onChange={(e) => setSelectedUserId(e.value)}
                    />
                </div>
                <br />
                <br />
                {loading && <LoadingSpinner />}
                {error && <ErrorMessage message={error.message} />}
                {!loading && !error && user ? (
                    <CreateUpdateUserForm objectToUpdate={user} />
                ) : null}
            </div>
        </div>
    );
};

export default EditUserPage;
