export const blue = '#2361DC';
export const lightBlue = '#4B9CD3';
export const pinkRed = '#DC2361';
export const red = '#FF0000';
export const darkRed = '#C60C30';
export const green = '#1CAC78';
export const orange = '#FF7518';
export const purple = '#720e9e';
export const pink = '#F9629F';
export const lightPink = '#ffb6c1';
export const grey = '#B0B0B0';
export const yellow = '#FFC000';
export const black = '#000000';
export const white = '#FFFFFF';
