import { useState } from 'react';
import convertError from '../utils/error-converter.js';
import UserFriendlyError from '../utils/UserFriendlyError.js';
import callApi from '../utils/call-api.js';
import analyzeApiResponse from '../utils/analyze-api-response.js';

/**
 * Custom React hook for managing hipaa-related data.
 *
 * @returns {Object} An object containing state variables and functions related to hipaa data.
 * @property {Array<Object>} offices - The array of returned offices (empty if none returned).
 * @property {boolean} isSendEmailSuccess - whether the email sent successfully or not.
 * @property {Object|null} error - An error object containing details about any encountered error.
 * @property {number} totalPages - Total number of pages.
 * @property {boolean} loading - A boolean indicating whether loading is active.
 */
const useHipaa = () => {
    const [offices, setOffices] = useState([]);
    const [isSendEmailSuccess, setIsSendEmailSuccess] = useState(false);
    const [specificComplianceCounts, setSpecificComplianceCounts] =
        useState(null);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    /**
     * Sends the OS failed HIPAA email to office.
     *
     * @async
     * @function
     * @param {string} officeId - The office id to send the email to.
     * @returns {Promise<boolean>} A promise that resolves when the process is complete.
     */
    const sendHipaaOsFailEmail = async (officeId) => {
        try {
            setLoading(true);
            setError(null);
            setIsSendEmailSuccess(false);

            const path = `/hipaa/send-os-email/${officeId}`;
            const response = await callApi(path, 'post');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            setIsSendEmailSuccess(true);
            return true;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return false;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Searches for offices that are incompliant with HIPAA for certain specified reasons.
     *
     * @async
     * @function
     * @param {string} [reason=''] - The search string for filtering by incompliant reason.
     * @param {number} [currentPage=1] - The current page for pagination.
     * @param {number} [limit=10] - The number of jobs to retrieve per page.
     * @param {string} [sortBy='officeSysName:asc'] - How to sort the returned data
     * @returns {Promise<Array>} A promise that resolves with a list of returned offices.
     */
    const getHipaaIncompliantOffices = async (
        reason = '',
        currentPage = 1,
        limit = 40,
        sortBy = 'officeSysName:asc'
    ) => {
        try {
            setLoading(true);
            setError(null);

            const path = `/hipaa/incompliant?reason=${reason}&limit=${limit}&page=${currentPage}&sortBy=${sortBy}`;
            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const { results: returnedOffices, totalPages: returnedTotalPages } =
                await response.json();
            setOffices(returnedOffices);
            setTotalPages(returnedTotalPages);
            return returnedOffices;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
        } finally {
            setLoading(false);
        }
    };

    /**
     * Searches for offices that are newly compliant with HIPAA for certain specified reasons.
     *
     * @async
     * @function
     * @param {Date} startDate - defaults to thirty days ago
     * @param {Date} endDate - defaults to today
     * @param {number} [currentPage=1] - The current page for pagination.
     * @param {number} [limit=10] - The number of jobs to retrieve per page.
     * @param {string} [sortBy='officeSysName:asc'] - How to sort the returned data
     * @returns {Promise<Array>} A promise that resolves with a list of returned offices.
     */
    const getHipaaNewlyCompliantOffices = async (
        startDate = new Date().setDate(new Date().getDate() - 30), // thirty days ago
        endDate = new Date(),
        currentPage = 1,
        limit = 20,
        sortBy = 'officeSysName:asc'
    ) => {
        try {
            setLoading(true);
            setError(null);

            const path = `/hipaa/compliant/new?startDate=${startDate}&endDate=${endDate}&limit=${limit}&page=${currentPage}&sortBy=${sortBy}`;
            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const { results: returnedOffices, totalPages: returnedTotalPages } =
                await response.json();
            setOffices(returnedOffices);
            setTotalPages(returnedTotalPages);
            return returnedOffices;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
        } finally {
            setLoading(false);
        }
    };

    /**
     * Gets counts for offices that are newly compliant with HIPAA in each category for certain specified reasons.
     *
     * @async
     * @function
     * @param {Date} startDate - defaults to thirty days ago
     * @param {Date} endDate - defaults to today
     * @returns {Promise<Object>} A promise that resolves with an object of counts.
     */
    const getHipaaSpecificComplianceCounts = async (
        startDate = new Date().setDate(new Date().getDate() - 30), // thirty days ago
        endDate = new Date()
    ) => {
        try {
            setLoading(true);
            setError(null);
            setSpecificComplianceCounts(null);

            const path = `/hipaa/compliant/specific?startDate=${startDate}&endDate=${endDate}`;
            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const results = await response.json();
            setSpecificComplianceCounts(results);
            return results;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
        } finally {
            setLoading(false);
        }
    };

    return {
        sendHipaaOsFailEmail,
        isSendEmailSuccess,
        getHipaaIncompliantOffices,
        getHipaaNewlyCompliantOffices,
        getHipaaSpecificComplianceCounts,
        specificComplianceCounts,
        offices,
        totalPages,
        loading,
        error
    };
};

export default useHipaa;
