import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/extensions
import { GoDotFill } from 'react-icons/go';
import { black } from '../../styles/standard-colors.js';

/**
 * ActiveStateDot component for displaying a colored dot to indicate active status with optional navigation or onClick functionality.
 *
 * @component
 * @example
 * // Example usage with navigation:
 * <ActiveStateDot navigateTo="/add-item" color="blue" size={40} />
 *
 * @example
 * // Example usage with custom onClick:
 * <ActiveStateDot onClick={() => handleAddButtonClick()} size={30} />
 *
 * @param {Object} props - The component props.
 * @param {string} [props.color='#FF355E'] - The color of the button. Defaults to black.
 * @param {number} [props.size=34] - The size of the button icon.
 * @param {string} [props.navigateTo] - The URL to navigate to when the button is clicked.
 * @param {Function} [props.onClick] - The function to be executed when the button is clicked.
 *
 * @returns {JSX.Element} - The rendered ActiveStateDot component.
 */
const ActiveStateDot = ({ color, size, navigateTo, onClick }) => {
    const navigate = useNavigate();

    if (navigateTo) {
        return (
            <GoDotFill
                size={size}
                color={color}
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(navigateTo)}
            />
        );
    }

    if (onClick) {
        return (
            <GoDotFill
                size={size}
                color={color}
                style={{ cursor: 'pointer' }}
                onClick={onClick}
            />
        );
    }

    return <GoDotFill size={size} color={color} />;
};

ActiveStateDot.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
    navigateTo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func
};

ActiveStateDot.defaultProps = {
    color: black,
    size: 40
};

export default ActiveStateDot;
