import * as Yup from 'yup';
import { validObjectId } from './custom-validations.js';

/**
 * Yup validation schema for creating a user.
 */
const createUserValidation = Yup.object({
    firstName: Yup.string()
        .trim('Remove any leading or trailing spaces')
        .required('Required'),
    lastName: Yup.string()
        .trim('Remove any leading or trailing spaces')
        .required('Required'),
    email: Yup.string()
        .email('Must be a valid email address')
        .trim('Remove any leading or trailing spaces')
        .required('Required'),
    role: Yup.string()
        .matches(validObjectId, {
            message: 'Must be a valid object id'
        })
        .trim('Remove any leading or trailing spaces')
        .required('Required'),
    helpDeskTeam: Yup.string()
        .matches(validObjectId, {
            message: 'Must be a valid object id'
        })
        .trim('Remove any leading or trailing spaces')
});

export default createUserValidation;
