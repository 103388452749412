import PropTypes from 'prop-types';

/**
 * DeleteButton component for rendering a delete button.
 *
 * @component
 * @example
 * // Example usage:
 * <DeleteButton label="Delete" style={{ backgroundColor: 'red', color: 'white' }} onClick={someFunction} />
 *
 * @param {Object} props - The component props.
 * @param {string} props.label - The label or text content of the button (required).
 * @param {Object} [props.style] - The inline style object for the button.
 * @param {Object} props.onClick - The onClick function to call
 *
 * @returns {JSX.Element} - The rendered DeleteButton component.
 */
function DeleteButton({ label, style, onClick }) {
    return (
        <button
            type="submit"
            className="delete-btn"
            style={style}
            onClick={() => onClick()}>
            {label}
        </button>
    );
}

// Prop validation
DeleteButton.propTypes = {
    label: PropTypes.string.isRequired,
    style: PropTypes.object,
    onClick: PropTypes.func.isRequired
};

export default DeleteButton;
