import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setActiveTab } from '../../redux/slices/header-slice.js';
import BackArrow from '../../components/common/BackArrow.jsx';
import CreateUpdateNewScCardForm from '../../components/new-sc-vetting-cards/CreateUpdateNewScCardForm.jsx';

/**
 * React component for the page to create a new SC vetting card.
 *
 * @component
 * @returns {JSX.Element} The JSX representation of the CreateNewScPage component.
 */
const CreateNewScPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveTab('New SC'));

        return () => {
            dispatch(setActiveTab(null));
        };
    });

    return (
        <div className="create-office-container">
            <div className="create-office-heading">
                <BackArrow navigateTo={-1} />
                <h2>Create a New SC Vetting Card</h2>
            </div>
            <CreateUpdateNewScCardForm />
        </div>
    );
};

export default CreateNewScPage;
