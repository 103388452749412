/**
 * Checks whether a password complies with HIPAA requirements.
 * HIPAA requirements typically include:
 * - At least 8 characters
 * - At least one uppercase letter
 * - At least one lowercase letter
 * - At least one digit
 * - At least one special character
 *
 * @param {string} pw - The password to be checked.
 * @returns {boolean} Returns true if the password complies with HIPAA requirements, otherwise false.
 * @example
 * // Returns true
 * checkPwHipaaCompliance("Password1!");
 *
 * @example
 * // Returns false
 * checkPwHipaaCompliance("weakpassword");
 */
const checkPwHipaaCompliance = (pw) => {
    const regex = /^(?=.*[\W_])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    if (regex.test(pw)) {
        return true;
    }
    return false;
};

export default checkPwHipaaCompliance;
