import SubLinks from '../../components/header/SubLinks.jsx';
import { reportingLinks } from '../../config/standard-links.js';
import BackArrow from '../../components/common/BackArrow.jsx';
import QueryForm from '../../components/reporting/QueryForm.jsx';

/**
 * Component for rendering the page for Query tool.
 * @returns {JSX.Element} QueryToolPage component.
 */
const QueryToolPage = () => {
    return (
        <div>
            <SubLinks links={reportingLinks} />
            <div className="newsc-container">
                <br />
                <br />
                <br />
                <div className="create-office-heading">
                    <BackArrow navigateTo={-1} />
                    <h2>Query</h2>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100vh',
                        width: '100%'
                    }}>
                    <QueryForm />
                </div>
            </div>
        </div>
    );
};

export default QueryToolPage;
