import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setActiveTab } from '../../redux/slices/header-slice.js';
import SubLinks from '../../components/header/SubLinks.jsx';
import { userManagementLinks } from '../../config/standard-links.js';
import CreateUpdateUserForm from '../../components/users/CreateUpdateUserForm.jsx';
import BackArrow from '../../components/common/BackArrow.jsx';

/**
 * Component for creating a new user.
 * @returns {JSX.Element} CreateUserPage component.
 */
const CreateUserPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveTab('Users'));

        return () => {
            dispatch(setActiveTab(null));
        };
    });

    return (
        <div>
            <SubLinks links={userManagementLinks} />
            <div className="newsc-container">
                <br />
                <br />
                <br />
                <div className="create-office-heading">
                    <BackArrow navigateTo={-1} />
                    <h2>Create a user</h2>
                </div>
                <br />
                <CreateUpdateUserForm />
            </div>
        </div>
    );
};

export default CreateUserPage;
